import React, { FC, Fragment, useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import NumberFormatCustom from 'components/NumberFormatCustom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { ucWords } from 'utils';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Checkbox, CircularProgress, Fade, Grid, makeStyles, MenuItem, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Religion } from 'typings/enum/UploadResumeProperties';

interface Props {
  maritalStatusMaster: Select[];
  languageSpokenMaster: Select[];
  positionApplied: string;
  setPositionApplied: React.Dispatch<React.SetStateAction<string>>;
  expectedSalary: number;
  setExpectedSalary: React.Dispatch<React.SetStateAction<number>>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  unitNo: string;
  setUnitNo: React.Dispatch<React.SetStateAction<string>>;
  postalCode: string;
  setPostalCode: React.Dispatch<React.SetStateAction<string>>;
  setHomeAddress: React.Dispatch<React.SetStateAction<string>>;
  contactNumber: string;
  setContactNumber: React.Dispatch<React.SetStateAction<string>>;
  dateOfBirth: Date | null;
  setDateOfBirth: React.Dispatch<React.SetStateAction<Date | null>>;
  passportNumber: string;
  setPassportNumber: React.Dispatch<React.SetStateAction<string>>;
  passportExpiryDate: Date | null;
  setPassportExpiryDate: React.Dispatch<React.SetStateAction<Date | null>>;
  height: number;
  setHeight: React.Dispatch<React.SetStateAction<number>>;
  weight: number;
  setWeight: React.Dispatch<React.SetStateAction<number>>;
  maritalStatus: string;
  setMaritalStatus: React.Dispatch<React.SetStateAction<string>>;
  religion: string;
  setReligion: React.Dispatch<React.SetStateAction<string>>;
  setLanguageSpoken: React.Dispatch<React.SetStateAction<string>>;
  emailAddress: string;
  setEmailAddress: React.Dispatch<React.SetStateAction<string>>;
  positionAppliedError: string;
  expectedSalaryError: string;
  nameError: string;
  contactNumberError: string;
  dateOfBirthError: string;
  passportNumberError: string;
  emailAddressError: string;
  isCheckingPassportNumber: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  textFieldNumber: {
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none'
    }
  },
  autoCompleteCheckbox: {
    padding: theme.spacing(0)
  },
  checkBoxIcon: {
    fontSize: '16px'
  },
  checkBox: {
    marginLeft: theme.spacing(-2),
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveScale: {
    width: '100%'
  },
  textFieldWrapper: {
    width: '100%',
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '96%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const AutocompleteCustom = withStyles({
  inputRoot: {
    flexWrap: 'wrap',
    '&[class*="MuiOutlinedInput-root"]': {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  tag: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #0B3469',
    fontSize: '13px',
    color: '#0B3469',
    height: 25
  }
})(Autocomplete);

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#F5F5F9',
    color: 'red',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid red',
    marginTop: 1
  }
}))(Tooltip);

const PersonalProfile: FC<Props> = props => {
  const classes = useStyles(props);
  const [religions, setReligions] = useState<Select[]>([]);

  const icon = <CheckBoxOutlineBlankIcon className={classes.checkBoxIcon} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkBoxIcon} />;

  const { maritalStatusMaster, languageSpokenMaster } = props;

  const { positionApplied, setPositionApplied, positionAppliedError } = props;
  const { expectedSalary, setExpectedSalary, expectedSalaryError } = props;
  const { name, setName, nameError } = props;
  const { address, setAddress, unitNo, setUnitNo, postalCode, setPostalCode, setHomeAddress } = props;
  const { contactNumber, setContactNumber, contactNumberError } = props;
  const { dateOfBirth, setDateOfBirth, dateOfBirthError } = props;
  const { passportNumber, setPassportNumber, passportNumberError } = props;
  const { passportExpiryDate, setPassportExpiryDate } = props;
  const { height, setHeight } = props;
  const { weight, setWeight } = props;
  const { maritalStatus, setMaritalStatus } = props;
  const { religion, setReligion } = props;
  const { setLanguageSpoken } = props;
  const { emailAddress, setEmailAddress, emailAddressError } = props;
  const { isCheckingPassportNumber } = props;

  useEffect(() => {
    const religionObject = Object.values(Religion);

    //Set religion
    let religionData: Select[] = [];
    religionObject.map((religion, index) => religionData.push({ name: religion, id: index }));
    setReligions(religionData);
  }, []);

  const handleDateOfBirthChange = (date: Date | null) => {
    setDateOfBirth(date);
  };

  const handlePassortExpiryDateChange = (date: Date | null) => {
    setPassportExpiryDate(date);
  };

  const handleSetlanguageSpoken = (languageSpokens: Select[]) => {
    let selectedLanguageSpoken: string[] = [];
    for (const languageSpoken of languageSpokens) {
      selectedLanguageSpoken.push(languageSpoken.name);
    }
    setLanguageSpoken(selectedLanguageSpoken.join(','));
  };

  const handleAddressChange = (address: string, unitNo: string, postalCode: string) => {
    setAddress(address);
    setUnitNo(unitNo);
    setPostalCode(postalCode);
    let fixedAddress: string = '';
    if (address) {
      fixedAddress = address;
    } else {
      fixedAddress = '';
    }
    let fixedUnitNo: string = '';
    if (unitNo) {
      fixedUnitNo = `${address && ', '}${unitNo}`;
    } else {
      fixedUnitNo = '';
    }
    let fixedPostalCode: string = '';
    if (postalCode) {
      fixedPostalCode = `${(address || unitNo) && ', '}${postalCode}`;
    } else {
      fixedPostalCode = '';
    }
    setHomeAddress(`${fixedAddress}${fixedUnitNo}${fixedPostalCode}`);
  };

  const renderMaritalStatus = () => {
    return maritalStatusMaster.map(maritalStatus => {
      return (
        <MenuItem key={maritalStatus.id} value={maritalStatus.name}>
          {ucWords(maritalStatus.name)}
        </MenuItem>
      );
    });
  };

  return (
    <Fragment>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center'>
        <TextField
          margin='dense'
          required
          fullWidth
          id='positionApply'
          label='Position Apply / 申请职位'
          error={positionAppliedError !== ''}
          value={positionApplied}
          onChange={event => setPositionApplied(event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center'>
        <TextField
          margin='dense'
          required
          fullWidth
          id='expectedSalary'
          label='Expected Salary / 薪水要求'
          error={expectedSalaryError !== ''}
          value={expectedSalary === 0 ? null : expectedSalary}
          onChange={event => setExpectedSalary(+event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            },
            inputComponent: NumberFormatCustom as any,
            inputProps: {
              thousandSeparator: true,
              prefix: '$'
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} container direction='row' justify='flex-start' alignItems='flex-start' alignContent='center'>
        <Typography variant='h5' color='primary'>
          1. Personal Profile 个人信息
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center'>
        <TextField
          margin='dense'
          required
          fullWidth
          id='name'
          label='Name / 名字'
          error={nameError !== ''}
          value={name}
          onChange={event => setName(event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
        />
      </Grid>
      <Grid item xs={12} sm={2} container direction='row' justify='center' alignItems='center'>
        <TextField
          margin='dense'
          fullWidth
          id='address'
          label='Address / 地址'
          value={address}
          onChange={event => handleAddressChange(event.target.value, unitNo, postalCode)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
          multiline
          rowsMax='4'
        />
      </Grid>
      <Grid item xs={6} sm={2} container direction='row' justify='center' alignItems='center'>
        <TextField
          margin='dense'
          fullWidth
          id='unitNo'
          label='Unit No. / 编号'
          value={unitNo}
          onChange={event => handleAddressChange(address, event.target.value, postalCode)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
        />
      </Grid>
      <Grid item xs={6} sm={2} container direction='row' justify='center' alignItems='center'>
        <TextField
          margin='dense'
          fullWidth
          id='postalCode'
          label='Postal Code / 邮政编码'
          value={postalCode}
          onChange={event => handleAddressChange(address, unitNo, event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center'>
        <TextField
          margin='dense'
          required
          fullWidth
          id='contactNumber'
          label='Contact No. / 联系电话'
          error={contactNumberError !== ''}
          value={contactNumber}
          onChange={event => setContactNumber(event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            },
            inputProps: {
              min: 0
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
          type='number'
          className={classes.textFieldNumber}
        />
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin='dense'
            required
            fullWidth
            id='dateOfBirth'
            label='Date of Birth / 出生日期'
            error={dateOfBirthError !== ''}
            value={dateOfBirth}
            variant='dialog'
            inputVariant='outlined'
            format='dd/MM/yyyy'
            onChange={handleDateOfBirthChange}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            InputProps={{
              classes: {
                input: classes.textFieldFont
              }
            }}
            InputLabelProps={{
              className: classes.textFieldFont
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center'>
        <div className={classes.textFieldWrapper}>
          <HtmlTooltip
            title='Passport number already been used'
            disableFocusListener
            disableTouchListener
            interactive
            placement='bottom-start'
            open={passportNumberError === 'Duplicated' ? true : false}
          >
            <TextField
              margin='dense'
              required
              fullWidth
              id='passportNumber'
              label='IC/FIN/WP/PASSPORT NO. / 证件号码'
              error={passportNumberError !== ''}
              value={passportNumber}
              onChange={event => setPassportNumber(event.target.value)}
              variant='outlined'
              autoComplete='off'
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          </HtmlTooltip>
          <Fade
            in={isCheckingPassportNumber}
            style={{
              transitionDelay: isCheckingPassportNumber ? `500ms` : '0ms'
            }}
            unmountOnExit
          >
            <CircularProgress size={24} className={classes.buttonProgress} />
          </Fade>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin='dense'
            fullWidth
            id='passportExpiryDate'
            label='Passport Expiry Date / 护照过期日'
            value={passportExpiryDate}
            variant='dialog'
            inputVariant='outlined'
            format='dd/MM/yyyy'
            onChange={handlePassortExpiryDateChange} //functionPassportExpiryDate
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            InputProps={{
              classes: {
                input: classes.textFieldFont
              }
            }}
            InputLabelProps={{
              className: classes.textFieldFont
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center'>
        <TextField
          margin='dense'
          fullWidth
          id='height'
          label='Height (CM) / 身高'
          value={height === 0 ? null : height}
          onChange={event => setHeight(+event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            },
            inputComponent: NumberFormatCustom as any,
            inputProps: {
              format: '###'
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center'>
        <TextField
          margin='dense'
          fullWidth
          id='weight'
          label='Weight (KG) / 体重'
          value={weight === 0 ? null : weight}
          onChange={event => setWeight(+event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            },
            inputComponent: NumberFormatCustom as any,
            inputProps: {
              format: '###'
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center'>
        <TextField
          select
          margin='dense'
          fullWidth
          id='maritalStatus'
          label='Marital Status / 婚姻状况'
          value={maritalStatus}
          onChange={event => setMaritalStatus(event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont,
            shrink: maritalStatus === '' ? false : true
          }}
        >
          <MenuItem key={0} value=''>
            <em>None</em>
          </MenuItem>
          {renderMaritalStatus()}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center'>
        <div className={classes.responsiveScale}>
          <AutocompleteCustom
            freeSolo
            id='free-solo-2-demo'
            disableClearable
            options={religions.map(religion => ucWords(religion.name))}
            onChange={(event, value) => setReligion(value)}
            renderInput={params => (
              <TextField
                {...params}
                margin='dense'
                fullWidth
                id='religion'
                label='Religion / 宗教'
                value={religion}
                onChange={event => setReligion(event.target.value)}
                variant='outlined'
                autoComplete='off'
                InputProps={{
                  classes: {
                    input: classes.textFieldFont
                  },
                  ...params.InputProps,
                  type: 'search'
                }}
                InputLabelProps={{
                  className: classes.textFieldFont
                }}
              />
            )}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='flex-start'>
        <div className={classes.responsiveScale}>
          <AutocompleteCustom
            multiple
            id='languageSpoken'
            options={languageSpokenMaster}
            disableCloseOnSelect
            getOptionLabel={(option: Select) => ucWords(option.name)}
            onChange={(event, value) => handleSetlanguageSpoken(value)}
            renderOption={(option: Select, { selected }) => (
              <Fragment>
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} color='primary' disableRipple className={classes.checkBox} />
                {ucWords(option.name)}
              </Fragment>
            )}
            renderInput={params => (
              <TextField
                {...params}
                margin='dense'
                fullWidth
                id='languageSpoken'
                label='Spoken Language / 语言 '
                variant='outlined'
                autoComplete='off'
              />
            )}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='flex-start'>
        <TextField
          margin='dense'
          fullWidth
          id='emailAddress'
          label='Email Address / 電郵地址'
          error={emailAddressError !== ''}
          placeholder={emailAddressError}
          value={emailAddress}
          onChange={event => setEmailAddress(event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
        />
      </Grid>
    </Fragment>
  );
};

export default PersonalProfile;
