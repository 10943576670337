import React, { FC, useState, useEffect, useCallback } from 'react';
import { makeStyles, Table, TableBody, TableHead } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';

import CreateEditSkillForm from './components/CreateEditSkillForm';

import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';
import TablePagination from 'components/TablePagination';
import { SKILL_BASE_URL, GET_EDIT_SKILL_URL } from 'constants/url';

interface Props {
  isLoadingData: boolean;
  skills: SkillsModel[];
  count: number;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  currentPage: number;
  rowsPerPage: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  openCreateSkill: boolean;
  handleCancelCreateSkill(): void;
  openEditSkill: boolean;
  skill?: SkillsModel;
  currentEditingSkillIndex: number;
  handleOpenEditSkill: (skillIndex: number) => React.MouseEventHandler;
  handleCancelEditSkill(): void;
  addNewSkill(skill: SkillsModel): void;
  updateIndividualSkill: (updatedSkillProperties: Partial<SkillsModel>) => void;
  setDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles(() => ({
  tableWrapper: {
    overflowX: 'auto'
  }
}));

const SkillTable: FC<Props> = props => {
  const classes = useStyles();
  let cancelTokenSource: CancelTokenSource;

  const {
    isLoadingData,
    skills,
    count,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError,
    currentPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    openCreateSkill,
    handleCancelCreateSkill,
    openEditSkill,
    skill,
    currentEditingSkillIndex,
    handleOpenEditSkill,
    handleCancelEditSkill,
    addNewSkill,
    updateIndividualSkill,
    setDelete
  } = props;

  const dummySkill: SkillsModel = {
    id: 0,
    name: ''
  };

  const [isLoading, setLoading] = useState<boolean>(false);

  const [name, setName] = useState<string>('');

  const [nameError, setNameError] = useState<string>('');

  const resetInputFormValues = () => {
    setName('');
  };

  const resetEditFormValues = useCallback(() => {
    if (!skill) {
      return;
    }

    const { name } = skill;

    setName(name);
  }, [skill]);

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  useEffect(() => {
    if (!openEditSkill) {
      let timeout: NodeJS.Timeout;

      if (isLoadingData) {
        timeout = setTimeout(() => {
          setShowSkeleton(true);
        }, 500);
      }

      setShowSkeleton(false);
      resetInputFormValues();
      clearFormErrors();

      return () => {
        clearTimeout(timeout);
      };
    } else {
      resetEditFormValues();
      clearFormErrors();
    }
  }, [openEditSkill, isLoadingData, resetEditFormValues]);

  const handleCloseCreateSkill = () => {
    handleCancelCreateSkill();
    resetInputFormValues();
    clearFormErrors();
  };

  const handleCloseEditSkill = () => {
    handleCancelEditSkill();
    resetInputFormValues();
    clearFormErrors();
  };

  const clearFormErrors = () => {
    setNameError('');
  };

  const validateForm = () => {
    let ret = true;
    clearFormErrors();

    if (!name || !name.trim()) {
      setNameError('Please enter skill name');
      ret = false;
    }

    return ret;
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      cancelTokenSource = axios.CancelToken.source();
      if (!openEditSkill) {
        const response = await axios.post(
          `${SKILL_BASE_URL}`,
          {
            name
          },
          { cancelToken: cancelTokenSource.token }
        );
        addNewSkill(response.data);
        handleSetMessageSuccess('Successfully added new master skill');
      } else {
        const response = await axios.put(
          `${GET_EDIT_SKILL_URL(skill!.id)}`,
          {
            name
          },
          { cancelToken: cancelTokenSource.token }
        );
        updateIndividualSkill(response.data);
        handleSetMessageSuccess('Successfully edited a master skill');
      }
      setSnackbarVarient('success');
      setOpenSnackbar(true);
      !openEditSkill ? handleCloseCreateSkill() : handleCloseEditSkill();
    } catch (err) {
      if (!openEditSkill) {
        handleSetMessageError('Failed to add a new master skill');
      } else {
        handleSetMessageError('Failed to edit a master skill');
      }
      setSnackbarVarient('error');
      setOpenSnackbar(true);
      console.log(`err:${err}`);
      const { errorCode } = err.data;

      console.log(`errorCode:${errorCode}`);
    }

    setLoading(false);
  };

  // headerNameWithPaddings['headerName:pL:pR:pT:pB']
  return (
    <div className={classes.tableWrapper}>
      <Table>
        <TableHead>
          <HeaderRow headers={[{ label: 'Skill Name' }, { label: 'Action', pL: '110px', pR: '0px', pT: '0px', pB: '0px' }]} />
        </TableHead>
        <TableBody>
          {openCreateSkill && (
            <CreateEditSkillForm
              name={name}
              setName={setName}
              nameError={nameError}
              isSubmitting={isLoading}
              onSubmit={handleOnSubmit}
              onCancel={handleCloseCreateSkill}
              primaryButtonLabel={'Save'}
            />
          )}
          {showSkeleton
            ? [1, 2, 3, 4, 5].map(index => (
                <BodyRow
                  key={index}
                  skill={dummySkill}
                  setOpenSnackbar={setOpenSnackbar}
                  setSnackbarVarient={setSnackbarVarient}
                  handleSetMessageSuccess={handleSetMessageSuccess}
                  handleSetMessageError={handleSetMessageError}
                  setDelete={setDelete}
                  onEditSkill={handleOpenEditSkill(index)}
                  isLoadingData={isLoadingData}
                />
              ))
            : skills.map((skill, index) =>
                openEditSkill && currentEditingSkillIndex === index ? (
                  <CreateEditSkillForm
                    key={skill.id}
                    name={name}
                    setName={setName}
                    nameError={nameError}
                    isSubmitting={isLoading}
                    onSubmit={handleOnSubmit}
                    onCancel={handleCloseEditSkill}
                    primaryButtonLabel={'Save'}
                    customBackground={'#F4F9FC'}
                  />
                ) : (
                  <BodyRow
                    key={skill.id}
                    skill={skill}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarVarient={setSnackbarVarient}
                    handleSetMessageSuccess={handleSetMessageSuccess}
                    handleSetMessageError={handleSetMessageError}
                    setDelete={setDelete}
                    onEditSkill={handleOpenEditSkill(index)}
                    isLoadingData={isLoadingData}
                  />
                )
              )}
        </TableBody>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50]}
          count={count}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Table>
    </div>
  );
};

export default SkillTable;
