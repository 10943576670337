import React, { FC, useState, useEffect, useCallback } from 'react';
import { makeStyles, Table, TableBody, TableHead } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';

import CreateEditEmploymentHistoryForm from './components/CreateEditEmploymentHistoryForm';

import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';
import TablePagination from 'components/TablePagination';
import { JOB_EXPERIENCE_BASE_URL, GET_EDIT_JOB_EXPERIENCE_URL } from 'constants/url';

interface Props {
  candidateId: number;
  isLoadingData: boolean;
  employmentHistories: EmploymentHistoriesModel[];
  count: number;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  currentPage: number;
  rowsPerPage: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  openCreateEmploymentHistory: boolean;
  handleCancelCreateEmploymentHistory(): void;
  openEditEmploymentHistory: boolean;
  employmentHistory?: EmploymentHistoriesModel;
  currentEditingEmploymentHistoryIndex: number;
  handleOpenEditEmploymentHistory: (employmentHistoryIndex: number) => React.MouseEventHandler;
  handleCancelEditEmploymentHistory(): void;
  addNewEmploymentHistory(employmentHistory: EmploymentHistoriesModel): void;
  updateIndividualEmploymentHistory: (updatedEmploymentHistoryProperties: Partial<EmploymentHistoriesModel>) => void;
  setDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles(() => ({
  tableWrapper: {
    overflowX: 'auto'
  }
}));

const EmploymentHistoryTable: FC<Props> = props => {
  const classes = useStyles();
  let cancelTokenSource: CancelTokenSource;

  const {
    candidateId,
    isLoadingData,
    employmentHistories,
    count,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError,
    currentPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    openCreateEmploymentHistory,
    handleCancelCreateEmploymentHistory,
    openEditEmploymentHistory,
    employmentHistory,
    currentEditingEmploymentHistoryIndex,
    handleOpenEditEmploymentHistory,
    handleCancelEditEmploymentHistory,
    addNewEmploymentHistory,
    updateIndividualEmploymentHistory,
    setDelete
  } = props;

  const dummyEmploymentHistory: EmploymentHistoriesModel = {
    id: 0,
    numberOfYears: 0,
    companyName: '',
    position: '',
    employmentRemark: '',
    candidateId: 0,
    createdAt: new Date()
  };

  const [isLoading, setLoading] = useState<boolean>(false);

  const [numberOfYears, setNumberOfYears] = useState<number>(0);
  const [companyName, setCompanyName] = useState<string>('');
  const [position, setPosition] = useState<string>('');
  const [employmentRemark, setEmploymentRemark] = useState<string>('');

  const [numberOfYearsError, setNumberOfYearsError] = useState<string>('');
  const [companyNameError, setCompanyNameError] = useState<string>('');
  const [positionError, setPositionError] = useState<string>('');

  const resetInputFormValues = () => {
    setNumberOfYears(0);
    setCompanyName('');
    setPosition('');
    setEmploymentRemark('');
  };

  const resetEditFormValues = useCallback(() => {
    if (!employmentHistory) {
      return;
    }

    const { numberOfYears, companyName, position, employmentRemark } = employmentHistory;

    setNumberOfYears(numberOfYears);
    setCompanyName(companyName);
    setPosition(position);
    employmentRemark && setEmploymentRemark(employmentRemark);
  }, [employmentHistory]);

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  useEffect(() => {
    if (!openEditEmploymentHistory) {
      let timeout: NodeJS.Timeout;

      if (isLoadingData) {
        timeout = setTimeout(() => {
          setShowSkeleton(true);
        }, 500);
      }

      setShowSkeleton(false);
      resetInputFormValues();
      clearFormErrors();

      return () => {
        clearTimeout(timeout);
      };
    } else {
      resetEditFormValues();
      clearFormErrors();
    }
  }, [openEditEmploymentHistory, isLoadingData, resetEditFormValues]);

  const handleCloseCreateEmploymentHistory = () => {
    handleCancelCreateEmploymentHistory();
    resetInputFormValues();
    clearFormErrors();
  };

  const handleCloseEditEmploymentHistory = () => {
    handleCancelEditEmploymentHistory();
    resetInputFormValues();
    clearFormErrors();
  };

  const clearFormErrors = () => {
    setNumberOfYearsError('');
    setCompanyNameError('');
    setPositionError('');
  };

  const validateForm = () => {
    let ret = true;
    clearFormErrors();

    if (!numberOfYears) {
      setNumberOfYearsError('Please enter number of years');
      ret = false;
    }

    if (!companyName || !companyName.trim()) {
      setCompanyNameError('Please enter employer name');
      ret = false;
    }

    if (!position || !position.trim()) {
      setPositionError('Please enter position');
      ret = false;
    }

    return ret;
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      cancelTokenSource = axios.CancelToken.source();
      if (!openEditEmploymentHistory) {
        const response = await axios.post(
          `${JOB_EXPERIENCE_BASE_URL}`,
          {
            numberOfYears,
            companyName,
            position,
            employmentRemark,
            candidateId
          },
          { cancelToken: cancelTokenSource.token }
        );
        addNewEmploymentHistory(response.data);
        handleSetMessageSuccess('Successfully added new employment history');
      } else {
        if (employmentHistory!.id) {
          const response = await axios.put(
            `${GET_EDIT_JOB_EXPERIENCE_URL(employmentHistory!.id)}`,
            {
              numberOfYears,
              companyName,
              position,
              employmentRemark
            },
            { cancelToken: cancelTokenSource.token }
          );
          updateIndividualEmploymentHistory(response.data);
          handleSetMessageSuccess('Successfully edited a employment history');
        }
      }
      setSnackbarVarient('success');
      setOpenSnackbar(true);
      !openEditEmploymentHistory ? handleCloseCreateEmploymentHistory() : handleCloseEditEmploymentHistory();
    } catch (err) {
      if (!openEditEmploymentHistory) {
        handleSetMessageError('Failed to add a new employment history');
      } else {
        handleSetMessageError('Failed to edit a employment history');
      }
      setSnackbarVarient('error');
      setOpenSnackbar(true);
      console.log(`err:${err}`);
      const { errorCode } = err.data;

      console.log(`errorCode:${errorCode}`);
    }

    setLoading(false);
  };

  // headerNameWithPaddings['headerName:pL:pR:pT:pB']
  return (
    <div className={classes.tableWrapper}>
      <Table>
        <TableHead>
          <HeaderRow
            headers={[
              { label: 'Employer Name', pR: '10px' },
              { label: 'No. of Years', pR: '10px' },
              { label: 'Position', pR: '10px' },
              { label: 'Remark', pR: '10px' },
              { label: 'Action', pL: '165px', pR: '0px', pT: '0px', pB: '0px' }
            ]}
          />
        </TableHead>
        <TableBody>
          {openCreateEmploymentHistory && (
            <CreateEditEmploymentHistoryForm
              companyName={companyName}
              setCompanyName={setCompanyName}
              companyNameError={companyNameError}
              numberOfYears={numberOfYears}
              setNumberOfYears={setNumberOfYears}
              numberOfYearsError={numberOfYearsError}
              position={position}
              setPosition={setPosition}
              positionError={positionError}
              employmentRemark={employmentRemark}
              setEmploymentRemark={setEmploymentRemark}
              isSubmitting={isLoading}
              onSubmit={handleOnSubmit}
              onCancel={handleCloseCreateEmploymentHistory}
              primaryButtonLabel={'Save'}
            />
          )}
          {showSkeleton
            ? [1, 2, 3, 4, 5].map(index => (
                <BodyRow
                  key={index}
                  employmentHistory={dummyEmploymentHistory}
                  setOpenSnackbar={setOpenSnackbar}
                  setSnackbarVarient={setSnackbarVarient}
                  handleSetMessageSuccess={handleSetMessageSuccess}
                  handleSetMessageError={handleSetMessageError}
                  setDelete={setDelete}
                  onEditEmploymentHistory={handleOpenEditEmploymentHistory(index)}
                  isLoadingData={isLoadingData}
                />
              ))
            : employmentHistories.map((employmentHistory, index) =>
                openEditEmploymentHistory && currentEditingEmploymentHistoryIndex === index ? (
                  <CreateEditEmploymentHistoryForm
                    key={employmentHistory.id}
                    companyName={companyName}
                    setCompanyName={setCompanyName}
                    companyNameError={companyNameError}
                    numberOfYears={numberOfYears}
                    setNumberOfYears={setNumberOfYears}
                    numberOfYearsError={numberOfYearsError}
                    position={position}
                    setPosition={setPosition}
                    positionError={positionError}
                    employmentRemark={employmentRemark}
                    setEmploymentRemark={setEmploymentRemark}
                    isSubmitting={isLoading}
                    onSubmit={handleOnSubmit}
                    onCancel={handleCloseEditEmploymentHistory}
                    primaryButtonLabel={'Save'}
                    customBackground={'#F4F9FC'}
                  />
                ) : (
                  <BodyRow
                    key={employmentHistory.id}
                    employmentHistory={employmentHistory}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarVarient={setSnackbarVarient}
                    handleSetMessageSuccess={handleSetMessageSuccess}
                    handleSetMessageError={handleSetMessageError}
                    setDelete={setDelete}
                    onEditEmploymentHistory={handleOpenEditEmploymentHistory(index)}
                    isLoadingData={isLoadingData}
                  />
                )
              )}
        </TableBody>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          count={count}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Table>
    </div>
  );
};

export default EmploymentHistoryTable;
