import React, { FC, Fragment } from 'react';
import { Grid, makeStyles, MenuItem, TextField, Theme, Typography } from '@material-ui/core';

import { ucWords } from 'utils';

interface Props {
  highestQualificationMaster: Select[];
  nameOfSchool: string;
  setNameOfSchool: React.Dispatch<React.SetStateAction<string>>;
  highestQualification: string;
  setHighestQualification: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  deleteButtonGrid: {
    marginBottom: theme.spacing(-3)
  }
}));

const QualificationAttained: FC<Props> = props => {
  const classes = useStyles(props);

  const { nameOfSchool, setNameOfSchool } = props;
  const { highestQualificationMaster, highestQualification, setHighestQualification } = props;

  const renderForm = () => {
    const renderHighestQualification = () => {
      return highestQualificationMaster.map(highestQualification => {
        return (
          <MenuItem key={highestQualification.id} value={highestQualification.name}>
            {ucWords(highestQualification.name)}
          </MenuItem>
        );
      });
    };

    return (
      <Fragment>
        <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center'>
          <TextField
            margin='dense'
            fullWidth
            id='schoolName'
            label='Name of School 学校名字'
            value={nameOfSchool}
            onChange={event => setNameOfSchool(event.target.value)}
            variant='outlined'
            autoComplete='off'
            InputProps={{
              classes: {
                input: classes.textFieldFont
              }
            }}
            InputLabelProps={{
              className: classes.textFieldFont
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center'>
          <TextField
            select
            margin='dense'
            fullWidth
            id='qualification'
            label='Qualification / 学历'
            value={highestQualification}
            onChange={event => setHighestQualification(event.target.value)}
            variant='outlined'
            autoComplete='off'
            InputProps={{
              classes: {
                input: classes.textFieldFont
              }
            }}
            InputLabelProps={{
              className: classes.textFieldFont,
              shrink: highestQualification === '' ? false : true
            }}
          >
            <MenuItem key={0} value=''>
              <em>None</em>
            </MenuItem>
            {renderHighestQualification()}
          </TextField>
        </Grid>
      </Fragment>
    );
  };
  return (
    <Fragment>
      <Grid item xs={12} sm={12} container direction='row' justify='flex-start' alignItems='flex-start' alignContent='center'>
        <Typography variant='h5' color='primary'>
          5. Highest Academic Qualification(s) Attained 最高学历
        </Typography>
      </Grid>
      {renderForm()}
    </Fragment>
  );
};

export default QualificationAttained;
