const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const LOGIN_URL = `${BASE_URL}/login`;
export const LOGOUT_URL = `${BASE_URL}/logout`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}/forgotpassword`;
export const RESET_PASSWORD_URL = `${BASE_URL}/resetpassword`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}/changepassword`;

export const USER_BASE_URL = `${BASE_URL}/users`;
export const GET_CURRENT_USER_URL = `${USER_BASE_URL}/current`;
export const GET_EDIT_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}`;
export const GET_DEACTIVATE_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}`;
export const GET_ACTIVATE_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}/activate`;

export const CANDIDATE_BASE_URL = `${BASE_URL}/candidates`;
export const CANDIDATE_GLOBAL_SEARCH_URL = `${CANDIDATE_BASE_URL}/globalsearch`;
export const GET_CANDIDATE_BY_ID_URL = (candidateId?: string) => `${CANDIDATE_BASE_URL}/${candidateId}`;
export const GET_EDIT_CANDIDATE_URL = (candidateId: number) => `${CANDIDATE_BASE_URL}/${candidateId}`;
export const GET_DOWNLOAD_PICTURE_URL = (fileKey: string) => `${CANDIDATE_BASE_URL}/downloadPicture/${fileKey}`;
export const GET_DOWNLOAD_PDF_URL = (fileKey: string) => `${CANDIDATE_BASE_URL}/downloadPdf/${fileKey}`;
export const GET_CANDIDATE_PHOTO_URL = (photo: string) => `${CANDIDATE_BASE_URL}/getimage/${photo}`;
export const GET_EDIT_CANDIDATE_DOCUMENT_URL = `${CANDIDATE_BASE_URL}/editDocument`;
export const GET_EXPORT_PDF_URL = (candidateId: number) => `${CANDIDATE_BASE_URL}/exportPDF/${candidateId}`;
export const GET_DELETE_CANDIDATES = (candidateId: number) => `${CANDIDATE_BASE_URL}/${candidateId}`;

export const SKILL_BASE_URL = `${BASE_URL}/skills`;
export const GET_EDIT_SKILL_URL = (skillId: number) => `${SKILL_BASE_URL}/${skillId}`;
export const GET_DELETE_SKILL = (skillId: number) => `${SKILL_BASE_URL}/${skillId}`;

export const FAMILY_MEMBER_BASE_URL = `${BASE_URL}/familyMembers`;
export const GET_FAMILY_MEMBER_BY_ID_URL = `${FAMILY_MEMBER_BASE_URL}/getById`;
export const GET_FAMILY_MEMBER_BY_CANDIDATE_ID_URL = (candidateId?: number) => `${FAMILY_MEMBER_BASE_URL}/${candidateId}`;
export const GET_EDIT_FAMILY_MEMBER_URL = (familyMemberId: number) => `${FAMILY_MEMBER_BASE_URL}/${familyMemberId}`;
export const GET_DELETE_FAMILY_MEMBER = (familyMemberId?: number) => `${FAMILY_MEMBER_BASE_URL}/${familyMemberId}`;

export const JOB_EXPERIENCE_BASE_URL = `${BASE_URL}/jobexperiences`;
export const GET_EDIT_JOB_EXPERIENCE_URL = (jobExperienceId: number) => `${JOB_EXPERIENCE_BASE_URL}/${jobExperienceId}`;
export const GET_DELETE_JOB_EXPERIENCE = (jobExperienceId: number) => `${JOB_EXPERIENCE_BASE_URL}/${jobExperienceId}`;

export const INJURIES_HEALTH_ISSUE_BASE_URL = `${BASE_URL}/injuriesHealthIssues`;
export const GET_INJURIES_HEALTH_ISSUE_BASE_URL_BY_ID_URL = `${INJURIES_HEALTH_ISSUE_BASE_URL}/getById`;
export const GET_INJURIES_HEALTH_ISSUE_BASE_URL_BY_CANDIDATE_ID_URL = (candidateId?: number) => `${INJURIES_HEALTH_ISSUE_BASE_URL}/${candidateId}`;
export const GET_EDIT_INJURIES_HEALTH_ISSUE_URL = (injuriesHealthIssueId: number) => `${INJURIES_HEALTH_ISSUE_BASE_URL}/${injuriesHealthIssueId}`;
export const GET_DELETE_INJURIES_HEALTH_ISSUE = (injuriesHealthIssueId?: number) => `${INJURIES_HEALTH_ISSUE_BASE_URL}/${injuriesHealthIssueId}`;

export const RESUME_BASE_URL = `${BASE_URL}/resume`;
export const RESUME_CHECKING_PASSPORT_NUMBER_URL = `${RESUME_BASE_URL}/checkingpassportnumber`;
