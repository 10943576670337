import React, { FC, useState, useEffect } from 'react';
import { Avatar, Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import axios, { CancelTokenSource } from 'axios';
import NumberFormat from 'react-number-format';
import Skeleton from 'react-loading-skeleton';

import EditIcon from '@material-ui/icons/EditOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ActionSnackbar from 'components/ActionSnackbar';
import EditCandidateModal from './components/EditCandidateModal';

import { GET_CANDIDATE_PHOTO_URL } from 'constants/url';

interface Props {
  candidates: CandidateModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(2)
  },
  gridName: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  contentTypography: {
    fontWeight: 500
  },
  skills: {
    border: '1px solid #0B3469',
    borderRadius: 3,
    color: '#0B3469',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    marginLeft: theme.spacing(1)
  },
  lineHeight: {
    lineHeight: 2
  },
  avatar: {
    backgroundColor: '#0B3469'
  },
  gridHeader: {
    display: 'flex'
  }
}));

const HeaderContent: FC<Props> = props => {
  const classes = useStyles();
  const { candidates } = props;

  const { id, name, expectedSalary, positionApplied, skills, photo1 } = candidates!;
  const [img, setImg] = useState<string>('');
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [candidateName, setCandidateName] = useState<string>('');
  const [candidateExpectedSalary, setCandidateExpectedSalary] = useState<number>(0);
  const [candidatePositionApplied, setCandidatePositionApplied] = useState<string>('');
  const [candidateSkill, setCandidateSkill] = useState<string>('');
  const skill = candidateSkill !== null ? candidateSkill.split(',') : ['-'];

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openEditProfileModal, setOpenEditProfileModal] = useState<boolean>(false);

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const loadData = async () => {
      setIsLoadingData(true);
      try {
        setCandidateName(name);
        setCandidateExpectedSalary(expectedSalary);
        setCandidatePositionApplied(positionApplied);
        setCandidateSkill(skills);
        if (photo1) {
          axios.get(GET_CANDIDATE_PHOTO_URL(photo1), { headers: { t: localStorage.getItem('token') }, responseType: 'arraybuffer' }).then(res => {
            let base64 = btoa(
              new Uint8Array(res.data).reduce((data, byte) => {
                return data + String.fromCharCode(byte);
              }, '')
            );
            let src = 'data:image;base64,' + base64;
            setImg(src);
          });
        }
      } catch (err) {
        console.log(err);
      }
      setIsLoadingData(false);
    };
    loadData();
    return () => {
      cancelTokenSource.cancel();
    };
  }, [photo1, expectedSalary, name, positionApplied, skills]);

  let initialName: any = [];
  if (candidateName) {
    const splitedNames = candidateName.split(' ');
    splitedNames.map((splitedName, index) => {
      if (index === 0 || index === splitedNames.length - 1) {
        if (splitedName[0]) {
          initialName.push(splitedName[0].toUpperCase());
        } else {
          initialName.push(splitedNames[index - 1][0] ? splitedNames[index - 1][0].toUpperCase() : '');
        }
      }

      return initialName;
    });
  }

  const renderAvatar = () => {
    if (!photo1) {
      if (isLoadingData) {
        return <Avatar aria-label='candidateInitial' />;
      } else {
        return (
          <Avatar className={classes.avatar} aria-label='candidateInitial'>
            {initialName.join('')}
          </Avatar>
        );
      }
    } else {
      if (isLoadingData) {
        return <Avatar aria-label='candidateInitial' />;
      } else {
        return <Avatar className={classes.avatar} alt={name} src={img} />;
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenEditProfile = () => {
    setOpenEditProfileModal(true);
  };
  const handleCancelEditProfile = () => {
    setOpenEditProfileModal(false);
  };

  return (
    <Grid>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant='h6' color='primary' display='inline'>
            CANDIDATE
          </Typography>
        </Grid>
        <Grid item xs={6} direction='row' justify='flex-end' alignItems='center' className={classes.gridHeader}>
          <Button variant='outlined' color='primary' onClick={handleOpenEditProfile}>
            <EditIcon /> EDIT
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>{renderAvatar()}</Grid>
        <Grid item className={classes.gridName}>
          <Typography color='primary' variant='h3'>
            {isLoadingData ? <Skeleton width={90} /> : `${candidateName}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Grid item sm={2}>
          <Typography variant='h6' color='primary' display='inline'>
            Expected Salary:
          </Typography>
          <Typography variant='h6' display='inline'>
            {isLoadingData ? (
              <Skeleton width={60} />
            ) : (
              <NumberFormat
                value={candidateExpectedSalary}
                displayType={'text'}
                thousandSeparator={true}
                prefix={' $'}
                className={classes.contentTypography}
              />
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6' color='primary' display='inline'>
            Position Applied:
          </Typography>
          <Typography variant='h6' display='inline'>
            {isLoadingData ? <Skeleton width={50} /> : <span className={classes.contentTypography}> {candidatePositionApplied}</span>}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Grid item>
          <Typography variant='h6' color='primary' display='inline'>
            Professional Skills:
          </Typography>
        </Grid>
        <Grid item>
          {isLoadingData ? (
            <Skeleton width={298} />
          ) : (
            skill.map((skill, index) => {
              return (
                <Typography variant='body1' align='right' display='inline' className={classes.lineHeight} key={index}>
                  <span className={classes.skills}>{skill} </span>
                </Typography>
              );
            })
          )}
        </Grid>
      </Grid>
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? 'Update is successful' : 'Update failed'}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
      <EditCandidateModal
        open={openEditProfileModal}
        candidateId={id}
        candidateName={name}
        setCandidateName={setCandidateName}
        candidateExpectedSalary={expectedSalary}
        setCandidateExpectedSalary={setCandidateExpectedSalary}
        candidatePositionApplied={positionApplied}
        setCandidatePositionApplied={setCandidatePositionApplied}
        candidateSkill={skills}
        setCandidateSkill={setCandidateSkill}
        handleCancel={handleCancelEditProfile}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
      />
    </Grid>
  );
};

export default HeaderContent;
