import React, { FC, useState } from 'react';
import { Button, Card, CardHeader, CardContent, CardActions, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import DownloadIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/EditOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import axios from 'axios';
import ActionSnackbar from 'components/ActionSnackbar';
import EditDocumentModal from './components/EditDocumentModal';
import { GET_DOWNLOAD_PICTURE_URL, GET_DOWNLOAD_PDF_URL } from 'constants/url';

interface Props {
  candidates: CandidateModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: 20,
    marginRight: 40
  },
  cardHeader: {
    padding: theme.spacing(2)
  },
  CardContent: {
    paddingLeft: theme.spacing(1),
    paddingRight: 0,
    paddingBottom: 0
  },
  actionIcon: {
    fontSize: 20
  },
  contentValue: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5)
  }
}));

const CandidateDocument: FC<Props> = props => {
  const classes = useStyles();
  const { candidates } = props;

  const { id, name, photo1, photo2, photo3, photo4, resumeFile } = candidates!;

  const [photos, setPhotos] = useState<string>(photo1);
  const [photos2, setPhotos2] = useState<string>(photo2);
  const [photos3, setPhotos3] = useState<string>(photo3);
  const [photos4, setPhotos4] = useState<string>(photo4);
  const [resume, setResume] = useState<string>(resumeFile);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openEditDocumentModal, setOpenEditDocumentModal] = useState<boolean>(false);

  const handleDownloadImage = async (imageName: string) => {
    axios.get(GET_DOWNLOAD_PICTURE_URL(imageName), { headers: { t: localStorage.getItem('token') }, responseType: 'blob' }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data && response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${imageName}`);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleDownloadPdf = async (pdfName: string) => {
    const response = await axios.get(GET_DOWNLOAD_PDF_URL(pdfName), {
      headers: {
        t: localStorage.getItem('token')
      },
      responseType: 'blob'
    });

    const url = window.URL.createObjectURL(new Blob([response.data && response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${pdfName}`);
    document.body.appendChild(link);
    link.click();
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenEditDocument = () => {
    setOpenEditDocumentModal(true);
  };
  const handleCancelEditNotes = () => {
    setOpenEditDocumentModal(false);
  };

  return (
    <Grid item xs={4}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Typography color='primary' variant='h5'>
              Downloadable Documents
            </Typography>
          }
        />
        <Divider />
        <CardContent className={classes.CardContent}>
          {resumeFile !== null ? (
            <Grid container>
              <Grid item xs={2}>
                <Button color='primary' onClick={event => handleDownloadPdf(resume)}>
                  <DownloadIcon className={classes.actionIcon} />
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Typography variant='h6' className={classes.contentValue}>
                  {name} Resume
                </Typography>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          {photo1 !== null ? (
            <Grid container>
              <Grid item xs={2}>
                <Button color='primary' onClick={event => handleDownloadImage(photos)}>
                  <DownloadIcon className={classes.actionIcon} />
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Typography variant='h6' className={classes.contentValue}>
                  {name} Photo
                </Typography>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          {photo2 !== null ? (
            <Grid container>
              <Grid item xs={2}>
                <Button color='primary' onClick={event => handleDownloadImage(photos2)}>
                  <DownloadIcon className={classes.actionIcon} />
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Typography variant='h6' className={classes.contentValue}>
                  {name} Photo2
                </Typography>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          {photo3 !== null ? (
            <Grid container>
              <Grid item xs={2}>
                <Button color='primary' onClick={event => handleDownloadImage(photos3)}>
                  <DownloadIcon className={classes.actionIcon} />
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Typography variant='h6' className={classes.contentValue}>
                  {name} Photo3
                </Typography>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          {photo4 !== null ? (
            <Grid container>
              <Grid item xs={2}>
                <Button color='primary' onClick={event => handleDownloadImage(photos4)}>
                  <DownloadIcon className={classes.actionIcon} />
                </Button>
              </Grid>
              <Grid item xs={10}>
                <Typography variant='h6' className={classes.contentValue}>
                  {name} Photo4
                </Typography>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
        </CardContent>
        <Divider />
        <CardActions>
          <Button color='primary' onClick={handleOpenEditDocument}>
            <EditIcon className={classes.actionIcon} />
            Edit
          </Button>
        </CardActions>
      </Card>
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? 'Update is successful' : 'Update failed'}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
      <EditDocumentModal
        open={openEditDocumentModal}
        candidateId={id}
        candidatePhoto={photos}
        setCandidatePhoto={setPhotos}
        candidatePhoto2={photos2}
        setCandidatePhoto2={setPhotos2}
        candidatePhoto3={photos3}
        setCandidatePhoto3={setPhotos3}
        candidatePhoto4={photos4}
        setCandidatePhoto4={setPhotos4}
        candidateResume={resume}
        setCandidateResume={setResume}
        handleCancel={handleCancelEditNotes}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
      />
    </Grid>
  );
};

export default CandidateDocument;
