import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';

import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { green, orange } from '@material-ui/core/colors';
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core';
import { ucWords } from 'utils';
import { format } from 'date-fns';
import { GET_CANDIDATE_PHOTO_URL } from 'constants/url';

interface Props {
  isLoadingData: boolean;
  candidate: CandidateModel;
  index: number;
  setSelectedId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    padding: theme.spacing(2)
  },
  customTypography: {
    marginTop: theme.spacing(0.5),
    textAlign: 'right'
  },
  labelPadding: {
    marginRight: theme.spacing(1)
  },
  tagUserIsGood: {
    backgroundColor: green[500],
    borderRadius: 3,
    color: '#FFFFFF',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2)
  },
  tagUserIsBad: {
    backgroundColor: orange[500],
    borderRadius: 3,
    color: '#FFFFFF',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2)
  },
  tagUserIsNotFlagYet: {
    border: '1px solid #C6C6C6',
    borderRadius: 3,
    color: '#C6C6C6',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2)
  },
  contentTypography: {
    fontWeight: 500
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  skills: {
    border: '1px solid #0B3469',
    borderRadius: 3,
    color: '#0B3469',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    marginLeft: theme.spacing(1)
  },
  status: {
    backgroundColor: '#7A8DA5',
    borderRadius: 3,
    color: '#FFFFFF',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.2),
    paddingBottom: theme.spacing(0.2),
    marginLeft: theme.spacing(1)
  },
  lineHeight: {
    lineHeight: 2
  },
  qualificationLineHeight: {
    lineHeight: 1.7
  },
  avatar: {
    backgroundColor: '#0B3469'
  }
}));

const Content: FC<Props> = props => {
  const classes = useStyles();
  const { isLoadingData, candidate, index, setOpenDialog, setMessage, setSelectedId } = props;
  const [img, setImg] = useState<string>('');

  const handleViewDetailClick = (candidateId: number) => {
    window.open(`/candidates/${candidateId}`, '_blank');
  };

  const handleDeleteActionClick = (candidateId: number) => {
    setOpenDialog(true);
    setSelectedId(candidateId);
    setMessage('Are you sure you want to delete this?');
  };

  let initialName: any = [];
  if (candidate.name) {
    const splitedNames = candidate.name.split(' ');
    splitedNames.map((splitedName, index) => {
      if (index === 0 || index === splitedNames.length - 1) {
        if (splitedName[0]) {
          initialName.push(splitedName[0].toUpperCase());
        } else {
          initialName.push(splitedNames[index - 1][0] ? splitedNames[index - 1][0].toUpperCase() : '');
        }
      }
      return initialName;
    });
  }

  useEffect(() => {
    if (candidate.photo1) {
      axios
        .get(GET_CANDIDATE_PHOTO_URL(candidate.photo1), { headers: { t: localStorage.getItem('token') }, responseType: 'arraybuffer' })
        .then(res => {
          let base64 = btoa(
            new Uint8Array(res.data).reduce((data, byte) => {
              return data + String.fromCharCode(byte);
            }, '')
          );
          let src = 'data:image;base64,' + base64;
          setImg(src);
        });
    }
  }, [candidate.photo1]);

  const renderAvatar = () => {
    if (!candidate.photo1) {
      if (isLoadingData) {
        return <Avatar aria-label='candidateInitial' />;
      } else {
        return (
          <Avatar className={classes.avatar} aria-label='candidateInitial'>
            {initialName.join('')}
          </Avatar>
        );
      }
    } else {
      if (isLoadingData) {
        return <Avatar aria-label='candidateInitial' />;
      } else {
        return <Avatar className={classes.avatar} alt={candidate.name} src={img} />;
      }
    }
  };

  const skills = candidate.skills && candidate.skills.split(',');

  return (
    <Grid item xs={12} sm={4} container key={index}>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          avatar={renderAvatar()}
          action={
            <div className={classes.customTypography}>
              <Typography variant='body1' align='right' display='inline' className={classes.labelPadding}>
                {isLoadingData ? (
                  <Skeleton width={40} />
                ) : (
                  <span
                    className={clsx(
                      { [classes.tagUserIsNotFlagYet]: candidate.tagUser === 'None' },
                      { [classes.tagUserIsGood]: candidate.tagUser === 'Good' },
                      { [classes.tagUserIsBad]: candidate.tagUser === 'Bad' }
                    )}
                  >
                    {candidate.tagUser}
                  </span>
                )}
              </Typography>
              <Tooltip title='Delete' placement='top'>
                <IconButton size='small' onClick={event => handleDeleteActionClick(candidate.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Tooltip>
              <Typography variant='body1' color='primary' display='block'>
                {isLoadingData ? <Skeleton width={120} /> : `Added: ${format(new Date(candidate.createdAt), 'dd/MM/yyyy').toString()}`}
              </Typography>
            </div>
          }
          title={
            <Typography color='primary' variant='h5'>
              {isLoadingData ? <Skeleton width={90} /> : `${candidate.name}`}
            </Typography>
          }
          subheader={
            <Typography color='primary' variant='body1'>
              {isLoadingData ? <Skeleton width={150} /> : `Passport No. ${candidate.passportNumber}`}
            </Typography>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant='h6' align='left'>
                <span className={classes.contentTypography}>
                  {isLoadingData ? (
                    <Skeleton width={60} />
                  ) : candidate.expectedSalary ? (
                    <NumberFormat value={candidate.expectedSalary} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                  ) : (
                    '$ -'
                  )}
                </span>
              </Typography>
              <Typography variant='body1' color='primary' align='left'>
                {isLoadingData ? <Skeleton width={60} /> : 'Expected'}
                <br />
                {isLoadingData ? <Skeleton width={50} /> : 'Salary'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h6' align='left'>
                {isLoadingData ? (
                  <Skeleton width={60} />
                ) : (
                  <span className={classes.contentTypography}>{!candidate.experience ? 0 : candidate.experience} year(s)</span>
                )}
              </Typography>
              <Typography variant='body1' color='primary' align='left'>
                {isLoadingData ? <Skeleton width={50} /> : 'Experience'}
                <br />
                {isLoadingData ? <Skeleton width={70} /> : 'in Singapore'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h6' align='right'>
                {isLoadingData ? <Skeleton width={50} /> : <span className={classes.contentTypography}>{candidate.positionApplied}</span>}
              </Typography>
              <Typography variant='body1' color='primary' align='right'>
                {isLoadingData ? <Skeleton width={60} /> : 'Position'}
                <br />
                {isLoadingData ? <Skeleton width={50} /> : 'Applied'}
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <Grid item xs={2}>
              {isLoadingData ? (
                <Skeleton width={40} />
              ) : (
                <Typography variant='subtitle1' color='primary'>
                  Skills:
                </Typography>
              )}
            </Grid>
            <Grid item xs={10}>
              {isLoadingData ? (
                <Skeleton width={298} />
              ) : (
                skills &&
                skills.map((skill, index) => {
                  return (
                    <Typography variant='body1' align='right' display='inline' className={classes.lineHeight} key={index}>
                      <span className={classes.skills}>{skill} </span>
                    </Typography>
                  );
                })
              )}
            </Grid>
            <Grid item xs={5}>
              {isLoadingData ? (
                <Skeleton width={40} />
              ) : (
                <Typography variant='subtitle1' color='primary'>
                  Highest Qualification:
                </Typography>
              )}
            </Grid>
            <Grid item xs={7}>
              {isLoadingData ? (
                <Skeleton width={50} />
              ) : (
                <Typography variant='body1' align='right' display='inline' className={classes.qualificationLineHeight}>
                  {candidate.qualificationAttain && candidate.qualificationAttain}
                </Typography>
              )}
            </Grid>
            <Grid item xs={2}>
              {isLoadingData ? (
                <Skeleton width={40} />
              ) : (
                <Typography variant='subtitle1' color='primary'>
                  Status:
                </Typography>
              )}
            </Grid>
            <Grid item xs={10}>
              {isLoadingData ? (
                <Skeleton width={50} />
              ) : (
                <Typography variant='body1' align='right' display='inline' className={classes.lineHeight}>
                  <span className={classes.status}>{ucWords(candidate.placementStatus)}</span>
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button fullWidth color='primary' disabled={isLoadingData ? true : false} onClick={event => handleViewDetailClick(candidate.id)}>
            {isLoadingData ? <Skeleton width={100} /> : 'VIEW DETAILS'}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default Content;
