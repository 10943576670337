import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import NotesForm from './components/NotesForm';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_CANDIDATE_URL } from 'constants/url';

interface Props {
  open: boolean;
  candidateId: number;
  candidateNote: string;
  setCandidateNote: React.Dispatch<React.SetStateAction<string>>;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: '#FFFFFF'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const EditNotesModal: React.FC<Props> = props => {
  const classes = useStyles();

  let cancelTokenSource: CancelTokenSource;
  const { open, candidateId, candidateNote, setCandidateNote, handleCancel, setOpenSnackbar, setSnackbarVarient } = props;
  const [isLoading, setLoading] = useState<boolean>(false);

  const [notes, setNotes] = useState<string>('');

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const resetFormValues = useCallback(() => {
    if (!candidateId) {
      return setNotes('');
    }
    setNotes(candidateNote);
  }, [candidateId, candidateNote]);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  const handleOnClose = () => {
    resetFormValues();
    handleCancel();
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      cancelTokenSource = axios.CancelToken.source();
      await axios.put(`${GET_EDIT_CANDIDATE_URL(candidateId)}`, { candidateNotes: notes }, { cancelToken: cancelTokenSource.token });

      setCandidateNote(notes);
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleCancel();
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='sm'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <NotesForm candidateNotes={notes} setCandidateNotes={setNotes} isSubmitting={isLoading} onSubmit={handleOnSubmit} onCancel={handleOnClose} />
      </DialogContent>
    </Dialog>
  );
};

export default EditNotesModal;
