import React, { FC, useState, useCallback } from 'react';
import { Grid, Paper, RootRef, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useDropzone } from 'react-dropzone';

interface Props {
  label: string;
  fileType: 'image' | 'file';
  setPhoto?: React.Dispatch<React.SetStateAction<string>>;
  setResume?: React.Dispatch<React.SetStateAction<string>>;

  onSubmit: React.FormEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  dropZone: {
    padding: theme.spacing(2),
    minHeight: 50
  },
  responsiveScale: {
    width: '100%',
    marginBottom: theme.spacing(3)
  },
  avatar: {
    width: 100,
    height: 70,
    color: '#C4C4C4',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 1px 1px #C4C4C4'
  },
  textClick: {
    marginLeft: 5
  }
}));

const DocumentForm: FC<Props> = props => {
  const classes = useStyles();

  const { label, fileType } = props;
  const { setPhoto, setResume } = props;
  const { onSubmit } = props;

  const [photoName, setPhotoName] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');

  const onDrop = useCallback(
    acceptedFiles => {
      if (fileType === 'image') {
        let image;
        let imageName;
        if (acceptedFiles[0] === undefined) {
          image = '';
          imageName = '';
        } else {
          image = acceptedFiles[0];
          imageName = acceptedFiles[0].name;
        }
        setPhoto && setPhoto(image);
        setPhotoName(imageName);
      } else {
        let file;
        let fileName;
        if (acceptedFiles[0] === undefined) {
          file = '';
          fileName = '';
        } else {
          file = acceptedFiles[0];
          fileName = acceptedFiles[0].name;
        }
        setResume && setResume(file);
        setFileName(fileName);
      }
    },
    [fileType, setPhoto, setResume]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileType === 'image' ? 'image/*' : '.docx, .doc, .pdf',
    multiple: false,
    onDrop
  });
  const { ref, ...rootProps } = getRootProps();

  const renderOverviewFile = () => {
    if (fileType === 'image') {
      return (
        <Typography variant='body1' color='textSecondary'>
          {photoName === '' ? 'Click Or Drag file here' : photoName}
        </Typography>
      );
    } else {
      return (
        <Typography variant='body1' color='textSecondary'>
          {fileName === '' ? 'Click Or Drag file here' : fileName}
        </Typography>
      );
    }
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <RootRef rootRef={ref}>
        <div className={classes.responsiveScale}>
          <Paper {...rootProps} className={classes.dropZone}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={8} container direction='row' justify='flex-start' alignItems='center'>
                <Typography variant='body1' color='textSecondary'>
                  {label}
                </Typography>
                <input {...getInputProps()} />
                <Typography variant='body1' color='textSecondary' className={classes.textClick}>
                  Click Or Drag file here
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} container direction='row' justify={fileType === 'image' ? 'flex-end' : 'flex-start'} alignItems='center'>
                {renderOverviewFile()}
              </Grid>
            </Grid>
          </Paper>
        </div>
      </RootRef>
    </form>
  );
};

export default DocumentForm;
