import React, { FC, useState, useCallback } from 'react';

import { useDropzone } from 'react-dropzone';
import { Grid, makeStyles, Paper, RootRef, Theme, Typography } from '@material-ui/core';

interface Props {
  label: string;
  fileType: 'image' | 'file';
  setPhoto?: React.Dispatch<React.SetStateAction<string>>;
  setResume?: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  dropZone: {
    marginTop: theme.spacing(0.9),
    marginBottom: theme.spacing(0.7),
    padding: theme.spacing(1.5),
    height: 39
  },
  responsiveScale: {
    width: '100%'
  },
  avatar: {
    width: 50,
    height: 70,
    color: '#C4C4C4',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 1px 1px #C4C4C4'
  }
}));

const DropZone: FC<Props> = props => {
  const classes = useStyles(props);
  const { label, fileType } = props;
  const { setPhoto, setResume } = props;

  const [photoName, setPhotoName] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');

  const onDrop = useCallback(
    acceptedFiles => {
      if (fileType === 'image') {
        let image;
        let imageName;
        if (acceptedFiles[0] === undefined) {
          image = '';
          imageName = '';
        } else {
          image = acceptedFiles[0];
          imageName = acceptedFiles[0].name;
        }
        setPhoto && setPhoto(image);
        setPhotoName(imageName);
      } else {
        let file;
        let fileName;
        if (acceptedFiles[0] === undefined) {
          file = '';
          fileName = '';
        } else {
          file = acceptedFiles[0];
          fileName = acceptedFiles[0].name;
        }
        setResume && setResume(file);
        setFileName(fileName);
      }
    },
    [fileType, setPhoto, setResume]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileType === 'image' ? 'image/*' : '.docx, .doc, .pdf',
    multiple: false,
    onDrop
  });
  const { ref, ...rootProps } = getRootProps();

  const renderOverviewFile = () => {
    if (fileType === 'image') {
      return (
        <Typography variant='body1' color='textSecondary'>
          {photoName === '' ? 'Click Or Drag file here' : photoName}
        </Typography>
      );
    } else {
      return (
        <Typography variant='body1' color='textSecondary'>
          {fileName === '' ? 'Click Or Drag file here' : fileName}
        </Typography>
      );
    }
  };

  return (
    <RootRef rootRef={ref}>
      <div className={classes.responsiveScale}>
        <Paper {...rootProps} className={classes.dropZone}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={8} container direction='row' justify='flex-start' alignItems='flex-start'>
              <Typography variant='body1' color='textSecondary'>
                {label}
              </Typography>
              <input {...getInputProps()} />
            </Grid>
            <Grid item xs={6} sm={4} container direction='row' justify='flex-end' alignItems='center'>
              {renderOverviewFile()}
            </Grid>
          </Grid>
        </Paper>
      </div>
    </RootRef>
  );
};
export default DropZone;
