import React, { Fragment } from 'react';
import { Button, Divider, Fab, Grid, IconButton, MenuItem, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

import NumberFormatCustom from 'components/NumberFormatCustom';
import { ucWords } from 'utils';

interface Props {
  highestQualificationMaster: Select[];
  address: string;
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  unit: string;
  setUnit: React.Dispatch<React.SetStateAction<string>>;
  postal: string;
  setPostal: React.Dispatch<React.SetStateAction<string>>;
  candidateFamilyMembers: FamilyMemberModel[];
  setCandidateFamilyMembers: React.Dispatch<React.SetStateAction<FamilyMemberModel[]>>;
  candidateFamilyMembersDeleted: FamilyMemberModel[];
  setCandidateFamilyMembersDeleted: React.Dispatch<React.SetStateAction<FamilyMemberModel[]>>;
  candidateNameOfSchool: string;
  setCandidateNameOfSchool: React.Dispatch<React.SetStateAction<string>>;
  candidateHighestQualification: string;
  setCandidateHighestQualification: React.Dispatch<React.SetStateAction<string>>;
  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  gridClass: {
    marginBottom: theme.spacing(2)
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  deleteButtonGrid: {
    marginBottom: theme.spacing(-2)
  },
  textFieldFont: {
    height: 18,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2.5)
  }
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: '#EF965A',
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const DetailForm: React.FC<Props> = props => {
  const classes = useStyles();

  const {
    highestQualificationMaster,
    address,
    setAddress,
    unit,
    setUnit,
    postal,
    setPostal,
    candidateFamilyMembers,
    setCandidateFamilyMembers,
    candidateFamilyMembersDeleted,
    setCandidateFamilyMembersDeleted,
    candidateNameOfSchool,
    setCandidateNameOfSchool,
    candidateHighestQualification,
    setCandidateHighestQualification,
    isSubmitting,
    onCancel,
    onSubmit
  } = props;

  const handleNameChange = (name: string, nameIndex: number) => {
    const currentFamilyMember = [...candidateFamilyMembers];
    currentFamilyMember[nameIndex].name = name;
    setCandidateFamilyMembers(currentFamilyMember);
  };

  const handleContactNumberChange = (contactNumber: string, contactNumberIndex: number) => {
    const currentFamilyMember = [...candidateFamilyMembers];
    currentFamilyMember[contactNumberIndex].contactNumber = contactNumber;
    setCandidateFamilyMembers(currentFamilyMember);
  };

  const handleAgeChange = (age: number, ageIndex: number) => {
    const currentFamilyMember = [...candidateFamilyMembers];
    currentFamilyMember[ageIndex].age = age;
    setCandidateFamilyMembers(currentFamilyMember);
  };

  const handleRelationshipChange = (relationship: string, relationshipIndex: number) => {
    const currentFamilyMember = [...candidateFamilyMembers];
    currentFamilyMember[relationshipIndex].relationship = relationship;
    setCandidateFamilyMembers(currentFamilyMember);
  };

  const handleOccupationChange = (occupation: string, occupationIndex: number) => {
    const currentFamilyMember = [...candidateFamilyMembers];
    currentFamilyMember[occupationIndex].occupation = occupation;
    setCandidateFamilyMembers(currentFamilyMember);
  };

  const handleAddNewFamilyMembers = () => {
    const currentFamilyMembers = [...candidateFamilyMembers];
    currentFamilyMembers.push({ id: 0, name: '', contactNumber: '', age: 0, relationship: '', occupation: '' });
    setCandidateFamilyMembers(currentFamilyMembers);
  };

  const handleDeleteFamilyMembers = (familyMemberIndex: number) => {
    const currentFamilyMembers = [...candidateFamilyMembers];
    const currentFamilyMembersDeleted = [...candidateFamilyMembersDeleted];
    const deletedData = currentFamilyMembers.splice(familyMemberIndex, 1);
    currentFamilyMembersDeleted.push({
      id: deletedData[0].id,
      name: deletedData[0].name,
      relationship: deletedData[0].relationship,
      age: deletedData[0].age,
      occupation: deletedData[0].occupation,
      contactNumber: deletedData[0].contactNumber
    });
    setCandidateFamilyMembers(currentFamilyMembers);
    setCandidateFamilyMembersDeleted(currentFamilyMembersDeleted);
  };

  const renderFamilyMembers = () => {
    return candidateFamilyMembers.map((value, index) => {
      const renderDeleteButton = () => {
        return (
          <Grid
            item
            xs={12}
            sm={12}
            container
            direction='row'
            justify='flex-end'
            alignItems='flex-end'
            alignContent='center'
            className={classes.deleteButtonGrid}
          >
            <Tooltip title='Delete' placement='left'>
              <IconButton size='small' onClick={event => handleDeleteFamilyMembers(index)}>
                <DeleteIcon fontSize='small' />
              </IconButton>
            </Tooltip>
          </Grid>
        );
      };
      return (
        <Fragment key={index}>
          {renderDeleteButton()}
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='name'
              label='Name'
              value={value.name}
              autoComplete='off'
              onChange={event => handleNameChange(event.target.value, index)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='contact'
              label='Contact No.'
              value={value.contactNumber}
              autoComplete='off'
              onChange={event => handleContactNumberChange(event.target.value, index)}
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                },
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  format: '###############'
                }
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='health'
              label='Relationship'
              value={value.relationship}
              autoComplete='off'
              onChange={event => handleRelationshipChange(event.target.value, index)}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='health'
              label='Age'
              value={value.age}
              autoComplete='off'
              onChange={event => handleAgeChange(+event.target.value, index)}
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                },
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  format: '##'
                }
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            <TextField
              variant='outlined'
              margin='normal'
              fullWidth
              id='health'
              label='Occupation'
              value={value.occupation}
              autoComplete='off'
              onChange={event => handleOccupationChange(event.target.value, index)}
            />
          </Grid>
        </Fragment>
      );
    });
  };

  const renderForm = () => {
    const renderHighestQualification = () => {
      return highestQualificationMaster.map(highestQualification => {
        return (
          <MenuItem key={highestQualification.id} value={highestQualification.name}>
            {ucWords(highestQualification.name)}
          </MenuItem>
        );
      });
    };

    return (
      <Fragment>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <TextField
            margin='dense'
            fullWidth
            id='schoolName'
            label='Name of School'
            value={candidateNameOfSchool}
            onChange={event => setCandidateNameOfSchool(event.target.value)}
            variant='outlined'
            autoComplete='off'
            InputProps={{
              classes: {
                input: classes.textFieldFont
              }
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <TextField
            select
            margin='dense'
            fullWidth
            id='qualification'
            label='Qualification'
            value={candidateHighestQualification}
            onChange={event => setCandidateHighestQualification(event.target.value)}
            variant='outlined'
            autoComplete='off'
            InputProps={{
              classes: {
                input: classes.textFieldFont
              }
            }}
            InputLabelProps={{
              shrink: candidateHighestQualification === '' ? false : true
            }}
          >
            <MenuItem key={0} value=''>
              <em>None</em>
            </MenuItem>
            {renderHighestQualification()}
          </TextField>
        </Grid>
      </Fragment>
    );
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid className={classes.contentGrid}>
        <Typography variant='h4' id='modal-title' color='primary'>
          Home Address
        </Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={1} className={classes.gridClass}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='address'
              label='Address'
              value={address}
              onChange={event => setAddress(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='unit'
              label='Unit No.'
              value={unit}
              onChange={event => setUnit(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='postal'
              label='Postal Code'
              value={postal}
              onChange={event => setPostal(event.target.value)}
              autoComplete='off'
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  format: '#######'
                }
              }}
            />
          </Grid>
        </Grid>
        <Typography variant='h4' id='modal-title' color='primary'>
          Family Members
        </Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={1} className={classes.gridClass}>
          {renderFamilyMembers()}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Tooltip title='Add Family Member' placement='right'>
              <Fab size='small' color='primary' aria-label='add' onClick={handleAddNewFamilyMembers}>
                <AddIcon />
              </Fab>
            </Tooltip>
          </Grid>
        </Grid>
        <Typography variant='h4' id='modal-title' color='primary'>
          Qualifications Attained
        </Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={1} className={classes.gridClass}>
          {renderForm()}
        </Grid>
      </Grid>
      <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' className={classes.cancelButton} disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <SaveButton type='submit' variant='contained' disabled={isSubmitting}>
          Save
        </SaveButton>
      </Grid>
    </form>
  );
};

export default DetailForm;
