import React, { FC, useContext, useState, useCallback, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import clsx from 'clsx';
import useDebounce from 'hooks/useDebounce';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';

import { Container, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import { CurrentPageContext } from 'contexts/CurrentPageContext';
import { format } from 'date-fns';
import { CANDIDATE_BASE_URL, SKILL_BASE_URL } from 'constants/url';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import ActionSnackbar from 'components/ActionSnackbar';

import FilterSection from './components/FilterSection';
import ContentSection from './components/ContentSection';
import PaginationSection from './components/PaginationSection';

import CandidateExportHeaders from 'typings/enum/CandidateExportHeaders';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  },
  spacing: {
    marginLeft: theme.spacing(1)
  },
  divider: {
    marginBottom: theme.spacing(3)
  },
  pageTitleDivider: {
    backgroundColor: '#0B3469',
    width: 45,
    height: 3,
    marginTop: theme.spacing(0.5)
  }
}));

const CandidatesPage: FC = () => {
  useCurrentPageTitleUpdater('Candidates Overview');
  const classes = useStyles();
  const { currentPageTitle } = useContext(CurrentPageContext);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(6);
  const [isSearchingCandidate, setSearchingCandidate] = useState<boolean>(false);
  const [isSearchCandidateError, setSearchCandidateError] = useState<boolean>(false);
  const [candidates, setCandidates] = useState<CandidateModel[]>([]);
  const [count, setCount] = useState<number>(0);

  const [skillQuery, setSkillQuery] = useState<string>('');
  const [skills, setSkills] = useState<Select[]>([]);

  const [flags, setFlags] = useState<Select[]>([]);
  const [employmentsStatus, setEmploymentsStatus] = useState<Select[]>([]);
  const [numberOfYears, setNumberOfYears] = useState<Select[]>([]);
  const [qualifications, setQualifications] = useState<Select[]>([]);

  const [columnFilter, setColumnFilter] = useState<ColumnFilter[]>([]);

  const [headers, setHeaders] = useState<CsvHeaderModel[]>([]);
  const [isDelete, setDelete] = useState<boolean>(false);

  // Search Candidate whenever skill, flag, employment status filter changes
  const fetchData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getParams = () => {
      const params = new URLSearchParams();

      if (columnFilter.length !== 0) {
        columnFilter.map(value => {
          if (value.columnName === 'skillFilter') {
            return params.append('sf', value.valueName.toString());
          } else if (value.columnName === 'flagFilter') {
            if (value.valueName === 'Good') {
              return params.append('ff', '1');
            } else if (value.valueName === 'None') {
              return params.append('ff', '2');
            } else if (value.valueName === 'Bad') {
              return params.append('ff', '3');
            }
          } else if (value.columnName === 'employmentStatusFilter') {
            return params.append('esf', value.valueName.toString());
          } else if (value.columnName === 'numberOfYearsFilter') {
            return params.append('noy', value.valueName.toString());
          } else if (value.columnName === 'qualificationFilter') {
            return params.append('qf', value.valueName.toString());
          }
          return params;
        });
      }

      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    const searchCandidate = async () => {
      setSearchingCandidate(true);
      setSearchCandidateError(false);

      try {
        const url = `${CANDIDATE_BASE_URL}?${getParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        //Set flag data to value of filter
        let candidateFlags: Select[] = [];
        data.flags.map((flag: string, index: number) => {
          if (flag === '1') {
            candidateFlags.push({ name: 'Good', id: index });
          } else if (flag === '2') {
            candidateFlags.push({ name: 'None', id: index });
          } else if (flag === '3') {
            candidateFlags.push({ name: 'Bad', id: index });
          }
          return candidateFlags;
        });

        //Set employment status data to value of filter
        let candidateEmploymentsStatus: Select[] = [];
        data.employmentsStatus.map((employmentStatus: string, index: number) => {
          return candidateEmploymentsStatus.push({ name: employmentStatus, id: index });
        });

        //Set number of years data to value of filter
        let candidateNumberOfYears: Select[] = [];
        [1, 2, 3, 4, 5].map((value: number) => {
          return candidateNumberOfYears.push({ name: value.toString(), id: value });
        });

        //Set qualification data to value of filter
        let candidateQualifications: Select[] = [];
        data.qualifications.map((qualification: string, index: number) => {
          return candidateQualifications.push({ name: qualification, id: index });
        });

        const csvHeader = Object.entries(CandidateExportHeaders);
        let csvHeaders: CsvHeaderModel[] = [];

        for (const [key, label] of csvHeader) {
          csvHeaders.push({ label: label.toString(), key: key.toString() });
        }

        setCount(data.count);

        let candidatesData: CandidateModel[] = [...data.candidates];
        data.candidates.map((candidate: CandidateModel, index: number) => {
          if (candidate.tagUser === '1') {
            candidatesData[index].tagUser = 'Good';
          } else if (candidate.tagUser === '2') {
            candidatesData[index].tagUser = 'None';
          } else if (candidate.tagUser === '3') {
            candidatesData[index].tagUser = 'Bad';
          }
          candidatesData[index].qualificationAttain =
            data.candidates[index].highestQualification && `${data.candidates[index].highestQualification} (${data.candidates[index].nameOfSchool})`;
          return candidatesData;
        });

        setCandidates(candidatesData);

        if (!setSkillQuery) {
          setSkills(data.skills);
        }

        setFlags(candidateFlags);
        setEmploymentsStatus(candidateEmploymentsStatus);
        setNumberOfYears(candidateNumberOfYears);
        setQualifications(candidateQualifications);
        setHeaders(csvHeaders);
      } catch (err) {
        setSearchCandidateError(true);
      }

      setSearchingCandidate(false);
      setDelete(false);
    };

    searchCandidate();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [rowsPerPage, currentPage, columnFilter]);

  // Load skill data if select component on skill filter not empty and populate on skill filter list
  const handleSkillSearch = useCallback((skillSearchQuery: string) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (skillSearchQuery) {
        params.append('q', skillSearchQuery);
      }

      return params.toString();
    };

    const searchSkill = async () => {
      try {
        const url = `${SKILL_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
        setSkills(data.skills);
      } catch (err) {
        console.log(err);
      }
    };

    searchSkill();

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  const debouncedSearchTerm = useDebounce(skillQuery, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSkillSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSkillSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSkillSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Load candidate data if candidate data has been deleted
  useEffect(() => {
    if (isDelete) {
      fetchData();
    }
  }, [isDelete, fetchData]);

  const detailDate = format(new Date(), 'cccc, dd MMMM yyyy').toString();
  const renderGreeting = () => {
    const now = format(new Date(), 'aa').toString();
    if (now === 'AM') {
      return 'Good Morning,';
    } else if (now === 'PM') {
      return 'Good Afternoon,';
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  return (
    <Container maxWidth='lg' className={clsx(classes.root, classes.container)}>
      <Typography variant='h4' color='primary' display='inline'>
        {renderGreeting()} Admin
      </Typography>
      <Typography variant='body1' color='primary' display='inline' className={classes.spacing}>
        {detailDate}
      </Typography>
      <Divider className={classes.divider} />
      <Typography variant='h6' color='primary' display='inline'>
        {currentPageTitle}
        <Divider className={classes.pageTitleDivider} />
      </Typography>
      <FilterSection
        skills={skills}
        flags={flags}
        employmentsStatus={employmentsStatus}
        numberOfYears={numberOfYears}
        qualifications={qualifications}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        skillQuery={skillQuery}
        setSkillQuery={setSkillQuery}
        setCurrentPage={setCurrentPage}
      />
      <ContentSection
        candidates={candidates}
        isLoadingData={isSearchingCandidate}
        headers={headers}
        setDelete={setDelete}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
        handleSetMessageSuccess={handleSetMessageSuccess}
        handleSetMessageError={handleSetMessageError}
      />
      <PaginationSection
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        count={count}
      />
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? messageSuccess : messageError}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
    </Container>
  );
};

export default CandidatesPage;
