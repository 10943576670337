import React, { FC, useState, useEffect, useCallback, Fragment } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { Button, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import axios, { CancelTokenSource } from 'axios';

import useRouter from 'hooks/useRouter';

import EmploymentHistoryTable from './components/EmploymentHistoryTable';
import ActionSnackbar from 'components/ActionSnackbar';

import { JOB_EXPERIENCE_BASE_URL } from 'constants/url';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: 'auto'
  },
  addButton: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  extendedIcon: {
    paddingRight: theme.spacing(1)
  }
}));
const EmploymentHistoryContent: FC = () => {
  const classes = useStyles();
  const { match } = useRouter();
  const candidateId: number = Number(match.params.id);

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const [isSearchingEmploymentHistory, setSearchingEmploymentHistory] = useState<boolean>(false);
  const [isSearchEmploymentHistoryError, setSearchEmploymentHistoryError] = useState<boolean>(false);
  const [employmentHistories, setEmploymentHistories] = useState<EmploymentHistoriesModel[]>([]);
  const [count, setCount] = useState<number>(0);

  const [openCreateEmploymentHistory, setOpenCreateEmploymentHistory] = useState<boolean>(false);
  const [openEditEmploymentHistory, setOpenEditEmploymentHistory] = useState<boolean>(false);
  const [currentEditingEmploymentHistoryIndex, setCurrentEditingEmploymentHistoryIndex] = useState<number>(0);

  const [isDelete, setDelete] = useState<boolean>(false);

  // Search Employment whenever rowsPerPage, currentPage
  const fetchData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (candidateId) {
        params.append('q', candidateId.toString());
      }

      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    const searchEmploymentHistory = async () => {
      setSearchingEmploymentHistory(true);
      setSearchEmploymentHistoryError(false);

      try {
        const url = `${JOB_EXPERIENCE_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
        setCount(data.count);
        setEmploymentHistories(data.employmentHistories);
      } catch (err) {
        setSearchEmploymentHistoryError(true);
      }

      setSearchingEmploymentHistory(false);
      setDelete(false);
    };

    searchEmploymentHistory();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [rowsPerPage, currentPage, candidateId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleOpenCreateEmploymentHistory = () => {
    setOpenEditEmploymentHistory(false);
    setOpenCreateEmploymentHistory(true);
  };

  const handleCancelCreateEmploymentHistory = () => {
    setOpenCreateEmploymentHistory(false);
  };

  const handleOpenEditEmploymentHistory = (employmentHistoryIndex: number): React.MouseEventHandler => () => {
    setCurrentEditingEmploymentHistoryIndex(employmentHistoryIndex);
    setOpenCreateEmploymentHistory(false);
    setOpenEditEmploymentHistory(true);
  };

  const handleCancelEditEmploymentHistory = () => {
    setOpenEditEmploymentHistory(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const addNewEmploymentHistory = (employmentHistory: EmploymentHistoriesModel) => {
    employmentHistory.new = true;
    employmentHistories.unshift(employmentHistory);
    setEmploymentHistories([...employmentHistories].slice(0, rowsPerPage));
    setCount(c => c + 1);
  };

  const updateIndividualEmploymentHistory = (updatedEmploymentHistoryProperties: Partial<EmploymentHistoriesModel>) => {
    setEmploymentHistories(
      employmentHistories!.map((employmentHistory, index) => {
        if (index !== currentEditingEmploymentHistoryIndex) {
          return employmentHistory;
        }

        return Object.assign({}, employmentHistory, updatedEmploymentHistoryProperties);
      })
    );
  };

  // Load employment history data if employment history data has been deleted
  useEffect(() => {
    if (isDelete) {
      fetchData();
    }
  }, [isDelete, fetchData]);

  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Grid container justify='space-between'>
          <Typography variant='h5' color='primary'>
            All Employers
          </Typography>
          <Button
            color='primary'
            size='medium'
            variant='outlined'
            className={classes.addButton}
            onClick={() => {
              handleOpenCreateEmploymentHistory();
            }}
          >
            <AddIcon className={classes.extendedIcon} />
            ADD EMPLOYMENT HISTORY
          </Button>
        </Grid>
        <EmploymentHistoryTable
          candidateId={candidateId}
          isLoadingData={isSearchingEmploymentHistory}
          employmentHistories={employmentHistories}
          count={count}
          setOpenSnackbar={setOpenSnackbar}
          setSnackbarVarient={setSnackbarVarient}
          handleSetMessageSuccess={handleSetMessageSuccess}
          handleSetMessageError={handleSetMessageError}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          handleChangePage={(event, page) => setCurrentPage(page)}
          handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
          openCreateEmploymentHistory={openCreateEmploymentHistory}
          handleCancelCreateEmploymentHistory={handleCancelCreateEmploymentHistory}
          addNewEmploymentHistory={addNewEmploymentHistory}
          setDelete={setDelete}
          openEditEmploymentHistory={openEditEmploymentHistory}
          employmentHistory={employmentHistories[currentEditingEmploymentHistoryIndex]}
          currentEditingEmploymentHistoryIndex={currentEditingEmploymentHistoryIndex}
          handleOpenEditEmploymentHistory={handleOpenEditEmploymentHistory}
          handleCancelEditEmploymentHistory={handleCancelEditEmploymentHistory}
          updateIndividualEmploymentHistory={updateIndividualEmploymentHistory}
        />
      </Paper>
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? messageSuccess : messageError}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
    </Fragment>
  );
};

export default EmploymentHistoryContent;
