import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import StatusForm from './components/StatusForm';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_CANDIDATE_URL, JOB_EXPERIENCE_BASE_URL } from 'constants/url';

interface Props {
  open: boolean;
  candidateId: number;
  candidateEmployementStatus: string;
  setCandidateEmployementStatus: React.Dispatch<React.SetStateAction<string>>;
  candidatePlacement: string;
  setCandidatePlacement: React.Dispatch<React.SetStateAction<string>>;
  candidateRating: string;
  setCandidateRating: React.Dispatch<React.SetStateAction<string>>;
  candidateHealth: boolean | undefined;
  setCandidateHeatlh: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: '#FFFFFF'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const EditStatusModal: React.FC<Props> = props => {
  const classes = useStyles();

  let cancelTokenSource: CancelTokenSource;
  const {
    open,
    candidateId,
    candidateEmployementStatus,
    setCandidateEmployementStatus,
    candidatePlacement,
    setCandidatePlacement,
    candidateRating,
    setCandidateRating,
    candidateHealth,
    setCandidateHeatlh,
    handleCancel,
    setOpenSnackbar,
    setSnackbarVarient
  } = props;
  const [isLoading, setLoading] = useState<boolean>(false);

  const [placement, setPlacement] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [position, setPosition] = useState<string>('');
  const [rating, setRating] = useState<string>('');
  const [health, setHealth] = useState<boolean | undefined>(false);
  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const resetFormValues = useCallback(() => {
    if (!candidateId) {
      return setCandidateEmployementStatus('');
    }
    setPlacement(candidateEmployementStatus);
    setRating(candidateRating);
    setCompany(candidatePlacement);
    setHealth(candidateHealth);
  }, [candidateId, candidateEmployementStatus, candidateRating, candidatePlacement, setCandidateEmployementStatus, candidateHealth]);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  const handleOnClose = () => {
    resetFormValues();
    handleCancel();
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      cancelTokenSource = axios.CancelToken.source();
      await axios.put(
        `${GET_EDIT_CANDIDATE_URL(candidateId)}`,
        { tagUser: rating, placementStatus: placement, placementTo: company, injuriesHealthIssue: health },
        { cancelToken: cancelTokenSource.token }
      );

      if (placement === 'ASSIGNED') {
        await axios.post(
          `${JOB_EXPERIENCE_BASE_URL}`,
          {
            numberOfYears: 0,
            companyName: company,
            position,
            candidateId
          },
          { cancelToken: cancelTokenSource.token }
        );
      }

      setCandidateEmployementStatus(placement);
      setCandidateRating(rating);
      setCandidatePlacement(company);
      setCandidateHeatlh(health);
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleCancel();
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='sm'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <StatusForm
          candidateEmployementStatus={placement}
          setCandidateEmployementStatus={setPlacement}
          candidatePlacement={company}
          setCandidatePlacement={setCompany}
          candidatePosition={position}
          setCandidatePosition={setPosition}
          candidateRating={rating}
          setCandidateRating={setRating}
          candidateHealth={health}
          setCandidateHealth={setHealth}
          isSubmitting={isLoading}
          onSubmit={handleOnSubmit}
          onCancel={handleOnClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditStatusModal;
