import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ProfileForm from './components/ProfileForm';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_CANDIDATE_URL, RESUME_BASE_URL } from 'constants/url';

interface Props {
  open: boolean;
  candidateId: number;
  candidateEmail: string;
  setCandidateEmail: React.Dispatch<React.SetStateAction<string>>;
  candidatePhone: string;
  setCandidatePhone: React.Dispatch<React.SetStateAction<string>>;
  candidateDateofBirth: Date | null;
  setCandidateDateofBirth: React.Dispatch<React.SetStateAction<Date | null>>;
  candidatePassport: string;
  setCandidatePassport: React.Dispatch<React.SetStateAction<string>>;
  candidatePassportExp: Date;
  setCandidatePassportExp: React.Dispatch<React.SetStateAction<Date | null>>;
  candidateHeight: number;
  setCandidateHeight: React.Dispatch<React.SetStateAction<number>>;
  candidateWeight: number;
  setCandidateWeight: React.Dispatch<React.SetStateAction<number>>;
  candidateMarital: string;
  setCandidateMarital: React.Dispatch<React.SetStateAction<string>>;
  candidateLanguage: string;
  setCandidateLanguage: React.Dispatch<React.SetStateAction<string>>;
  candidateReligion: string;
  setCandidateReligion: React.Dispatch<React.SetStateAction<string>>;

  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: '#FFFFFF'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const EditProfileModal: React.FC<Props> = props => {
  const classes = useStyles();

  let cancelTokenSource: CancelTokenSource;
  const {
    open,
    candidateId,
    candidateEmail,
    setCandidateEmail,
    candidatePhone,
    setCandidatePhone,
    candidateDateofBirth,
    setCandidateDateofBirth,
    candidatePassport,
    setCandidatePassport,
    candidatePassportExp,
    setCandidatePassportExp,
    candidateHeight,
    setCandidateHeight,
    candidateWeight,
    setCandidateWeight,
    candidateMarital,
    setCandidateMarital,
    candidateLanguage,
    setCandidateLanguage,
    candidateReligion,
    setCandidateReligion,
    handleCancel,
    setOpenSnackbar,
    setSnackbarVarient
  } = props;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [maritalStatusMaster, setMaritalStatusMaster] = useState<Select[]>([]);
  const [languageSpokenMaster, setLanguageSpokenMaster] = useState<Select[]>([]);

  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [birth, setBirth] = useState<Date | null>(new Date());
  const [passport, setPassport] = useState<string>('');
  const [passportExp, setPassportExp] = useState<Date | null>(new Date());
  const [height, setHeight] = useState<number>(0);
  const [weight, setWeight] = useState<number>(0);
  const [marital, setMarital] = useState<string>('');
  const [language, setLanguage] = useState<string>('');
  const [religion, setReligion] = useState<string>('');

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const resetFormValues = useCallback(() => {
    if (!candidateEmail) {
      return setEmail('');
    }
    setEmail(candidateEmail);
    setPhone(candidatePhone);
    setBirth(candidateDateofBirth);
    setPassport(candidatePassport);
    setPassportExp(candidatePassportExp);
    setHeight(candidateHeight);
    setWeight(candidateWeight);
    setMarital(candidateMarital);
    setLanguage(candidateLanguage);
    setReligion(candidateReligion);
  }, [
    candidateEmail,
    candidatePhone,
    candidateDateofBirth,
    candidatePassport,
    candidatePassportExp,
    candidateHeight,
    candidateWeight,
    candidateMarital,
    candidateReligion,
    candidateLanguage
  ]);

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const loadProperties = async () => {
      setLoading(true);

      try {
        const url = `${RESUME_BASE_URL}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        //Set marital status master
        let maritalStatusData: Select[] = [];
        data.maritalStatus.map((value: string, index: number) => {
          return maritalStatusData.push({ name: value, id: index });
        });
        setMaritalStatusMaster(maritalStatusData);

        //Set language spoken master
        let languageSpokenData: Select[] = [];
        data.languageSpoken.map((value: string, index: number) => {
          return languageSpokenData.push({ name: value, id: index });
        });
        setLanguageSpokenMaster(languageSpokenData);
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    };

    loadProperties();

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  const handleOnClose = () => {
    resetFormValues();
    handleCancel();
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      cancelTokenSource = axios.CancelToken.source();
      await axios.put(
        `${GET_EDIT_CANDIDATE_URL(candidateId)}`,
        {
          email,
          phone,
          birth,
          passport,
          passportExp,
          height,
          weight,
          marital,
          language,
          religion
        },
        { cancelToken: cancelTokenSource.token }
      );

      setCandidateEmail(email);
      setCandidatePhone(phone);
      setCandidateDateofBirth(birth);
      setCandidatePassport(passport);
      setCandidatePassportExp(passportExp);
      setCandidateHeight(height);
      setCandidateWeight(weight);
      setCandidateMarital(marital);
      setCandidateLanguage(language);
      setCandidateReligion(religion);
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleCancel();
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='sm'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ProfileForm
          maritalStatusMaster={maritalStatusMaster}
          languageSpokenMaster={languageSpokenMaster}
          candidateEmail={email}
          setCandidateEmail={setEmail}
          candidatePhone={phone}
          setCandidatePhone={setPhone}
          candidateDateofBirth={birth}
          setCandidateDateofBirth={setBirth}
          candidatePassport={passport}
          setCandidatePassport={setPassport}
          candidatePassportExp={passportExp}
          setCandidatePassportExp={setPassportExp}
          candidateHeight={height}
          setCandidateHeight={setHeight}
          candidateWeight={weight}
          setCandidateWeight={setWeight}
          candidateMarital={marital}
          setCandidateMarital={setMarital}
          candidateLanguage={language}
          setCandidateLanguage={setLanguage}
          candidateReligion={religion}
          setCandidateReligion={setReligion}
          isSubmitting={isLoading}
          onSubmit={handleOnSubmit}
          onCancel={handleOnClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditProfileModal;
