import React, { Fragment, useEffect, useState } from 'react';
import AppHeader from 'components/AppHeader';
import contentLogo from 'images/header.jpeg';
import imageLoader from 'images/imageLoader.gif';
import useDebounce from 'hooks/useDebounce';
import axios, { CancelTokenSource } from 'axios';
import {
  Backdrop,
  Box,
  Button,
  Container,
  Checkbox,
  CssBaseline,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  Link,
  makeStyles,
  Modal,
  Theme,
  Typography
} from '@material-ui/core';
import { isValidEmail } from 'utils';
import { format } from 'date-fns';
import { RESUME_BASE_URL, RESUME_CHECKING_PASSPORT_NUMBER_URL } from 'constants/url';
import { StandardConfirmationDialog } from 'components/AppDialog';
import ElevationScroll, { Props } from './components/ElevationScroll';
import PersonalProfile from './components/PersonalProfile';
import QualificationAttained from './components/QualificationAttained';
import JobExperience from './components/JobExperience';
import FamilyMember from './components/FamilyMember';
import ProfessionalSkill from './components/ProfessionalSkill';
import InjuriesHealthIssue from './components/InjuriesHealthIssue';
import UploadPhotoAndCv from './components/UploadPhotoAndCv';

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    width: '340px'
  },
  container: {
    padding: theme.spacing(2)
  },

  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  confirmationStatement1: {
    marginTop: theme.spacing(3)
  },
  confirmationStatement2: {
    marginTop: theme.spacing(2)
  },
  buttonSubmit: {
    marginTop: theme.spacing(2)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loader: {
    width: '50%',
    marginLeft: theme.spacing(-1.5)
  }
}));

const ResumePage = (props: Props) => {
  const classes = useStyles();
  let cancelTokenSource: CancelTokenSource;

  const [isLoadingPage, setLoadingPage] = useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [skillsMaster, setSkillsMaster] = useState<Select[]>([]);
  const [maritalStatusMaster, setMaritalStatusMaster] = useState<Select[]>([]);
  const [languageSpokenMaster, setLanguageSpokenMaster] = useState<Select[]>([]);
  const [highestQualificationMaster, setHighestQualificationMaster] = useState<Select[]>([]);
  const [isCheckingPassportNumber, setCheckingPassportNumber] = useState<boolean>(false);
  const [isAgree, setAgree] = useState<boolean>(true);

  //set fake state
  const [address, setAddress] = useState<string>('');
  const [unitNo, setUnitNo] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [isNewSkill, setNewSkill] = useState<boolean>(false);
  const [customSkills, setCustomSkills] = useState<string[]>(['']);

  // set state to send to api
  const [positionApplied, setPositionApplied] = useState<string>('');
  const [expectedSalary, setExpectedSalary] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [homeAddress, setHomeAddress] = useState<string>('');
  const [contactNumber, setContactNumber] = useState<string>('');
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [passportNumber, setPassportNumber] = useState<string>('');
  const [passportExpiryDate, setPassportExpiryDate] = useState<Date | null>(null);
  const [height, setHeight] = useState<number>(0);
  const [weight, setWeight] = useState<number>(0);
  const [maritalStatus, setMaritalStatus] = useState<string>('');
  const [religion, setReligion] = useState<string>('');
  const [languageSpoken, setLanguageSpoken] = useState<string>('');
  const [photo1, setPhoto1] = useState<string>('');
  const [photo2, setPhoto2] = useState<string>('');
  const [photo3, setPhoto3] = useState<string>('');
  const [photo4, setPhoto4] = useState<string>('');
  const [resume, setResume] = useState<string>('');
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [nameOfSchool, setNameOfSchool] = useState<string>('');
  const [highestQualification, setHighestQualification] = useState<string>('');
  const [jobExperience, setJobExperience] = useState<EmploymentHistoriesModel[]>([
    { companyName: '', numberOfYears: 0, position: '', companyNameError: '', numberOfYearsError: '', positionError: '' }
  ]);
  const [familyMember, setFamilyMember] = useState<FamilyMemberModel[]>([{ name: '', contactNumber: '', age: 0, relationship: '', occupation: '' }]);
  const [selectedSkillsMaster, setSelectedSkillsMaster] = useState<string>('');
  const [skills, setSkills] = useState<string>('');
  const [injuriesHealthIssue, setInjuriesHealthIssue] = useState<boolean>();

  // set state to validation form
  const [positionAppliedError, setPositionAppliedError] = useState<string>('');
  const [expectedSalaryError, setExpectedSalaryError] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [contactNumberError, setContactNumberError] = useState<string>('');
  const [dateOfBirthError, setDateOfBirthError] = useState<string>('');
  const [passportNumberError, setPassportNumberError] = useState<string>('');
  const [emailAddressError, setEmailAddressError] = useState<string>('');
  const [injuriesHealthIssueError, setInjuriesHealthIssueError] = useState<string>('');

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const loadProperties = async () => {
      setLoadingPage(true);

      try {
        const url = `${RESUME_BASE_URL}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        //Set skill master
        setSkillsMaster(data.skills);

        //Set marital status master
        let maritalStatusData: Select[] = [];
        data.maritalStatus.map((value: string, index: number) => {
          return maritalStatusData.push({ name: value, id: index });
        });
        setMaritalStatusMaster(maritalStatusData);

        //Set language spoken master
        let languageSpokenData: Select[] = [];
        data.languageSpoken.map((value: string, index: number) => {
          return languageSpokenData.push({ name: value, id: index });
        });
        setLanguageSpokenMaster(languageSpokenData);

        //Set highest qualification master
        let highestQualificationData: Select[] = [];
        data.highestQualification.map((value: string, index: number) => {
          return highestQualificationData.push({ name: value, id: index });
        });
        setHighestQualificationMaster(highestQualificationData);
      } catch (err) {
        console.log(err);
      }

      setLoadingPage(false);
    };

    loadProperties();

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  const clearFormErrors = () => {
    setPositionAppliedError('');
    setExpectedSalaryError('');
    setNameError('');
    setContactNumberError('');
    setDateOfBirthError('');
    if (passportNumberError === '') {
      setPassportNumberError('');
    }
    jobExperience.map(value => {
      value.companyNameError = '';
      value.numberOfYearsError = '';
      value.positionError = '';
      return jobExperience;
    });
    setJobExperience(jobExperience);
    setEmailAddressError('');
    setInjuriesHealthIssueError('');
  };

  const validateForm = () => {
    let ret = true;
    clearFormErrors();

    if (!positionApplied || !positionApplied.trim()) {
      setPositionAppliedError('Please enter position applied');
      ret = false;
    }

    if (!expectedSalary) {
      setExpectedSalaryError('Please enter expected salary');
      ret = false;
    }

    if (!name || !name.trim()) {
      setNameError('Please enter candidite name');
      ret = false;
    }

    if (!contactNumber || !contactNumber.trim()) {
      setContactNumberError('Please enter candidite contact number');
      ret = false;
    }

    if (dateOfBirth === null) {
      setDateOfBirthError('Please enter candidite date of birth');
      ret = false;
    }

    if (!passportNumber || !passportNumber.trim()) {
      setPassportNumberError('Please enter candidite passport number');
      ret = false;
    }

    // Set validate for candidate email
    if (emailAddress !== '') {
      if (!isValidEmail(emailAddress)) {
        setEmailAddress('');
        setEmailAddressError('Please enter an valid email, e.g: example@email.com');
        ret = false;
      }
    }

    // Set validate for candidate injuries health issue
    if (injuriesHealthIssue === undefined) {
      setInjuriesHealthIssueError('Please enter candidite injuries health issue');
      ret = false;
    }

    // Set validate for job experience form
    let validatedJobExperience = [...jobExperience];
    jobExperience.map((value, index) => {
      if (!value.companyName || !value.companyName.trim()) {
        validatedJobExperience[index].companyNameError = `Please enter company name, if you don't have, you can type '-'`;
        ret = false;
      }

      if (!value.position || !value.position.trim()) {
        validatedJobExperience[index].positionError = `Please enter position, if you don't have, you can type '-'`;
        ret = false;
      }

      return ret;
    });
    setJobExperience(validatedJobExperience);

    return ret;
  };

  // implement debounce to search exist passport number
  const debouncedSearchTerm = useDebounce(passportNumber, 500);

  // Checking passport number to database
  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

      const getQueryParams = () => {
        const params = new URLSearchParams();
        if (debouncedSearchTerm) {
          params.append('q', debouncedSearchTerm);
        }

        return params.toString();
      };

      const checkingPassportNumber = async () => {
        setCheckingPassportNumber(true);

        try {
          const url = `${RESUME_CHECKING_PASSPORT_NUMBER_URL}?${getQueryParams()}`;
          const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
          if (data.countOfPassportNumber === 0) {
            setPassportNumberError('');
          }
        } catch (err) {
          console.log(err);
          const { errorCode } = err.data;

          if (errorCode === 11) {
            setPassportNumberError('Duplicated');
          }
        }

        setCheckingPassportNumber(false);
      };

      checkingPassportNumber();

      return () => {
        cancelTokenSource.cancel();
      };
    }
  }, [debouncedSearchTerm]);

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();

    if (!validateForm() || passportNumberError !== '') {
      return;
    }

    // Set fixed family members
    let newFamilyMember: FamilyMemberModel[] = [];

    familyMember.map((value, index) => {
      if (value.name !== '') {
        newFamilyMember.push([...familyMember][index]);
      }
      return newFamilyMember;
    });

    setLoadingPage(true);

    try {
      cancelTokenSource = axios.CancelToken.source();
      const formData = new FormData();

      formData.append('positionApplied', positionApplied);
      formData.append('expectedSalary', expectedSalary === 0 ? '' : `${expectedSalary}`);
      formData.append('name', name);
      formData.append('homeAddress', homeAddress);
      formData.append('contactNumber', contactNumber);
      formData.append('dateOfBirth', dateOfBirth === null ? '' : format(dateOfBirth, 'yyyy-MM-dd'));
      formData.append('passportNumber', passportNumber);
      formData.append('passportExpiryDate', passportExpiryDate === null ? '' : format(passportExpiryDate, 'yyyy-MM-dd'));
      formData.append('height', height === 0 ? '' : `${height}`);
      formData.append('weight', weight === 0 ? '' : `${weight}`);
      formData.append('maritalStatus', maritalStatus);
      formData.append('religion', religion);
      formData.append('languageSpoken', languageSpoken);
      formData.append('photo1', photo1);
      formData.append('photo2', photo2);
      formData.append('photo3', photo3);
      formData.append('photo4', photo4);
      formData.append('resume', resume);
      formData.append('emailAddress', emailAddress);
      formData.append('injuriesHealthIssue', `${injuriesHealthIssue}`);
      formData.append('nameOfSchool', nameOfSchool);
      formData.append('highestQualification', highestQualification);
      formData.append('jobExperience', JSON.stringify(jobExperience));
      formData.append('familyMember', JSON.stringify(newFamilyMember));
      formData.append('skills', skills);

      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        },
        cancelToken: cancelTokenSource.token
      };

      await axios.post(`${RESUME_BASE_URL}`, formData, config);
    } catch (err) {
      console.log(err);
      const { errorCode } = err.data;

      if (errorCode === 11) {
        setPassportNumberError('Duplicated');
      }
    }

    setLoadingPage(false);
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
    window.location.reload();
  };

  const handleAgreement = () => {
    setAgree(!isAgree);
  };

  return (
    <Fragment>
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppHeader isAdminHeader={false} />
      </ElevationScroll>

      <Container className={classes.container}>
        <Box my={2}>
          <Grid container>
            <Grid item xs={12} sm={3} container direction='row' justify='center' alignItems='center'>
              <img src={contentLogo} alt='appLogo' className={classes.logo} />
            </Grid>
          </Grid>
          <Typography variant='h4' color='primary'>
            EMPLOYMENT APPLICATION FORM 工作申请表
          </Typography>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            <PersonalProfile
              maritalStatusMaster={maritalStatusMaster}
              languageSpokenMaster={languageSpokenMaster}
              positionApplied={positionApplied}
              setPositionApplied={setPositionApplied}
              expectedSalary={expectedSalary}
              setExpectedSalary={setExpectedSalary}
              name={name}
              setName={setName}
              address={address}
              setAddress={setAddress}
              unitNo={unitNo}
              setUnitNo={setUnitNo}
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              setHomeAddress={setHomeAddress}
              contactNumber={contactNumber}
              setContactNumber={setContactNumber}
              dateOfBirth={dateOfBirth}
              setDateOfBirth={setDateOfBirth}
              passportNumber={passportNumber}
              setPassportNumber={setPassportNumber}
              passportExpiryDate={passportExpiryDate}
              setPassportExpiryDate={setPassportExpiryDate}
              height={height}
              setHeight={setHeight}
              weight={weight}
              setWeight={setWeight}
              maritalStatus={maritalStatus}
              setMaritalStatus={setMaritalStatus}
              religion={religion}
              setReligion={setReligion}
              setLanguageSpoken={setLanguageSpoken}
              emailAddress={emailAddress}
              setEmailAddress={setEmailAddress}
              positionAppliedError={positionAppliedError}
              expectedSalaryError={expectedSalaryError}
              nameError={nameError}
              contactNumberError={contactNumberError}
              dateOfBirthError={dateOfBirthError}
              passportNumberError={passportNumberError}
              emailAddressError={emailAddressError}
              isCheckingPassportNumber={isCheckingPassportNumber}
            />
            <ProfessionalSkill
              skillsMaster={skillsMaster}
              selectedSkillsMaster={selectedSkillsMaster}
              setSelectedSkillsMaster={setSelectedSkillsMaster}
              isNewSkill={isNewSkill}
              setNewSkill={setNewSkill}
              customSkills={customSkills}
              setCustomSkills={setCustomSkills}
              setSkills={setSkills}
            />
            <JobExperience jobExperience={jobExperience} setJobExperience={setJobExperience} />
            <InjuriesHealthIssue
              injuriesHealthIssue={injuriesHealthIssue}
              setInjuriesHealthIssue={setInjuriesHealthIssue}
              injuriesHealthIssueError={injuriesHealthIssueError}
            />
            <QualificationAttained
              highestQualificationMaster={highestQualificationMaster}
              nameOfSchool={nameOfSchool}
              setNameOfSchool={setNameOfSchool}
              highestQualification={highestQualification}
              setHighestQualification={setHighestQualification}
            />
            <FamilyMember familyMember={familyMember} setFamilyMember={setFamilyMember} />
            <UploadPhotoAndCv setPhoto1={setPhoto1} setPhoto2={setPhoto2} setPhoto3={setPhoto3} setPhoto4={setPhoto4} setResume={setResume} />
          </Grid>
          <Typography variant='body1' color='textSecondary' className={classes.confirmationStatement2}>
            To send us more supporting documents, please email us at askhuijiemanpower@gmail.com or whatsapp:+6596395669.
            如需提交更多相关文件，请发电邮或发 whatsapp:+6596395669
          </Typography>
          <FormControlLabel
            className={classes.confirmationStatement1}
            control={<Checkbox checked={!isAgree} color='primary' />}
            onClick={handleAgreement}
            label={
              <span style={{ color: '#757575' }}>
                I hereby declare that all information given in this form is true and correct. 我宣誓, 以上申请表格内容全部属实.
              </span>
            }
          />
          <Typography variant='body1' color='textSecondary' className={classes.confirmationStatement2}>
            By submitting your curriculum vitae or personal data to us in connection with your job application, you are deemed to have read and agreed
            to the terms of our{' '}
            <u>
              <Link href='https://huijieea.sg/privacy-policy/' color='inherit'>
                Privacy Policy
              </Link>
            </u>
            , and consented to the collection, use and disclosure of your personal data by us and our affiliates in accordance with our{' '}
            <u>
              <Link href='https://huijieea.sg/privacy-policy/' color='inherit'>
                Privacy Policy
              </Link>
            </u>
            .
            一旦提交与工作申请有关的简历或个人资料，您将被视为已阅读并同意我公司的隐私条款政策，并且同意我公司对您的个人资料进行收集、使用和出示到我公司合作单位.
          </Typography>
          <Button variant='contained' color='primary' className={classes.buttonSubmit} onClick={handleOnSubmit} disabled={isAgree}>
            SUBMIT / 提交
          </Button>
          <Modal
            aria-labelledby='spring-modal-title'
            aria-describedby='spring-modal-description'
            className={classes.modal}
            open={isLoadingPage}
            disableBackdropClick
            disableAutoFocus
            disableEnforceFocus
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <Fade in={isLoadingPage}>
              <img src={imageLoader} alt='imageLoader' className={classes.loader} />
            </Fade>
          </Modal>
          <StandardConfirmationDialog
            variant={'success'}
            message={'Your resume has been saved !'}
            open={openConfirmationDialog}
            handleClose={handleCloseConfirmationDialog}
            noCancelButton={true}
          />
        </Box>
      </Container>
    </Fragment>
  );
};

export default ResumePage;
