import React, { FC, useState } from 'react';
import { Button, Checkbox, ClickAwayListener, Grid, List, ListItem, ListItemText, makeStyles, TextField, Theme, withStyles } from '@material-ui/core';
import { Option } from 'components/PositionedPopper';
import { ucWords } from 'utils';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

interface Props {
  setOpenPopper: React.Dispatch<React.SetStateAction<boolean>>;
  options: Option[];
  columnFilter?: ColumnFilter[];
  setColumnFilter?: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    padding: theme.spacing(0),
    marginTop: theme.spacing(-1),
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checkBox: {
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  clearButton: {
    color: '#567091',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#0B3469'
    },
    padding: theme.spacing(0)
  },
  checkBoxSize: {
    fontSize: '16px'
  },
  textFieldFont: {
    fontSize: '13px',
    height: 18
  }
}));

const ClearButton = withStyles({
  label: {
    textTransform: 'capitalize',
    marginLeft: 25
  }
})(Button);

const CheckBoxPicker: FC<Props> = props => {
  const classes = useStyles(props);
  const { setOpenPopper, options, columnFilter, setColumnFilter, setCurrentPage } = props;

  const handleCloseMenuListPopper = () => {
    setOpenPopper(false);
  };

  const [selectedFilter, setSelectedFilter] = useState<ColumnFilter[]>(columnFilter === undefined ? [] : columnFilter);

  const handleClickCheckBox = (columnName: string, valueId: number, valueName: string) => () => {
    setCurrentPage && setCurrentPage(0);
    const newSelectedFilter = [...selectedFilter];
    // count element in object selected filter for check already exist or not
    const countElement = newSelectedFilter.filter(selected => selected.filterKey === `${columnName}-${valueId}`).length;
    if (countElement === 0) {
      newSelectedFilter.push({ filterKey: `${columnName}-${valueId}`, columnName, valueId, valueName });
    } else {
      // check index of element and remove object by index
      const selectedFilterIndex = newSelectedFilter.map(x => x.filterKey).indexOf(`${columnName}-${valueId}`);
      newSelectedFilter.splice(selectedFilterIndex, 1);
    }
    setSelectedFilter(newSelectedFilter);
    setColumnFilter && setColumnFilter(newSelectedFilter);
  };

  const handleClickClearButton = (columnName: string) => {
    let currentFilter = [...selectedFilter];

    // check id of filter element and renew filter
    const newSelectedFilter: ColumnFilter[] = [];
    currentFilter.map(x => {
      const selectedColumnName = x.filterKey.split('-')[0];
      if (selectedColumnName !== columnName) {
        newSelectedFilter.push(x);
      }
      return newSelectedFilter;
    });
    setSelectedFilter(newSelectedFilter);
    setColumnFilter && setColumnFilter(newSelectedFilter);
  };

  const renderContent = () => {
    return options.map(option => {
      const selectComponent = () => {
        if (option.isFilterWithSelect) {
          return (
            <TextField
              margin='dense'
              fullWidth
              id={option.key}
              label={`Search ${option.label}...`}
              value={option.query && option.query}
              onChange={event => option.setQuery && option.setQuery(event.target.value)}
              variant='outlined'
              autoComplete='off'
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          );
        }
      };
      return (
        <Grid item key={option.key} xs={12}>
          {selectComponent()}
          <List>
            {option.checkBoxList &&
              option.checkBoxList.map(checkBox => {
                // check checkBox is checked
                const checkedCheckbox = selectedFilter.filter(selected => selected.filterKey === `${option.key}-${checkBox.id}`).length;
                const renderListItemText = () => {
                  if (option.key === 'numberOfYearsFilter') {
                    if (checkBox.name === '5') {
                      return `≥ ${checkBox.name} Years`;
                    } else {
                      if (checkBox.name === '1') {
                        return `${checkBox.name} Year`;
                      } else {
                        return `${checkBox.name} Years`;
                      }
                    }
                  } else {
                    return ucWords(checkBox.name);
                  }
                };
                return (
                  <ListItem
                    key={checkBox.id}
                    dense
                    button
                    disableRipple
                    onClick={handleClickCheckBox(option.key, checkBox.id, checkBox.name)}
                    className={classes.listItem}
                  >
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon className={classes.checkBoxSize} />}
                      checkedIcon={<CheckBoxIcon className={classes.checkBoxSize} />}
                      edge='start'
                      color='primary'
                      className={classes.checkBox}
                      checked={checkedCheckbox === 0 ? false : true}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': `${option.key}-${checkBox.id}` }}
                    />
                    <ListItemText id={`${option.key}-${checkBox.id}`} primary={renderListItemText()} />
                  </ListItem>
                );
              })}
          </List>
        </Grid>
      );
    });
  };

  return (
    <ClickAwayListener onClickAway={handleCloseMenuListPopper}>
      <div>
        <Grid container direction='row' justify='flex-end' alignItems='flex-start'>
          <ClearButton size='small' className={classes.clearButton} onClick={event => handleClickClearButton(options[0].key)} disableRipple>
            Clear
          </ClearButton>
        </Grid>
        <Grid container spacing={2}>
          {renderContent()}
        </Grid>
      </div>
    </ClickAwayListener>
  );
};

export default CheckBoxPicker;
