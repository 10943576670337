import React, { FC, Fragment } from 'react';
import { Checkbox, Fab, FormControlLabel, Grid, IconButton, makeStyles, TextField, Theme, Tooltip, Typography, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ucWords } from 'utils';

interface Props {
  skillsMaster: Select[];
  isNewSkill: boolean;
  setNewSkill: React.Dispatch<React.SetStateAction<boolean>>;
  customSkills: string[];
  setCustomSkills: React.Dispatch<React.SetStateAction<string[]>>;
  selectedSkillsMaster: string;
  setSelectedSkillsMaster: React.Dispatch<React.SetStateAction<string>>;
  setSkills: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  addNewCustomSkillButton: {
    marginTop: theme.spacing(2)
  },
  checkBoxIcon: {
    fontSize: '16px'
  },
  checkBox: {
    marginLeft: theme.spacing(-2),
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  autoCompleteResponsive: {
    width: '100%'
  }
}));

const AutocompleteCustom = withStyles({
  inputRoot: {
    flexWrap: 'wrap',
    '&[class*="MuiOutlinedInput-root"]': {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 0,
      paddingBottom: 0
    }
  },
  tag: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #0B3469',
    fontSize: '13px',
    color: '#0B3469',
    height: 25
  }
})(Autocomplete);

const ProfessionalSkill: FC<Props> = props => {
  const classes = useStyles(props);

  const icon = <CheckBoxOutlineBlankIcon className={classes.checkBoxIcon} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkBoxIcon} />;

  const { skillsMaster, customSkills, isNewSkill, setNewSkill, setCustomSkills, selectedSkillsMaster, setSelectedSkillsMaster, setSkills } = props;

  const handleSkillMasterChange = (skills: Select[]) => {
    let selectedSkill: string[] = [];
    for (const skill of skills) {
      selectedSkill.push(skill.name);
    }
    setSelectedSkillsMaster(selectedSkill.join(','));

    let fixedCustomSkill: string[] = [];
    customSkills.map(customSkill => {
      if (customSkill !== '') {
        fixedCustomSkill.push(customSkill);
      }
      return fixedCustomSkill;
    });

    const fixedSkills =
      fixedCustomSkill.length === 0 ? selectedSkill : selectedSkill.length === 0 ? fixedCustomSkill : [selectedSkill, fixedCustomSkill];

    setSkills(fixedSkills.join(','));
  };

  const activateCustomSkill = () => {
    setCustomSkills(['']);
    setNewSkill(!isNewSkill);
    setSkills(selectedSkillsMaster);
  };

  const handleCustomSkillChange = (skill: string, customSkillIndex: number) => {
    const currentCustomSkill = [...customSkills];
    currentCustomSkill[customSkillIndex] = skill;
    setCustomSkills(currentCustomSkill);

    let fixedCustomSkill: string[] = [];
    currentCustomSkill.map(customSkill => {
      if (customSkill !== '') {
        fixedCustomSkill.push(customSkill);
      }
      return fixedCustomSkill;
    });

    const fixedSkills =
      fixedCustomSkill.length === 0 ? selectedSkillsMaster : [selectedSkillsMaster, fixedCustomSkill].join(selectedSkillsMaster !== '' ? ',' : '');

    setSkills(fixedSkills);
  };

  const handleAddNewCustomSkill = () => {
    const currentCustomSkill = [...customSkills];
    currentCustomSkill.push('');
    setCustomSkills(currentCustomSkill);
  };

  const handleDeleteCustomSkill = (customSkillIndex: number) => {
    const currentCustomSkill = [...customSkills];
    currentCustomSkill.splice(customSkillIndex, 1);
    setCustomSkills(currentCustomSkill);

    let fixedCustomSkill: string[] = [];
    currentCustomSkill.map(customSkill => {
      if (customSkill !== '') {
        fixedCustomSkill.push(customSkill);
      }
      return fixedCustomSkill;
    });

    const fixedSkills =
      fixedCustomSkill.length === 0 ? selectedSkillsMaster : [selectedSkillsMaster, fixedCustomSkill].join(selectedSkillsMaster !== '' ? ',' : '');

    setSkills(fixedSkills);
  };

  const renderCustomSkillForm = () => {
    return customSkills.map((value, index) => {
      const renderDeleteButton = () => {
        if (index !== 0) {
          return (
            <Grid item xs={12} sm={12} container direction='row' justify='flex-end' alignItems='flex-end' alignContent='center'>
              <Tooltip title='Delete' placement='left' onClick={event => handleDeleteCustomSkill(index)}>
                <IconButton size='small'>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Grid>
          );
        }
      };
      return (
        <Fragment key={index}>
          {renderDeleteButton()}
          <TextField
            margin='dense'
            fullWidth
            id='customSkill'
            label='Enter Skill / 输入技能'
            value={value}
            onChange={event => handleCustomSkillChange(event.target.value, index)}
            variant='outlined'
            autoComplete='off'
            disabled={!isNewSkill}
            InputProps={{
              classes: {
                input: classes.textFieldFont
              }
            }}
            InputLabelProps={{
              className: classes.textFieldFont
            }}
          />
        </Fragment>
      );
    });
  };
  return (
    <Fragment>
      <Grid item xs={12} sm={12} container direction='row' justify='flex-start' alignItems='flex-start' alignContent='center'>
        <Typography variant='h5' color='primary'>
          2. Professional Skill(s) 专业技能
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='flex-start' alignItems='flex-start'>
        <div className={classes.autoCompleteResponsive}>
          <AutocompleteCustom
            multiple
            id='skills'
            options={skillsMaster}
            disableCloseOnSelect
            getOptionLabel={(option: Select) => ucWords(option.name)}
            onChange={(event, value) => handleSkillMasterChange(value)}
            renderOption={(option: Select, { selected }) => (
              <Fragment>
                <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} color='primary' disableRipple className={classes.checkBox} />
                {ucWords(option.name)}
              </Fragment>
            )}
            renderInput={params => (
              <TextField {...params} margin='dense' fullWidth id='skills' label='Search Skill / 搜索技能' variant='outlined' autoComplete='off' />
            )}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='flex-start' alignItems='center'>
        <FormControlLabel
          control={<Checkbox icon={icon} checkedIcon={checkedIcon} checked={isNewSkill} color='primary' onChange={activateCustomSkill} />}
          label='Other / 其他'
        />
        {renderCustomSkillForm()}
        <Tooltip title='Add / 加成员' placement='right'>
          <span>
            <Fab
              size='small'
              color='primary'
              aria-label='add'
              onClick={handleAddNewCustomSkill}
              disabled={!isNewSkill}
              className={classes.addNewCustomSkillButton}
            >
              <AddIcon />
            </Fab>
          </span>
        </Tooltip>
      </Grid>
    </Fragment>
  );
};

export default ProfessionalSkill;
