import React from 'react';
import { Button, makeStyles, TableRow, TextField, Theme } from '@material-ui/core';

import BodyCell from 'components/BodyCell';
import clsx from 'clsx';

interface Props {
  companyName: string;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  companyNameError: string;
  numberOfYears: number;
  setNumberOfYears: React.Dispatch<React.SetStateAction<number>>;
  numberOfYearsError: string;
  position: string;
  setPosition: React.Dispatch<React.SetStateAction<string>>;
  positionError: string;
  employmentRemark: string;
  setEmploymentRemark: React.Dispatch<React.SetStateAction<string>>;
  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
  primaryButtonLabel: string;
  customBackground?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  employmentHistoryForm: {
    height: 64
  },
  textFieldRoot: (props: Props) => ({
    backgroundColor: props.customBackground
  }),
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  buttonContainer: {
    display: 'flex'
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  }
}));

const CreateEditEmploymentHistoryForm: React.FC<Props> = props => {
  const classes = useStyles(props);

  const { companyName, setCompanyName, companyNameError } = props;
  const { numberOfYears, setNumberOfYears, numberOfYearsError } = props;
  const { position, setPosition, positionError } = props;
  const { employmentRemark, setEmploymentRemark } = props;

  const { isSubmitting, onSubmit, onCancel, primaryButtonLabel, customBackground } = props;

  return (
    <TableRow className={classes.employmentHistoryForm}>
      <BodyCell cellWidth='20%' isComponent={true}>
        <TextField
          margin='dense'
          required
          fullWidth
          className={clsx({ [classes.textFieldRoot]: customBackground })}
          id='companyName'
          placeholder='Enter Name'
          error={companyNameError !== ''}
          value={companyName}
          onChange={event => setCompanyName(event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
        />
      </BodyCell>
      <BodyCell cellWidth='15%' isComponent={true}>
        <TextField
          margin='dense'
          required
          fullWidth
          className={clsx({ [classes.textFieldRoot]: customBackground })}
          id='numberOfYears'
          label='Enter Numeric'
          error={numberOfYearsError !== ''}
          value={numberOfYears}
          onChange={event => setNumberOfYears(+event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            },
            inputProps: {
              min: 0
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
          type='number'
        />
      </BodyCell>
      <BodyCell cellWidth='15%' isComponent={true}>
        <TextField
          margin='dense'
          required
          fullWidth
          className={clsx({ [classes.textFieldRoot]: customBackground })}
          id='position'
          placeholder='Enter Position'
          error={positionError !== ''}
          value={position}
          onChange={event => setPosition(event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
        />
      </BodyCell>
      <BodyCell cellWidth='30%' isComponent={true}>
        <TextField
          margin='dense'
          required
          fullWidth
          className={clsx({ [classes.textFieldRoot]: customBackground })}
          id='employmentRemark'
          placeholder='Enter Remark'
          value={employmentRemark}
          onChange={event => setEmploymentRemark(event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont
          }}
          multiline
          rowsMax={4}
        />
      </BodyCell>
      <BodyCell cellWidth='20%' pL='60px' isComponent={true}>
        <div className={classes.buttonContainer}>
          <Button variant='contained' className={classes.cancelButton} onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button onClick={onSubmit} variant='contained' color='secondary' disabled={isSubmitting}>
            {primaryButtonLabel}
          </Button>
        </div>
      </BodyCell>
    </TableRow>
  );
};

export default CreateEditEmploymentHistoryForm;
