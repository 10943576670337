import React, { FC, useState } from 'react';
import { Button, Card, CardHeader, CardContent, CardActions, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import EditIcon from '@material-ui/icons/EditOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ActionSnackbar from 'components/ActionSnackbar';
import EditNotesModal from './components/EditNotesModal';

interface Props {
  candidates: CandidateModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: 20
  },
  cardHeader: {
    padding: theme.spacing(2)
  },
  CardContent: {
    paddingLeft: theme.spacing(1),
    paddingRight: 0,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  actionIcon: {
    fontSize: 20
  },
  contentValue: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5)
  }
}));

const CandidateNotes: FC<Props> = props => {
  const classes = useStyles();
  const { candidates } = props;

  const { id, candidateNotes } = candidates!;

  const [notes, setNotes] = useState<string>(candidateNotes);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openEditNotesModal, setOpenEditNotesModal] = useState<boolean>(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenEditNotes = () => {
    setOpenEditNotesModal(true);
  };
  const handleCancelEditNotes = () => {
    setOpenEditNotesModal(false);
  };

  return (
    <Grid item xs={8}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Typography color='primary' variant='h5'>
              Candidate Notes
            </Typography>
          }
        />
        <Divider />
        <CardContent className={classes.CardContent}>
          <Typography variant='h6' className={classes.contentValue}>
            {notes === null ? '-' : notes}
          </Typography>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color='primary' onClick={handleOpenEditNotes}>
            <EditIcon className={classes.actionIcon} />
            Edit
          </Button>
        </CardActions>
      </Card>
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? 'Update is successful' : 'Update failed'}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
      <EditNotesModal
        open={openEditNotesModal}
        candidateId={id}
        candidateNote={candidateNotes}
        setCandidateNote={setNotes}
        handleCancel={handleCancelEditNotes}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
      />
    </Grid>
  );
};

export default CandidateNotes;
