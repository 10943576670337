import React, { FC, Fragment } from 'react';
import NumberFormatCustom from 'components/NumberFormatCustom';
import { Fab, Grid, IconButton, makeStyles, TextField, Theme, Tooltip, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

interface Props {
  familyMember: FamilyMemberModel[];
  setFamilyMember: React.Dispatch<React.SetStateAction<FamilyMemberModel[]>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  textFieldNumber: {
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none'
    }
  },
  deleteButtonGrid: {
    marginBottom: theme.spacing(-3)
  }
}));

const FamilyMember: FC<Props> = props => {
  const classes = useStyles(props);

  const { familyMember, setFamilyMember } = props;

  const handleNameChange = (name: string, nameIndex: number) => {
    const currentFamilyMember = [...familyMember];
    currentFamilyMember[nameIndex].name = name;
    setFamilyMember(currentFamilyMember);
  };

  const handleContactNumberChange = (contactNumber: string, contactNumberIndex: number) => {
    const currentFamilyMember = [...familyMember];
    currentFamilyMember[contactNumberIndex].contactNumber = contactNumber;
    setFamilyMember(currentFamilyMember);
  };

  const handleAgeChange = (age: number, ageIndex: number) => {
    const currentFamilyMember = [...familyMember];
    currentFamilyMember[ageIndex].age = age;
    setFamilyMember(currentFamilyMember);
  };

  const handleRelationshipChange = (relationship: string, relationshipIndex: number) => {
    const currentFamilyMember = [...familyMember];
    currentFamilyMember[relationshipIndex].relationship = relationship;
    setFamilyMember(currentFamilyMember);
  };

  const handleOccupationChange = (occupation: string, occupationIndex: number) => {
    const currentFamilyMember = [...familyMember];
    currentFamilyMember[occupationIndex].occupation = occupation;
    setFamilyMember(currentFamilyMember);
  };

  const handleAddNewFamilyMember = () => {
    const currentFamilyMember = [...familyMember];
    currentFamilyMember.push({ name: '', contactNumber: '', age: 0, relationship: '', occupation: '' });
    setFamilyMember(currentFamilyMember);
  };

  const handleDeleteFamilyMember = (familyMemberIndex: number) => {
    const currentFamilyMember = [...familyMember];
    currentFamilyMember.splice(familyMemberIndex, 1);
    setFamilyMember(currentFamilyMember);
  };

  const renderForm = () => {
    return familyMember.map((value, index) => {
      const renderDeleteButton = () => {
        if (index !== 0) {
          return (
            <Grid
              item
              xs={12}
              sm={12}
              container
              direction='row'
              justify='flex-end'
              alignItems='flex-end'
              alignContent='center'
              className={classes.deleteButtonGrid}
            >
              <Tooltip title='Delete' placement='left' onClick={event => handleDeleteFamilyMember(index)}>
                <IconButton size='small'>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Grid>
          );
        }
      };
      return (
        <Fragment key={index}>
          {renderDeleteButton()}
          <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center' key={`familyMemberName${index}`}>
            <TextField
              margin='dense'
              fullWidth
              id='familyMemberName'
              label='Name / 姓名'
              value={value.name}
              onChange={event => handleNameChange(event.target.value, index)}
              variant='outlined'
              autoComplete='off'
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3} container direction='row' justify='center' alignItems='center' key={`familyMemberContactNumber${index}`}>
            <TextField
              margin='dense'
              fullWidth
              id='familyMemberContactNumber'
              label='Contact / 电话'
              value={value.contactNumber}
              onChange={event => handleContactNumberChange(event.target.value, index)}
              variant='outlined'
              autoComplete='off'
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                },
                inputProps: {
                  min: 0
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
              type='number'
              className={classes.textFieldNumber}
            />
          </Grid>
          <Grid item xs={6} sm={3} container direction='row' justify='center' alignItems='center' key={`familyMemberAge${index}`}>
            <TextField
              margin='dense'
              fullWidth
              id='familyMemberAge'
              label='Age / 年龄'
              value={value.age === 0 ? null : value.age}
              onChange={event => handleAgeChange(+event.target.value, index)}
              variant='outlined'
              autoComplete='off'
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                },
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  format: '##'
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center' key={`familyMemberRelationship${index}`}>
            <TextField
              margin='dense'
              fullWidth
              id='familyMemberRelationship'
              label='Relationship / 关系'
              value={value.relationship}
              onChange={event => handleRelationshipChange(event.target.value, index)}
              variant='outlined'
              autoComplete='off'
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center' key={`familyMemberOccupation${index}`}>
            <TextField
              margin='dense'
              fullWidth
              id='familyMemberOccupation'
              label='Occupation / 职业'
              value={value.occupation}
              onChange={event => handleOccupationChange(event.target.value, index)}
              variant='outlined'
              autoComplete='off'
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          </Grid>
        </Fragment>
      );
    });
  };
  return (
    <Fragment>
      <Grid item xs={12} sm={12} container direction='row' justify='flex-start' alignItems='flex-start' alignContent='center'>
        <Typography variant='h5' color='primary'>
          6. Family Member(s) 家庭成员
        </Typography>
      </Grid>
      {renderForm()}
      <Grid item xs={12} sm={12} container direction='row' justify='flex-start' alignItems='flex-start' alignContent='center'>
        <Tooltip title='Add / 加成员' placement='right'>
          <Fab size='small' color='primary' aria-label='add' onClick={handleAddNewFamilyMember}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Grid>
    </Fragment>
  );
};

export default FamilyMember;
