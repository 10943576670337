import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import CandidateForm from './components/CandidateForm';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_CANDIDATE_URL, RESUME_BASE_URL } from 'constants/url';

interface Props {
  open: boolean;
  candidateId: number;
  candidateName: string;
  setCandidateName: React.Dispatch<React.SetStateAction<string>>;
  candidateExpectedSalary: number;
  setCandidateExpectedSalary: React.Dispatch<React.SetStateAction<number>>;
  candidatePositionApplied: string;
  setCandidatePositionApplied: React.Dispatch<React.SetStateAction<string>>;
  candidateSkill: string;
  setCandidateSkill: React.Dispatch<React.SetStateAction<string>>;

  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: '#FFFFFF'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const EditCandidateModal: React.FC<Props> = props => {
  const classes = useStyles();

  let cancelTokenSource: CancelTokenSource;
  const {
    open,
    candidateId,
    candidateName,
    setCandidateName,
    candidateExpectedSalary,
    setCandidateExpectedSalary,
    candidatePositionApplied,
    setCandidatePositionApplied,
    candidateSkill,
    setCandidateSkill,
    handleCancel,
    setOpenSnackbar,
    setSnackbarVarient
  } = props;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [skillsMaster, setSkillsMaster] = useState<Select[]>([]);

  const [name, setName] = useState<string>('');
  const [expectedSalary, setExpectedSalary] = useState<number>(0);
  const [positionApplied, setPositionApplied] = useState<string>('');
  const [skills, setSkills] = useState<string>('');

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const resetFormValues = useCallback(() => {
    if (!candidateName) {
      return setName('');
    }
    setName(candidateName);
    setExpectedSalary(candidateExpectedSalary);
    setPositionApplied(candidatePositionApplied);
    setSkills(candidateSkill);
  }, [candidateName, candidateExpectedSalary, candidatePositionApplied, candidateSkill]);

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const loadProperties = async () => {
      setLoading(true);

      try {
        const url = `${RESUME_BASE_URL}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        //Set skill master
        setSkillsMaster(data.skills);
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    };

    loadProperties();

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  const handleOnClose = () => {
    resetFormValues();
    handleCancel();
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      cancelTokenSource = axios.CancelToken.source();
      await axios.put(
        `${GET_EDIT_CANDIDATE_URL(candidateId)}`,
        {
          name,
          expectedSalary,
          positionApplied,
          skills
        },
        { cancelToken: cancelTokenSource.token }
      );

      setCandidateName(name);
      setCandidateExpectedSalary(expectedSalary);
      setCandidatePositionApplied(positionApplied);
      setCandidateSkill(skills);
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleCancel();
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='sm'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CandidateForm
          skillsMaster={skillsMaster}
          candidateName={name}
          setCandidateName={setName}
          candidateExpectedSalary={expectedSalary}
          setCandidateExpectedSalary={setExpectedSalary}
          candidatePositionApplied={positionApplied}
          setCandidatePositionApplied={setPositionApplied}
          candidateSkill={skills}
          setCandidateSkill={setSkills}
          isSubmitting={isLoading}
          onSubmit={handleOnSubmit}
          onCancel={handleOnClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditCandidateModal;
