import React, { useState } from 'react';
import { Button, Divider, Grid, MenuItem, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';

interface Props {
  candidateEmployementStatus: string;
  setCandidateEmployementStatus: React.Dispatch<React.SetStateAction<string>>;
  candidateRating: string;
  setCandidateRating: React.Dispatch<React.SetStateAction<string>>;
  candidatePlacement: string;
  setCandidatePlacement: React.Dispatch<React.SetStateAction<string>>;
  candidatePosition: string;
  setCandidatePosition: React.Dispatch<React.SetStateAction<string>>;
  candidateHealth: boolean | undefined;
  setCandidateHealth: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  gridClass: {
    marginBottom: theme.spacing(2)
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  deleteButtonGrid: {
    marginBottom: theme.spacing(-2)
  }
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: '#EF965A',
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const StatusForm: React.FC<Props> = props => {
  const classes = useStyles();

  const {
    candidateEmployementStatus,
    setCandidateEmployementStatus,
    candidatePlacement,
    setCandidatePlacement,
    candidatePosition,
    setCandidatePosition,
    candidateRating,
    candidateHealth,
    setCandidateHealth,
    setCandidateRating,
    isSubmitting,
    onCancel,
    onSubmit
  } = props;

  const [openEmployementName, setOpenEmployementName] = useState<string>(candidateEmployementStatus);

  const handleChangeEmployementStatus = (event: any) => {
    if (event.target.value === 'ASSIGNED') {
      setOpenEmployementName(event.target.value);
      setCandidateEmployementStatus(event.target.value);
    } else {
      setOpenEmployementName(event.target.value);
      setCandidateEmployementStatus(event.target.value);
      setCandidatePlacement('');
      setCandidatePosition('');
    }
  };

  const handleInjuriesHealthIssueChange = (value: string) => {
    let injuriesHealthIssue: boolean | undefined;
    if (value === 'Yes') {
      injuriesHealthIssue = true;
    } else if (value === 'No') {
      injuriesHealthIssue = false;
    }
    setCandidateHealth(injuriesHealthIssue);
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid className={classes.contentGrid}>
        <Typography variant='h4' id='modal-title' color='primary'>
          Candidate Status
        </Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={1} className={classes.gridClass}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              variant='outlined'
              select
              margin='normal'
              required
              fullWidth
              id='employeestatus'
              label='Employement Status'
              value={candidateEmployementStatus}
              onChange={event => handleChangeEmployementStatus(event)}
              autoComplete='off'
            >
              <MenuItem key={0} value='INACTIVE'>
                Inactive
              </MenuItem>
              <MenuItem key={1} value='UNASSIGNED'>
                Unassigned
              </MenuItem>
              <MenuItem key={2} value='ASSIGNED'>
                Assigned
              </MenuItem>
            </TextField>
          </Grid>
          {openEmployementName === 'ASSIGNED' ? (
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='companyemployementname'
                label='Company Name'
                value={candidatePlacement}
                onChange={event => setCandidatePlacement(event.target.value)}
                autoComplete='off'
              />
            </Grid>
          ) : (
            ''
          )}
          {openEmployementName === 'ASSIGNED' ? (
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='position'
                label='Position'
                value={candidatePosition}
                onChange={event => setCandidatePosition(event.target.value)}
                autoComplete='off'
              />
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              variant='outlined'
              select
              margin='normal'
              required
              fullWidth
              id='rating'
              label='Rating'
              value={candidateRating}
              onChange={event => setCandidateRating(event.target.value)}
              autoComplete='off'
            >
              <MenuItem key={0} value='1'>
                Good
              </MenuItem>
              <MenuItem key={0} value='2'>
                None
              </MenuItem>
              <MenuItem key={1} value='3'>
                Bad
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              variant='outlined'
              select
              margin='normal'
              required
              fullWidth
              id='healthissues'
              label='Health Issues'
              value={candidateHealth ? 'Yes' : 'No'}
              onChange={event => handleInjuriesHealthIssueChange(event.target.value)}
              autoComplete='off'
            >
              <MenuItem key='Yes' value='Yes'>
                Yes
              </MenuItem>
              <MenuItem key='No' value='No'>
                No
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' className={classes.cancelButton} disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <SaveButton type='submit' variant='contained' disabled={isSubmitting}>
          Save
        </SaveButton>
      </Grid>
    </form>
  );
};

export default StatusForm;
