import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Theme, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { orange } from '@material-ui/core/colors';

import DocumentForm from './components/DocumentForm';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_CANDIDATE_DOCUMENT_URL } from 'constants/url';

interface Props {
  open: boolean;
  candidateId: number;
  candidatePhoto: string;
  setCandidatePhoto: React.Dispatch<React.SetStateAction<string>>;
  candidatePhoto2: string;
  setCandidatePhoto2: React.Dispatch<React.SetStateAction<string>>;
  candidatePhoto3: string;
  setCandidatePhoto3: React.Dispatch<React.SetStateAction<string>>;
  candidatePhoto4: string;
  setCandidatePhoto4: React.Dispatch<React.SetStateAction<string>>;
  candidateResume: string;
  setCandidateResume: React.Dispatch<React.SetStateAction<string>>;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginBottom: theme.spacing(3)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: '#FFFFFF'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: '#EF965A',
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const EditNotesModal: React.FC<Props> = props => {
  const classes = useStyles();

  let cancelTokenSource: CancelTokenSource;
  const {
    open,
    candidateId,
    candidatePhoto,
    setCandidatePhoto,
    candidatePhoto2,
    setCandidatePhoto2,
    candidatePhoto3,
    setCandidatePhoto3,
    candidatePhoto4,
    setCandidatePhoto4,
    candidateResume,
    setCandidateResume,
    handleCancel,
    setOpenSnackbar,
    setSnackbarVarient
  } = props;
  const [isLoading, setLoading] = useState<boolean>(false);

  const [photos, setPhotos] = useState<string>('');
  const [photos2, setPhotos2] = useState<string>('');
  const [photos3, setPhotos3] = useState<string>('');
  const [photos4, setPhotos4] = useState<string>('');
  const [resume, setResume] = useState<string>('');

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const resetFormValues = useCallback(() => {
    if (!candidateId) {
      return setPhotos('');
    }
    setPhotos(candidatePhoto);
    setPhotos2(candidatePhoto2);
    setPhotos3(candidatePhoto3);
    setPhotos4(candidatePhoto4);
    setResume(candidateResume);
  }, [candidateId, candidatePhoto, candidatePhoto2, candidatePhoto3, candidatePhoto4, candidateResume]);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  const handleOnClose = () => {
    resetFormValues();
    handleCancel();
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      cancelTokenSource = axios.CancelToken.source();
      const formData = new FormData();
      if (photos !== '' || photos2 !== '' || photos3 !== '' || photos4 !== '' || resume !== '') {
        formData.append('candidateId', `${candidateId}`);
        formData.append('originalImage', candidatePhoto);
        formData.append('newImage', photos);
        formData.append('originalImage2', candidatePhoto2);
        formData.append('newImage2', photos2);
        formData.append('originalImage3', candidatePhoto3);
        formData.append('newImage3', photos3);
        formData.append('originalImage4', candidatePhoto4);
        formData.append('newImage4', photos4);
        formData.append('originalResume', candidateResume);
        formData.append('newResume', resume);

        const config = {
          headers: {
            'content-type': 'multipart/form-data'
          },
          cancelToken: cancelTokenSource.token
        };
        await axios.post(`${GET_EDIT_CANDIDATE_DOCUMENT_URL}`, formData, config);

        setCandidatePhoto(photos);
        setCandidatePhoto2(photos2);
        setCandidatePhoto3(photos3);
        setCandidatePhoto4(photos4);
        setCandidateResume(resume);
      }

      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleCancel();
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='sm'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.contentGrid}>
          <Typography variant='h4' id='modal-title' color='primary'>
            Candidate Documents
          </Typography>
          <Divider className={classes.divider} />
          <DocumentForm label='Photo' fileType='image' setPhoto={setPhotos} onSubmit={handleOnSubmit} />
          <DocumentForm label='Photo2' fileType='image' setPhoto={setPhotos2} onSubmit={handleOnSubmit} />
          <DocumentForm label='Photo3' fileType='image' setPhoto={setPhotos3} onSubmit={handleOnSubmit} />
          <DocumentForm label='Photo4' fileType='image' setPhoto={setPhotos4} onSubmit={handleOnSubmit} />
          <DocumentForm label='Resume' fileType='file' setResume={setResume} onSubmit={handleOnSubmit} />
        </Grid>
        <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          <Button variant='contained' className={classes.cancelButton} disabled={isLoading} onClick={handleOnClose}>
            Cancel
          </Button>
          <SaveButton type='submit' variant='contained' disabled={isLoading} onClick={handleOnSubmit}>
            Save
          </SaveButton>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditNotesModal;
