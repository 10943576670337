export enum Religion {
  ISLAM = 'ISLAM',
  CHRISTIAN = 'CHRISTIAN',
  PROTESTANT = 'PROTESTANT',
  CATHOLIC = 'CATHOLIC',
  CONFUCIUS = 'CONFUCIUS',
  BUDDHA = 'BUDDHA',
  HINDU = 'HINDU',
  JEWISH = 'JEWISH'
}
