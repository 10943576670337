import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import DetailForm from './components/DetailForm';
import axios, { CancelTokenSource } from 'axios';
import {
  GET_EDIT_CANDIDATE_URL,
  GET_FAMILY_MEMBER_BY_ID_URL,
  GET_EDIT_FAMILY_MEMBER_URL,
  GET_DELETE_FAMILY_MEMBER,
  FAMILY_MEMBER_BASE_URL,
  RESUME_BASE_URL
} from 'constants/url';

interface Props {
  open: boolean;
  candidateId: number;
  candidateAddress: string;
  setCandidateAddress: React.Dispatch<React.SetStateAction<string>>;
  candidateFamilyMembers: FamilyMemberModel[];
  setCandidateFamilyMembers: React.Dispatch<React.SetStateAction<FamilyMemberModel[]>>;
  candidateNameOfSchool: string;
  setCandidateNameOfSchool: React.Dispatch<React.SetStateAction<string>>;
  candidateHighestQualification: string;
  setCandidateHighestQualification: React.Dispatch<React.SetStateAction<string>>;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: '#FFFFFF'
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const EditDetailModal: React.FC<Props> = props => {
  const classes = useStyles();

  let cancelTokenSource: CancelTokenSource;
  const {
    open,
    candidateId,
    candidateAddress,
    setCandidateAddress,
    candidateFamilyMembers,
    setCandidateFamilyMembers,
    candidateNameOfSchool,
    setCandidateNameOfSchool,
    candidateHighestQualification,
    setCandidateHighestQualification,
    handleCancel,
    setOpenSnackbar,
    setSnackbarVarient
  } = props;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [highestQualificationMaster, setHighestQualificationMaster] = useState<Select[]>([]);

  const addresses = candidateAddress !== null ? candidateAddress.split(', ') : '-';
  const [address, setAddress] = useState<string>(addresses[0]);
  const [unit, setUnit] = useState<string>(addresses[1]);
  const [postal, setPostal] = useState<string>(addresses[2]);
  const [familyMembers, setFamilyMembers] = useState<FamilyMemberModel[]>([]);
  const [familyMembersDeleted, setFamilyMembersDeleted] = useState<FamilyMemberModel[]>([]);
  const [nameOfSchool, setNameOfSchool] = useState<string>('');
  const [highestQualification, setHighestQualification] = useState<string>('');

  // This is to ensure that the form vale and erors are reset/cleared when user canceled the editing
  const resetFormValues = useCallback(() => {
    if (!candidateId) {
      return setAddress('');
    }
    setFamilyMembers(candidateFamilyMembers);
    setNameOfSchool(candidateNameOfSchool);
    setHighestQualification(candidateHighestQualification);
  }, [candidateId, candidateFamilyMembers, candidateNameOfSchool, candidateHighestQualification]);

  useEffect(() => {
    resetFormValues();
  }, [resetFormValues]);

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const loadProperties = async () => {
      setLoading(true);

      try {
        const url = `${RESUME_BASE_URL}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });

        //Set highest qualification master
        let highestQualificationData: Select[] = [];
        data.highestQualification.map((value: string, index: number) => {
          return highestQualificationData.push({ name: value, id: index });
        });
        setHighestQualificationMaster(highestQualificationData);
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    };

    loadProperties();

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  const handleOnClose = () => {
    resetFormValues();
    handleCancel();
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      cancelTokenSource = axios.CancelToken.source();
      const homeAddress = address + ', ' + unit + ', ' + postal;
      await axios.put(
        `${GET_EDIT_CANDIDATE_URL(candidateId)}`,
        { homeAddress, nameOfSchool, highestQualification },
        { cancelToken: cancelTokenSource.token }
      );

      if (familyMembers.length !== 0) {
        familyMembers.map(async value => {
          if (value.id) {
            const { data } = await axios.post(`${GET_FAMILY_MEMBER_BY_ID_URL}`, { id: value.id }, { cancelToken: cancelTokenSource.token });
            const { id } = data.family;
            await axios.put(
              `${GET_EDIT_FAMILY_MEMBER_URL(id)}`,
              {
                name: value.name,
                relationship: value.relationship,
                age: value.age,
                occupation: value.occupation,
                contactNumber: value.contactNumber
              },
              {
                cancelToken: cancelTokenSource.token
              }
            );
          } else {
            await axios.post(
              `${FAMILY_MEMBER_BASE_URL}`,
              {
                candidateId,
                name: value.name,
                relationship: value.relationship,
                age: value.age,
                occupation: value.occupation,
                contactNumber: value.contactNumber
              },
              {
                cancelToken: cancelTokenSource.token
              }
            );
          }
        });
      }

      if (familyMembersDeleted.length !== 0) {
        familyMembersDeleted.map(async value => {
          await axios.delete(GET_DELETE_FAMILY_MEMBER(value.id));
        });
      }

      setCandidateAddress(homeAddress);
      setCandidateNameOfSchool(nameOfSchool);
      setCandidateHighestQualification(highestQualification);
      setCandidateFamilyMembers(familyMembers);
      setOpenSnackbar(true);
      setSnackbarVarient('success');
      handleCancel();
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
      setSnackbarVarient('error');
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} scroll='body' fullWidth={true} maxWidth='sm'>
      <DialogTitle>
        <IconButton size='small' className={classes.closeButton} onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DetailForm
          highestQualificationMaster={highestQualificationMaster}
          address={address}
          setAddress={setAddress}
          unit={unit}
          setUnit={setUnit}
          postal={postal}
          setPostal={setPostal}
          candidateFamilyMembers={familyMembers}
          setCandidateFamilyMembers={setFamilyMembers}
          candidateFamilyMembersDeleted={familyMembersDeleted}
          setCandidateFamilyMembersDeleted={setFamilyMembersDeleted}
          candidateNameOfSchool={nameOfSchool}
          setCandidateNameOfSchool={setNameOfSchool}
          candidateHighestQualification={highestQualification}
          setCandidateHighestQualification={setHighestQualification}
          isSubmitting={isLoading}
          onSubmit={handleOnSubmit}
          onCancel={handleOnClose}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditDetailModal;
