import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import { Button, Grid, makeStyles, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import axios, { CancelTokenSource } from 'axios';

import SearchInput from 'components/SearchInput';
import useDebounce from 'hooks/useDebounce';
import SkillTable from './components/SkillTable';
import ActionSnackbar from 'components/ActionSnackbar';

import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import { SKILL_BASE_URL } from 'constants/url';

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  extendedIcon: {
    paddingRight: theme.spacing(1)
  }
}));

const SkillsPage: FC = () => {
  useCurrentPageTitleUpdater('Skill');

  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');

  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [isSearchingSkill, setSearchingSkill] = useState<boolean>(false);
  const [isSearchSkillError, setSearchSkillError] = useState<boolean>(false);
  const [skills, setSkills] = useState<SkillsModel[]>([]);
  const [count, setCount] = useState<number>(0);

  const [openCreateSkill, setOpenCreateSkill] = useState<boolean>(false);
  const [openEditSkill, setOpenEditSkill] = useState<boolean>(false);
  const [currentEditingSkillIndex, setCurrentEditingSkillIndex] = useState<number>(0);

  const [isDelete, setDelete] = useState<boolean>(false);

  // Search Skill whenever rowsPerPage, currentPage, queryString changes
  const fetchData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('q', queryString);
      }

      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    const searchSkill = async () => {
      setSearchingSkill(true);
      setSearchSkillError(false);

      try {
        const url = `${SKILL_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
        setCount(data.count);
        setSkills(data.skills);
      } catch (err) {
        setSearchSkillError(true);
      }

      setSearchingSkill(false);
      setDelete(false);
    };

    searchSkill();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [rowsPerPage, currentPage, queryString]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);
  // Load client data to populate on search list
  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  const handleOpenCreateSkill = () => {
    setOpenEditSkill(false);
    setOpenCreateSkill(true);
  };

  const handleCancelCreateSkill = () => {
    setOpenCreateSkill(false);
  };

  const handleOpenEditSkill = (skillIndex: number): React.MouseEventHandler => () => {
    setCurrentEditingSkillIndex(skillIndex);
    setOpenCreateSkill(false);
    setOpenEditSkill(true);
  };

  const handleCancelEditSkill = () => {
    setOpenEditSkill(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const addNewSkill = (skill: SkillsModel) => {
    skill.new = true;
    skills.unshift(skill);
    setSkills([...skills].slice(0, rowsPerPage));
    setCount(c => c + 1);
  };

  const updateIndividualSkill = (updatedSkillProperties: Partial<SkillsModel>) => {
    setSkills(
      skills!.map((skill, index) => {
        if (index !== currentEditingSkillIndex) {
          return skill;
        }

        return Object.assign({}, skill, updatedSkillProperties);
      })
    );
  };

  // Load skill data if skill data has been deleted
  useEffect(() => {
    if (isDelete) {
      fetchData();
    }
  }, [isDelete, fetchData]);

  return (
    <Fragment>
      <Grid container justify='space-between'>
        <SearchInput
          withBorder
          withTransition={false}
          width={150}
          placeHolder='Search Skill...'
          iconColor='#989898'
          tableSearchValue={query}
          setTableSearchValue={setQuery}
        />
        <Button
          color='primary'
          size='medium'
          variant='outlined'
          className={classes.addButton}
          onClick={() => {
            handleOpenCreateSkill();
          }}
        >
          <AddIcon className={classes.extendedIcon} />
          New Skill
        </Button>
      </Grid>
      <SkillTable
        isLoadingData={isSearchingSkill}
        skills={skills}
        count={count}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
        handleSetMessageSuccess={handleSetMessageSuccess}
        handleSetMessageError={handleSetMessageError}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, page) => setCurrentPage(page)}
        handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
        openCreateSkill={openCreateSkill}
        handleCancelCreateSkill={handleCancelCreateSkill}
        addNewSkill={addNewSkill}
        setDelete={setDelete}
        openEditSkill={openEditSkill}
        skill={skills[currentEditingSkillIndex]}
        currentEditingSkillIndex={currentEditingSkillIndex}
        handleOpenEditSkill={handleOpenEditSkill}
        handleCancelEditSkill={handleCancelEditSkill}
        updateIndividualSkill={updateIndividualSkill}
      />
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? messageSuccess : messageError}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
    </Fragment>
  );
};

export default SkillsPage;
