import React, { FC } from 'react';
import { Button, Grid, makeStyles, Theme } from '@material-ui/core';
import axios from 'axios';
import DownloadIcon from '@material-ui/icons/GetApp';

import CandidateDetail from './components/CandidateDetail';
import CandidateProfile from './components/CandidateProfile';
import CandidateStatus from './components/CandidateStatus';
import CandidateDocument from './components/CandidateDocument';
import CandidateNotes from './components/CandidateNotes';
import { GET_EXPORT_PDF_URL } from 'constants/url';

interface Props {
  candidates: CandidateModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  gridBottom: {
    marginTop: 80
  },
  gridButton: {
    marginBottom: theme.spacing(4)
  }
}));
const OverviewContent: FC<Props> = props => {
  const classes = useStyles();
  const { candidates } = props;

  const { id, name } = candidates;
  const handleExportPDF = async () => {
    try {
      await axios
        .get(GET_EXPORT_PDF_URL(id), {
          headers: {
            t: localStorage.getItem('token')
          },
          responseType: 'blob'
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data && response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${name}.pdf`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid>
      <Grid item xs={12} container className={classes.gridButton} direction='row' justify='flex-end' alignItems='center'>
        <Button variant='outlined' color='primary' onClick={handleExportPDF}>
          <DownloadIcon /> EXPORT PDF
        </Button>
      </Grid>
      <Grid container>
        <CandidateDetail candidates={candidates} />
        <CandidateProfile candidates={candidates} />
        <CandidateStatus candidates={candidates} />
      </Grid>
      <Grid container className={classes.gridBottom}>
        <CandidateDocument candidates={candidates} />
        <CandidateNotes candidates={candidates} />
      </Grid>
    </Grid>
  );
};

export default OverviewContent;
