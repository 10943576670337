import React from 'react';
import { Button, Divider, Grid, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';

interface Props {
  candidateNotes: string;
  setCandidateNotes: React.Dispatch<React.SetStateAction<string>>;

  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  gridClass: {
    marginBottom: theme.spacing(2)
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  }
}));

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: '#EF965A',
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const NotesForm: React.FC<Props> = props => {
  const classes = useStyles();

  const { candidateNotes, setCandidateNotes, isSubmitting, onCancel, onSubmit } = props;

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid className={classes.contentGrid}>
        <Typography variant='h4' id='modal-title' color='primary'>
          Candidate Notes
        </Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={1} className={classes.gridClass}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='notes'
            label='Notes'
            value={candidateNotes}
            onChange={event => setCandidateNotes(event.target.value)}
            autoComplete='off'
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
      <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' className={classes.cancelButton} disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <SaveButton type='submit' variant='contained' disabled={isSubmitting}>
          Save
        </SaveButton>
      </Grid>
    </form>
  );
};

export default NotesForm;
