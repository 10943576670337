import React, { FC, Fragment } from 'react';
import { Fab, Grid, IconButton, makeStyles, TextField, Theme, Tooltip, Typography } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

interface Props {
  jobExperience: EmploymentHistoriesModel[];
  setJobExperience: React.Dispatch<React.SetStateAction<EmploymentHistoriesModel[]>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  deleteButtonGrid: {
    marginBottom: theme.spacing(-3)
  }
}));

const JobExperience: FC<Props> = props => {
  const classes = useStyles(props);

  const { jobExperience, setJobExperience } = props;

  const handleCompanyNameChange = (companyName: string, companyNameIndex: number) => {
    const currentJobExperience = [...jobExperience];
    currentJobExperience[companyNameIndex].companyName = companyName;
    setJobExperience(currentJobExperience);
  };

  const handleNumberOfYearChange = (numberOfYear: number, numberOfYearIndex: number) => {
    const currentJobExperience = [...jobExperience];
    currentJobExperience[numberOfYearIndex].numberOfYears = numberOfYear;
    setJobExperience(currentJobExperience);
  };

  const handlePositionChange = (position: string, positionIndex: number) => {
    const currentJobExperience = [...jobExperience];
    currentJobExperience[positionIndex].position = position;
    setJobExperience(currentJobExperience);
  };

  const handleAddNewJobExperience = () => {
    const currentJobExperience = [...jobExperience];
    currentJobExperience.push({ companyName: '', numberOfYears: 0, position: '', companyNameError: '', numberOfYearsError: '', positionError: '' });
    setJobExperience(currentJobExperience);
  };

  const handleDeleteNewJobExperience = (jobExperienceIndex: number) => {
    const currentJobExperience = [...jobExperience];
    currentJobExperience.splice(jobExperienceIndex, 1);
    setJobExperience(currentJobExperience);
  };

  const renderForm = () => {
    return jobExperience.map((value, index) => {
      const renderDeleteButton = () => {
        if (index !== 0) {
          return (
            <Grid
              item
              xs={12}
              sm={12}
              container
              direction='row'
              justify='flex-end'
              alignItems='flex-end'
              alignContent='center'
              className={classes.deleteButtonGrid}
            >
              <Tooltip title='Delete' placement='left' onClick={event => handleDeleteNewJobExperience(index)}>
                <IconButton size='small'>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Grid>
          );
        }
      };
      return (
        <Fragment key={index}>
          {renderDeleteButton()}
          <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center' key={`companyName${index}`}>
            <TextField
              margin='dense'
              required
              fullWidth
              id='companyName'
              label='Name of Company / 公司名字'
              error={value.companyNameError !== ''}
              placeholder={value.companyNameError}
              value={value.companyName}
              onChange={event => handleCompanyNameChange(event.target.value, index)}
              variant='outlined'
              autoComplete='off'
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3} container direction='row' justify='center' alignItems='center' key={`numberOfYears${index}`}>
            <TextField
              margin='dense'
              required
              fullWidth
              id='numberOfYears'
              label='Years of Experience / 多年经验'
              error={value.numberOfYearsError !== ''}
              placeholder={value.numberOfYearsError}
              value={value.numberOfYears}
              onChange={event => handleNumberOfYearChange(+event.target.value, index)}
              variant='outlined'
              autoComplete='off'
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                },
                inputProps: {
                  min: 0
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
              type='number'
            />
          </Grid>
          <Grid item xs={6} sm={3} container direction='row' justify='center' alignItems='center' key={`position${index}`}>
            <TextField
              margin='dense'
              required
              fullWidth
              id='position'
              label='Position / 位置'
              error={value.positionError !== ''}
              placeholder={value.positionError}
              value={value.position}
              onChange={event => handlePositionChange(event.target.value, index)}
              variant='outlined'
              autoComplete='off'
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          </Grid>
        </Fragment>
      );
    });
  };
  return (
    <Fragment>
      <Grid item xs={12} sm={12} container direction='row' justify='flex-start' alignItems='flex-start' alignContent='center'>
        <Typography variant='h5' color='primary'>
          3. Working Experience In Singapore 在新加坡工作的经验
        </Typography>
      </Grid>
      {renderForm()}
      <Grid item xs={12} sm={12} container direction='row' justify='flex-start' alignItems='flex-start' alignContent='center'>
        <Tooltip title='Add / 加经验' placement='right'>
          <Fab size='small' color='primary' aria-label='add' onClick={handleAddNewJobExperience}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Grid>
    </Fragment>
  );
};

export default JobExperience;
