import React, { FC, useState } from 'react';
import { Button, Card, CardHeader, CardContent, CardActions, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import { format } from 'date-fns';
import EditIcon from '@material-ui/icons/EditOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ActionSnackbar from 'components/ActionSnackbar';
import EditProfileModal from './components/EditProfileModal';

interface Props {
  candidates: CandidateModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: 20,
    marginRight: 40
  },
  cardHeader: {
    padding: theme.spacing(2)
  },
  CardContent: { padding: 0 },
  actionIcon: {
    fontSize: 20
  },
  gridOdd: {
    backgroundColor: '#F2F4F7',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  gridEven: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  contentLabel: {
    fontWeight: 'normal'
  },
  contentValue: {
    paddingLeft: theme.spacing(4)
  },
  healthIssueGrid: {
    paddingBottom: theme.spacing(2)
  }
}));

const CandidateProfile: FC<Props> = props => {
  const classes = useStyles();
  const { candidates } = props;

  const {
    id,
    emailAddress,
    contactNumber,
    dateOfBirth,
    passportNumber,
    passportExpiryDate,
    height,
    weight,
    maritalStatus,
    religion,
    languageSpoken
  } = candidates!;

  const [candidateEmail, setCandidateEmail] = useState<string>(emailAddress);
  const [candidateContactNumber, setCandidateContactNumber] = useState<string>(contactNumber);
  const [candidateDateofBirth, setCandidateofBirth] = useState<Date | null>(dateOfBirth);
  const [candidatePassportNumber, setCandidatePassportNumber] = useState<string>(passportNumber);
  const [candidatePassportExp, setCandidatePassportExp] = useState<Date | null>(passportExpiryDate);
  const [candidateHeight, setCandidateHeight] = useState<number>(height);
  const [candidateWeight, setCandidateWeight] = useState<number>(weight);
  const [candidateMarital, setCandidateMarital] = useState<string>(maritalStatus);
  const [candidateLanguage, setCandidateLanguage] = useState<string>(languageSpoken);
  const [candidateReligion, setCandidateReligion] = useState<string>(religion);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openEditProfileModal, setOpenEditProfileModal] = useState<boolean>(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenEditProfile = () => {
    setOpenEditProfileModal(true);
  };
  const handleCancelEditProfile = () => {
    setOpenEditProfileModal(false);
  };

  return (
    <Grid item xs={4}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Typography color='primary' variant='h5'>
              Candidate Profile Detail
            </Typography>
          }
        />
        <Divider />
        <CardContent className={classes.CardContent}>
          <Grid container className={classes.gridEven}>
            <Grid item xs={3}>
              <Typography variant='h6' color='primary' className={classes.contentLabel}>
                Email
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              <Typography variant='h6'>{candidateEmail === null ? '-' : candidateEmail}</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.gridOdd}>
            <Grid item xs={3}>
              <Typography variant='h6' color='primary' className={classes.contentLabel}>
                Phone
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              <Typography variant='h6'>{candidateContactNumber}</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.gridEven}>
            <Grid item xs={3}>
              <Typography variant='h6' color='primary' className={classes.contentLabel}>
                Date of Birth
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              <Typography variant='h6'>{format(candidateDateofBirth != null ? new Date(candidateDateofBirth) : new Date(), 'dd/MM/yyyy')}</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.gridOdd}>
            <Grid item xs={3}>
              <Typography variant='h6' color='primary' className={classes.contentLabel}>
                Passport No.
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              <Typography variant='h6'>{candidatePassportNumber}</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.gridEven}>
            <Grid item xs={3}>
              <Typography variant='h6' color='primary' className={classes.contentLabel}>
                Passport Expiry
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              <Typography variant='h6'>{candidatePassportExp !== null ? format(new Date(candidatePassportExp), 'dd/MM/yyyy') : '-'}</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.gridOdd}>
            <Grid item xs={3}>
              <Typography variant='h6' color='primary' className={classes.contentLabel}>
                Height (cm)
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              <Typography variant='h6'>{candidateHeight === null ? '-' : candidateHeight}</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.gridEven}>
            <Grid item xs={3}>
              <Typography variant='h6' color='primary' className={classes.contentLabel}>
                Weight (kg)
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              <Typography variant='h6'>{candidateWeight === null ? '-' : candidateWeight}</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.gridOdd}>
            <Grid item xs={3}>
              <Typography variant='h6' color='primary' className={classes.contentLabel}>
                Marital Status
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              <Typography variant='h6'>{candidateMarital === null ? '-' : candidateMarital}</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.gridEven}>
            <Grid item xs={3}>
              <Typography variant='h6' color='primary' className={classes.contentLabel}>
                Religion
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              <Typography variant='h6'>{candidateReligion === null ? '-' : candidateReligion}</Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.gridOdd}>
            <Grid item xs={3}>
              <Typography variant='h6' color='primary' className={classes.contentLabel}>
                Spoken Language
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              <Typography variant='h6'>{candidateLanguage === null ? '-' : candidateLanguage}</Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color='primary' onClick={handleOpenEditProfile}>
            <EditIcon className={classes.actionIcon} />
            Edit
          </Button>
        </CardActions>
      </Card>
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? 'Update is successful' : 'Update failed'}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
      <EditProfileModal
        open={openEditProfileModal}
        candidateId={id}
        candidateEmail={emailAddress}
        setCandidateEmail={setCandidateEmail}
        candidatePhone={contactNumber}
        setCandidatePhone={setCandidateContactNumber}
        candidateDateofBirth={dateOfBirth}
        setCandidateDateofBirth={setCandidateofBirth}
        candidatePassport={passportNumber}
        setCandidatePassport={setCandidatePassportNumber}
        candidatePassportExp={passportExpiryDate}
        setCandidatePassportExp={setCandidatePassportExp}
        candidateHeight={height}
        setCandidateHeight={setCandidateHeight}
        candidateWeight={weight}
        setCandidateWeight={setCandidateWeight}
        candidateMarital={maritalStatus}
        setCandidateMarital={setCandidateMarital}
        candidateLanguage={languageSpoken}
        setCandidateLanguage={setCandidateLanguage}
        candidateReligion={religion}
        setCandidateReligion={setCandidateReligion}
        handleCancel={handleCancelEditProfile}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
      />
    </Grid>
  );
};

export default CandidateProfile;
