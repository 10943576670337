import React, { FC, Fragment } from 'react';
import { Grid, MenuItem, makeStyles, TextField, Theme, Typography } from '@material-ui/core';

interface Props {
  injuriesHealthIssue: boolean | undefined;
  setInjuriesHealthIssue: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  injuriesHealthIssueError: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  deleteButtonGrid: {
    marginBottom: theme.spacing(-3)
  }
}));

const InjuriesHealthIssue: FC<Props> = props => {
  const classes = useStyles(props);

  const { injuriesHealthIssue, setInjuriesHealthIssue, injuriesHealthIssueError } = props;

  const handleInjuriesHealthIssueChange = (value: string) => {
    let injuriesHealthIssue: boolean | undefined;
    if (value === 'Yes') {
      injuriesHealthIssue = true;
    } else if (value === 'No') {
      injuriesHealthIssue = false;
    } else {
      injuriesHealthIssue = undefined;
    }
    setInjuriesHealthIssue(injuriesHealthIssue);
  };

  return (
    <Fragment>
      <Grid item xs={12} sm={12} container direction='row' justify='flex-start' alignItems='flex-start' alignContent='center'>
        <Typography variant='h5' color='primary'>
          4. Injuries Or Health Issue(s) 受伤或健康问题
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='center'>
        <TextField
          select
          margin='dense'
          required
          fullWidth
          id='injuriesHealthIssue'
          label='Health Issues / 健康问题'
          error={injuriesHealthIssueError !== ''}
          value={injuriesHealthIssue === undefined ? '' : injuriesHealthIssue ? 'Yes' : 'No'}
          onChange={event => handleInjuriesHealthIssueChange(event.target.value)}
          variant='outlined'
          autoComplete='off'
          InputProps={{
            classes: {
              input: classes.textFieldFont
            }
          }}
          InputLabelProps={{
            className: classes.textFieldFont,
            shrink: injuriesHealthIssue === undefined ? false : true
          }}
        >
          <MenuItem key={0} value=''>
            <em>None</em>
          </MenuItem>
          <MenuItem key='Yes' value='Yes'>
            Yes
          </MenuItem>
          <MenuItem key='No' value='No'>
            No
          </MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='flex-start' alignItems='flex-start' alignContent='center'></Grid>
    </Fragment>
  );
};

export default InjuriesHealthIssue;
