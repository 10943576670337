import React, { Fragment, useEffect, useState } from 'react';
import { Checkbox, Button, Divider, Grid, MenuItem, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { ucWords } from 'utils';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormatCustom from 'components/NumberFormatCustom';
import clsx from 'clsx';
import { Religion } from 'typings/enum/UploadResumeProperties';

interface Props {
  maritalStatusMaster: Select[];
  languageSpokenMaster: Select[];
  candidateEmail: string;
  setCandidateEmail: React.Dispatch<React.SetStateAction<string>>;
  candidatePhone: string;
  setCandidatePhone: React.Dispatch<React.SetStateAction<string>>;
  candidateDateofBirth: Date | null;
  setCandidateDateofBirth: React.Dispatch<React.SetStateAction<Date | null>>;
  candidatePassport: string;
  setCandidatePassport: React.Dispatch<React.SetStateAction<string>>;
  candidatePassportExp: Date | null;
  setCandidatePassportExp: React.Dispatch<React.SetStateAction<Date | null>>;
  candidateHeight: number;
  setCandidateHeight: React.Dispatch<React.SetStateAction<number>>;
  candidateWeight: number;
  setCandidateWeight: React.Dispatch<React.SetStateAction<number>>;
  candidateMarital: string;
  setCandidateMarital: React.Dispatch<React.SetStateAction<string>>;
  candidateLanguage: string;
  setCandidateLanguage: React.Dispatch<React.SetStateAction<string>>;
  candidateReligion: string;
  setCandidateReligion: React.Dispatch<React.SetStateAction<string>>;

  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  gridClass: {
    marginBottom: theme.spacing(2)
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  textFieldRoot: (props: Props) => ({
    backgroundColor: '#FFFFFF',
    marginTop: theme.spacing(2)
  }),
  textFieldFont: {
    fontSize: '15px',
    height: 18,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2.5)
  },
  autoCompleteCheckbox: {
    padding: theme.spacing(0)
  },
  checkBoxIcon: {
    fontSize: '16px'
  },
  checkBox: {
    marginLeft: theme.spacing(-2),
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveScale: {
    width: '100%'
  }
}));

const AutocompleteCustom = withStyles({
  inputRoot: {
    flexWrap: 'wrap',
    '&[class*="MuiOutlinedInput-root"]': {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      paddingBottom: 8
    }
  },
  tag: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #0B3469',
    fontSize: '13px',
    color: '#0B3469',
    height: 25
  }
})(Autocomplete);

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: '#EF965A',
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const ProfileForm: React.FC<Props> = props => {
  const classes = useStyles(props);

  const [religions, setReligions] = useState<Select[]>([]);
  const icon = <CheckBoxOutlineBlankIcon className={classes.checkBoxIcon} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkBoxIcon} />;

  const {
    candidateEmail,
    setCandidateEmail,
    candidatePhone,
    setCandidatePhone,
    candidateDateofBirth,
    setCandidateDateofBirth,
    candidatePassport,
    setCandidatePassport,
    candidatePassportExp,
    setCandidatePassportExp,
    candidateHeight,
    setCandidateHeight,
    candidateWeight,
    setCandidateWeight,
    candidateMarital,
    setCandidateMarital,
    candidateLanguage,
    setCandidateLanguage,
    candidateReligion,
    setCandidateReligion,
    isSubmitting,
    onCancel,
    onSubmit
  } = props;
  const { maritalStatusMaster, languageSpokenMaster } = props;

  const language = candidateLanguage !== null ? candidateLanguage.split(',') : [];
  const arrayLanguage: any = [];

  useEffect(() => {
    const religionObject = Object.values(Religion);

    //Set religion
    let religionData: Select[] = [];
    religionObject.map((religion, index) => religionData.push({ name: religion, id: index }));
    setReligions(religionData);
  }, []);

  const handleDateofBirthChange = (date: Date | null) => {
    setCandidateDateofBirth(date);
  };

  const handlePassportExpChange = (date: Date | null) => {
    setCandidatePassportExp(date);
  };

  const handleSetlanguageSpoken = (languageSpokens: Select[]) => {
    let selectedLanguageSpoken: string[] = [];
    for (const languageSpoken of languageSpokens) {
      selectedLanguageSpoken.push(languageSpoken.name);
    }
    setCandidateLanguage(selectedLanguageSpoken.join(','));
  };

  const renderMaritalStatus = () => {
    return maritalStatusMaster.map(maritalStatus => {
      return (
        <MenuItem key={maritalStatus.id} value={maritalStatus.name}>
          {maritalStatus.name}
        </MenuItem>
      );
    });
  };

  useEffect(() => {
    languageSpokenMaster.map((value, index) => {
      if (language.length !== 0) {
        language.map(language => {
          if (language === value.name) {
            arrayLanguage.push(languageSpokenMaster[index]);
          }

          return arrayLanguage;
        });
      }

      return arrayLanguage;
    });
  }, [arrayLanguage, language, languageSpokenMaster]);

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid className={classes.contentGrid}>
        <Typography variant='h4' id='modal-title' color='primary'>
          Candidate Profile Detail
        </Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={1} className={classes.gridClass}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='email'
              label='Email'
              value={candidateEmail}
              onChange={event => setCandidateEmail(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='phone'
              label='Phone'
              value={candidatePhone}
              onChange={event => setCandidatePhone(event.target.value)}
              autoComplete='off'
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                },
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  format: '###############'
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin='dense'
                required
                fullWidth
                className={clsx({ [classes.textFieldRoot]: 'FFFFFF' })}
                id='dob'
                label='Date of Birth'
                value={candidateDateofBirth}
                variant='dialog'
                inputVariant='outlined'
                format='dd/MM/yyyy'
                onChange={handleDateofBirthChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                InputProps={{
                  classes: {
                    input: classes.textFieldFont
                  }
                }}
                InputAdornmentProps={{ position: 'start' }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='passportno'
              label='Passport No.'
              value={candidatePassport}
              onChange={event => setCandidatePassport(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin='dense'
                required
                fullWidth
                className={clsx({ [classes.textFieldRoot]: 'FFFFFF' })}
                id='passportexp'
                label='Passport Expiry'
                value={candidatePassportExp}
                variant='dialog'
                inputVariant='outlined'
                format='dd/MM/yyyy'
                onChange={handlePassportExpChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                InputProps={{
                  classes: {
                    input: classes.textFieldFont
                  }
                }}
                InputAdornmentProps={{ position: 'start' }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='height'
              label='Height (cm)'
              value={candidateHeight}
              onChange={event => setCandidateHeight(+event.target.value)}
              autoComplete='off'
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  format: '###'
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='weight'
              label='Weight (kg)'
              value={candidateWeight}
              onChange={event => setCandidateWeight(+event.target.value)}
              autoComplete='off'
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  format: '###'
                }
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <TextField
              variant='outlined'
              select
              margin='normal'
              required
              fullWidth
              id='marital'
              label='Marital Status'
              value={candidateMarital}
              onChange={event => setCandidateMarital(event.target.value)}
              autoComplete='off'
            >
              {renderMaritalStatus()}
            </TextField>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
            <div className={classes.responsiveScale}>
              <AutocompleteCustom
                freeSolo
                id='free-solo-2-demo'
                disableClearable
                defaultValue={candidateReligion}
                options={religions.map(religion => ucWords(religion.name))}
                onChange={(event, value) => setCandidateReligion(value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id='religion'
                    label='Religion'
                    value={candidateReligion}
                    autoComplete='off'
                    onChange={event => setCandidateReligion(event.target.value)}
                    InputProps={{
                      classes: {
                        input: classes.textFieldFont
                      },
                      ...params.InputProps,
                      type: 'search'
                    }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.responsiveScale}>
              <AutocompleteCustom
                multiple
                id='languageSpoken'
                options={languageSpokenMaster}
                disableCloseOnSelect
                defaultValue={arrayLanguage}
                getOptionLabel={(option: Select) => ucWords(option.name)}
                onChange={(event, value) => handleSetlanguageSpoken(value)}
                renderOption={(option: Select, { selected }) => (
                  <Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} color='primary' disableRipple className={classes.checkBox} />
                    {ucWords(option.name)}
                  </Fragment>
                )}
                renderInput={params => (
                  <TextField
                    {...params}
                    margin='dense'
                    fullWidth
                    id='languageSpoken'
                    label=' Spoken Language '
                    variant='outlined'
                    autoComplete='off'
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' className={classes.cancelButton} disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <SaveButton type='submit' variant='contained' disabled={isSubmitting}>
          Save
        </SaveButton>
      </Grid>
    </form>
  );
};

export default ProfileForm;
