import React, { FC, Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';

import DropZone from './components/DropZone';

interface Props {
  setPhoto1: React.Dispatch<React.SetStateAction<string>>;
  setPhoto2: React.Dispatch<React.SetStateAction<string>>;
  setPhoto3: React.Dispatch<React.SetStateAction<string>>;
  setPhoto4: React.Dispatch<React.SetStateAction<string>>;
  setResume: React.Dispatch<React.SetStateAction<string>>;
}

const UploadPhotoAndCv: FC<Props> = props => {
  const { setPhoto1, setPhoto2, setPhoto3, setPhoto4 } = props;
  const { setResume } = props;

  return (
    <Fragment>
      <Grid item xs={12} sm={12} container direction='row' justify='flex-start' alignItems='flex-start' alignContent='center'>
        <Typography variant='h5' color='primary'>
          7. Upload Photo & CV 上传照片和简历
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='flex-start'>
        <DropZone label='Photo 01 / 照片 01' fileType='image' setPhoto={setPhoto1} />
        <DropZone label='Photo 02 / 照片 02' fileType='image' setPhoto={setPhoto2} />
        <DropZone label='Photo 03 / 照片 03' fileType='image' setPhoto={setPhoto3} />
        <DropZone label='Photo 04 / 照片 04' fileType='image' setPhoto={setPhoto4} />
      </Grid>
      <Grid item xs={12} sm={6} container direction='row' justify='center' alignItems='flex-start'>
        <DropZone label='Resume / 简历 ' fileType='file' setResume={setResume} />
      </Grid>
    </Fragment>
  );
};

export default UploadPhotoAndCv;
