import React, { Fragment, useEffect } from 'react';
import { Checkbox, Button, Divider, Grid, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { orange } from '@material-ui/core/colors';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { ucWords } from 'utils';
import NumberFormatCustom from 'components/NumberFormatCustom';

interface Props {
  skillsMaster: Select[];
  candidateName: string;
  setCandidateName: React.Dispatch<React.SetStateAction<string>>;
  candidateExpectedSalary: number;
  setCandidateExpectedSalary: React.Dispatch<React.SetStateAction<number>>;
  candidatePositionApplied: string;
  setCandidatePositionApplied: React.Dispatch<React.SetStateAction<string>>;
  candidateSkill: string;
  setCandidateSkill: React.Dispatch<React.SetStateAction<string>>;

  isSubmitting: boolean;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) => ({
  gridClass: {
    marginBottom: theme.spacing(2)
  },
  contentGrid: {
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#0B3469',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  cancelButton: {
    marginRight: theme.spacing(3)
  },
  controlDiv: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  textFieldRoot: (props: Props) => ({
    backgroundColor: '#FFFFFF',
    marginTop: theme.spacing(2)
  }),
  textFieldFont: {
    fontSize: '15px',
    height: 18,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2.5)
  },
  autoCompleteCheckbox: {
    padding: theme.spacing(0)
  },
  checkBoxIcon: {
    fontSize: '16px'
  },
  checkBox: {
    marginLeft: theme.spacing(-2),
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  responsiveScale: {
    width: '100%'
  }
}));

const AutocompleteCustom = withStyles({
  inputRoot: {
    flexWrap: 'wrap',
    '&[class*="MuiOutlinedInput-root"]': {
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 8,
      paddingBottom: 8
    }
  },
  tag: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #0B3469',
    fontSize: '13px',
    color: '#0B3469',
    height: 25
  }
})(Autocomplete);

const SaveButton = withStyles(theme => ({
  root: {
    color: '#FFFFFF',
    backgroundColor: '#EF965A',
    '&:hover': {
      backgroundColor: orange[700]
    }
  }
}))(Button);

const CandidateForm: React.FC<Props> = props => {
  const classes = useStyles(props);

  const icon = <CheckBoxOutlineBlankIcon className={classes.checkBoxIcon} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkBoxIcon} />;

  const {
    candidateName,
    setCandidateName,
    candidateExpectedSalary,
    setCandidateExpectedSalary,
    candidatePositionApplied,
    setCandidatePositionApplied,
    candidateSkill,
    setCandidateSkill,
    isSubmitting,
    onCancel,
    onSubmit
  } = props;
  const { skillsMaster } = props;

  const skill = candidateSkill !== null ? candidateSkill.split(',') : [];
  const arraySkill: any = [];

  const handleSkillMasterChange = (skills: Select[]) => {
    let selectedSkill: string[] = [];
    for (const skill of skills) {
      selectedSkill.push(skill.name);
    }
    setCandidateSkill(selectedSkill.join(','));
  };

  useEffect(() => {
    skillsMaster.map((value, index) => {
      if (skill.length !== 0) {
        skill.map(skill => {
          if (skill === value.name) {
            arraySkill.push(skillsMaster[index]);
          }
          return arraySkill;
        });
      }
      return arraySkill;
    });
  }, [arraySkill, skill, skillsMaster]);

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid className={classes.contentGrid}>
        <Typography variant='h4' id='modal-title' color='primary'>
          Candidate Profile
        </Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={1} className={classes.gridClass}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='name'
              label='Name'
              value={candidateName}
              onChange={event => setCandidateName(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='positionapplied'
              label='Position Applied'
              value={candidatePositionApplied}
              onChange={event => setCandidatePositionApplied(event.target.value)}
              autoComplete='off'
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='expectedsalary'
              label='Expected Salary'
              value={candidateExpectedSalary}
              onChange={event => setCandidateExpectedSalary(+event.target.value)}
              autoComplete='off'
              InputProps={{
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  thousandSeparator: true,
                  prefix: '$'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.responsiveScale}>
              <AutocompleteCustom
                multiple
                id='skills'
                options={skillsMaster}
                disableCloseOnSelect
                defaultValue={arraySkill}
                getOptionLabel={(option: Select) => ucWords(option.name)}
                onChange={(event, value) => handleSkillMasterChange(value)}
                renderOption={(option: Select, { selected }) => (
                  <Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} color='primary' disableRipple className={classes.checkBox} />
                    {ucWords(option.name)}
                  </Fragment>
                )}
                renderInput={params => (
                  <TextField {...params} margin='dense' fullWidth id='skills' label='Skills' variant='outlined' autoComplete='off' />
                )}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justify='center' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' className={classes.cancelButton} disabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
        <SaveButton type='submit' variant='contained' disabled={isSubmitting}>
          Save
        </SaveButton>
      </Grid>
    </form>
  );
};

export default CandidateForm;
