import React, { FC, useState } from 'react';

import { Button, Card, CardHeader, CardContent, CardActions, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import EditIcon from '@material-ui/icons/EditOutlined';
import Skeleton from 'react-loading-skeleton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ActionSnackbar from 'components/ActionSnackbar';
import EditStatusModal from './components/EditStatusModal';

interface Props {
  candidates: CandidateModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: 20
  },
  cardHeader: {
    padding: theme.spacing(2)
  },
  CardContent: { padding: 0 },
  actionIcon: {
    fontSize: 20
  },
  gridOdd: {
    backgroundColor: '#F2F4F7',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  gridEven: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  contentLabel: {
    fontWeight: 'normal'
  },
  contentValue: {
    paddingLeft: theme.spacing(4)
  },
  healthIssueGrid: {
    paddingBottom: theme.spacing(2)
  }
}));

const CandidateStatus: FC<Props> = props => {
  const classes = useStyles();
  const { candidates } = props;

  const { id, placementStatus, placementTo, tagUser, injuriesHealthIssue } = candidates!;
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  const [placement, setPlacement] = useState<string>(placementStatus);
  const [company, setCompany] = useState<string>(placementTo);
  const [rating, setRating] = useState<string>(tagUser);
  const [healthIssues, sethealthIssues] = useState<boolean | undefined>(injuriesHealthIssue);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openEditStatusModal, setOpenEditStatusModal] = useState<boolean>(false);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenEditStatus = () => {
    setOpenEditStatusModal(true);
  };
  const handleCancelEditStatus = () => {
    setOpenEditStatusModal(false);
  };

  const renderRating = () => {
    if (rating === '1') {
      return (
        <Grid item xs={9} className={classes.contentValue}>
          {isLoadingData ? <Skeleton width={40} /> : <Typography variant='h6'>GOOD</Typography>}
        </Grid>
      );
    } else if (rating === '2') {
      return (
        <Grid item xs={9} className={classes.contentValue}>
          {isLoadingData ? <Skeleton width={40} /> : <Typography variant='h6'>NONE</Typography>}
        </Grid>
      );
    } else if (rating === '3') {
      return (
        <Grid item xs={9} className={classes.contentValue}>
          {isLoadingData ? <Skeleton width={40} /> : <Typography variant='h6'>BAD</Typography>}
        </Grid>
      );
    }
  };

  return (
    <Grid item xs={4}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Typography color='primary' variant='h5'>
              Candidate Status
            </Typography>
          }
        />
        <Divider />
        <CardContent className={classes.CardContent}>
          <Grid container className={classes.gridEven}>
            <Grid item xs={3}>
              <Typography variant='h6' color='primary' className={classes.contentLabel}>
                Employement Status
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              {isLoadingData ? <Skeleton width={40} /> : <Typography variant='h6'>{placement}</Typography>}
            </Grid>
          </Grid>
          {placement === 'ASSIGNED' ? (
            <Grid container className={classes.gridOdd}>
              <Grid item xs={3}>
                <Typography variant='h6' color='primary' className={classes.contentLabel}>
                  Employement Placement
                </Typography>
              </Grid>
              <Grid item xs={9} className={classes.contentValue}>
                {isLoadingData ? <Skeleton width={40} /> : <Typography variant='h6'>{company}</Typography>}
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          <Grid container className={placement === 'ASSIGNED' ? classes.gridEven : classes.gridOdd}>
            <Grid item xs={3}>
              <Typography variant='h6' color='primary' className={classes.contentLabel}>
                Rating
              </Typography>
            </Grid>
            {renderRating()}
          </Grid>
          <Grid container className={placement === 'ASSIGNED' ? classes.gridOdd : classes.gridEven}>
            <Grid item xs={3}>
              <Typography variant='h6' color='primary' className={classes.contentLabel}>
                Injuries/health issues
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              {isLoadingData ? <Skeleton width={40} /> : <Typography variant='h6'>{healthIssues === true ? 'Yes' : 'No'}</Typography>}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color='primary' onClick={handleOpenEditStatus}>
            <EditIcon className={classes.actionIcon} />
            Edit
          </Button>
        </CardActions>
      </Card>
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? 'Update is successful' : 'Update failed'}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
      <EditStatusModal
        open={openEditStatusModal}
        candidateId={id}
        candidateEmployementStatus={placementStatus}
        setCandidateEmployementStatus={setPlacement}
        candidatePlacement={company}
        setCandidatePlacement={setCompany}
        candidateRating={tagUser}
        setCandidateRating={setRating}
        candidateHealth={injuriesHealthIssue}
        setCandidateHeatlh={sethealthIssues}
        handleCancel={handleCancelEditStatus}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
      />
    </Grid>
  );
};

export default CandidateStatus;
