import React, { FC } from 'react';
import { AppBar, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import DashboardHeader from './components/DashboardHeader';
import ResumePageHeader from './components/ResumePageHeader';

interface Props {
  isAdminHeader: boolean;
  open?: boolean;
  handleDrawerOpen?(): void;
}

const { REACT_APP_DRAWER_WIDTH = '240' } = process.env;

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: REACT_APP_DRAWER_WIDTH,
    width: `calc(100% - ${REACT_APP_DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
}));

const AppHeader: FC<Props> = props => {
  const classes = useStyles();
  const { isAdminHeader, open, handleDrawerOpen } = props;

  const renderHeader = () => {
    if (isAdminHeader) {
      return <DashboardHeader open={open} handleDrawerOpen={handleDrawerOpen} />;
    } else {
      return <ResumePageHeader />;
    }
  };
  return (
    <AppBar position={isAdminHeader ? 'absolute' : 'sticky'} className={clsx(classes.appBar, { [classes.appBarShift]: isAdminHeader && open })}>
      {renderHeader()}
    </AppBar>
  );
};

export default AppHeader;
