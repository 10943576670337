import React, { FC, useState, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import clsx from 'clsx';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';

import useRouter from 'hooks/useRouter';
import { Container, makeStyles, Theme } from '@material-ui/core';
import CustomizedTabs from 'components/CustomizedTabs';
import OverviewContent from './components/OverviewContent';
import HeaderContent from './components/HeaderContent';
import EmploymentHistoryContent from './components/EmploymentHistoryContent';
import { GET_CANDIDATE_BY_ID_URL } from 'constants/url';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  }
}));

const CandidateDetailPage: FC = () => {
  useCurrentPageTitleUpdater('Candidates Detail');
  const classes = useStyles();
  const { match } = useRouter();
  const params = match.params.id;
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const dummyCandidate: CandidateModel = {
    id: 0,
    positionApplied: '',
    expectedSalary: 0,
    experience: 0,
    name: '',
    contactNumber: '',
    emailAddress: '',
    dateOfBirth: new Date(),
    passportNumber: '',
    passportExpiryDate: new Date(),
    maritalStatus: '',
    height: 0,
    weight: 0,
    religion: '',
    languageSpoken: '',
    skills: '',
    homeAddress: '',
    resumeFile: '',
    photo1: '',
    photo2: '',
    photo3: '',
    photo4: '',
    tagUser: '',
    injuriesHealthIssue: false,
    nameOfSchool: '',
    highestQualification: '',
    placementTo: '',
    placementStatus: '',
    candidateNotes: '',
    createdAt: new Date(),
    updatedAt: new Date()
  };
  const [candidates, setCandidates] = useState<CandidateModel>(dummyCandidate);

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const loadData = async () => {
      try {
        const { data } = await axios.get(`${GET_CANDIDATE_BY_ID_URL(params)}`, { cancelToken: cancelTokenSource.token });
        setCandidates(data.candidate);
      } catch (err) {
        console.log(err);
      }
    };
    loadData();
    return () => {
      cancelTokenSource.cancel();
    };
  }, [params]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    action(actionParam);
  };

  const SelectedContent: FC<{ page: number }> = props => {
    switch (props.page) {
      case 0:
        return <OverviewContent candidates={candidates} />;
      case 1:
        return <EmploymentHistoryContent />;
      default:
        return <div />;
    }
  };

  return (
    <Container maxWidth='lg' className={clsx(classes.root, classes.container)}>
      <HeaderContent candidates={candidates} />
      <CustomizedTabs
        tabs={[{ id: 0, name: 'Overview' }, { id: 1, name: 'Employment History' }]}
        selectedTabId={selectedTab}
        onSelect={(tabId: number) => performActionAndRevertPage(setSelectedTab, tabId)}
      />
      <SelectedContent page={selectedTab} />
    </Container>
  );
};

export default CandidateDetailPage;
