import React, { FC, useState } from 'react';

import { Button, Card, CardContent, CardActions, Chip, makeStyles, Theme, Typography } from '@material-ui/core';
import { PopperPlacementType } from '@material-ui/core/Popper';
import { ucWords } from 'utils';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';

import PositionedPopper from 'components/PositionedPopper';

interface Props {
  skills: Select[];
  flags: Select[];
  employmentsStatus: Select[];
  numberOfYears: Select[];
  qualifications: Select[];
  columnFilter: ColumnFilter[];
  setColumnFilter: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
  skillQuery: string;
  setSkillQuery: React.Dispatch<React.SetStateAction<string>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  cardHeader: {
    backgroundColor: '#F4F4F4',
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  chip: {
    backgroundColor: '#E6EAF0'
  }
}));

const FilterSection: FC<Props> = props => {
  const classes = useStyles();

  const [openSkillsFilterPopper, setOpenSkillsFilterPopper] = useState(false);
  const [anchorElSkillsFilterPopper, setAnchorElSkillsFilterPopper] = useState<HTMLElement | null>(null);
  const [placementSkillsFilterPopper, setPlacementSkillsFilterPopper] = useState<PopperPlacementType>();

  const [openFlagFilterPopper, setOpenFlagFilterPopper] = useState(false);
  const [anchorElFlagFilterPopper, setAnchorElFlagFilterPopper] = useState<HTMLElement | null>(null);
  const [placementFlagFilterPopper, setPlacementFlagFilterPopper] = useState<PopperPlacementType>();

  const [openEmploymentStatusFilterPopper, setOpenEmploymentStatusFilterPopper] = useState(false);
  const [anchorElEmploymentStatusFilterPopper, setAnchorElEmploymentStatusFilterPopper] = useState<HTMLElement | null>(null);
  const [placementEmploymentStatusFilterPopper, setPlacementEmploymentStatusFilterPopper] = useState<PopperPlacementType>();

  const [openNumberOfYearsFilterPopper, setOpenNumberOfYearsFilterPopper] = useState(false);
  const [anchorElNumberOfYearsFilterPopper, setAnchorElNumberOfYearsFilterPopper] = useState<HTMLElement | null>(null);
  const [placementNumberOfYearsFilterPopper, setPlacementNumberOfYearsFilterPopper] = useState<PopperPlacementType>();

  const [openQualificationsFilterPopper, setOpenQualificationsFilterPopper] = useState(false);
  const [anchorElQualificationsFilterPopper, setAnchorElQualificationsFilterPopper] = useState<HTMLElement | null>(null);
  const [placementQualificationsFilterPopper, setPlacementQualificationsFilterPopper] = useState<PopperPlacementType>();

  const { skills, flags, employmentsStatus, numberOfYears, qualifications, setCurrentPage } = props;
  const { columnFilter, setColumnFilter } = props;
  const { skillQuery, setSkillQuery } = props;

  const handleSkillFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenSkillsFilterPopper(!openSkillsFilterPopper);
    setAnchorElSkillsFilterPopper(event.currentTarget);
    setPlacementSkillsFilterPopper('bottom-start');
  };

  const handleFlagFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenFlagFilterPopper(!openFlagFilterPopper);
    setAnchorElFlagFilterPopper(event.currentTarget);
    setPlacementFlagFilterPopper('bottom-start');
  };

  const handleEmploymentStatusFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenEmploymentStatusFilterPopper(!openEmploymentStatusFilterPopper);
    setAnchorElEmploymentStatusFilterPopper(event.currentTarget);
    setPlacementEmploymentStatusFilterPopper('bottom-start');
  };

  const handleNumberOfYearsFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenNumberOfYearsFilterPopper(!openNumberOfYearsFilterPopper);
    setAnchorElNumberOfYearsFilterPopper(event.currentTarget);
    setPlacementNumberOfYearsFilterPopper('bottom-start');
  };

  const handleQualificationFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenQualificationsFilterPopper(!openQualificationsFilterPopper);
    setAnchorElQualificationsFilterPopper(event.currentTarget);
    setPlacementQualificationsFilterPopper('bottom-start');
  };

  const handleDeleteFilter = (columnName: string, valueId: number, valueName: string) => () => {
    const newSelectedFilter = [...columnFilter];
    // check index of element and remove object by index
    const selectedFilterIndex = newSelectedFilter.map(x => x.filterKey).indexOf(`${columnName}-${valueId}`);
    newSelectedFilter.splice(selectedFilterIndex, 1);
    setColumnFilter && setColumnFilter(newSelectedFilter);
  };

  const renderFilter = () => {
    if (columnFilter.length === 0) {
      return (
        <Typography variant='body1' color='textSecondary'>
          No filter yet
        </Typography>
      );
    } else {
      return columnFilter.map((value, index) => {
        const renderChipLabel = () => {
          if (value.columnName === 'numberOfYearsFilter') {
            if (value.valueName === '5') {
              return `≥ ${value.valueName} Years`;
            } else {
              if (value.valueName === '1') {
                return `${value.valueName} Year`;
              } else {
                return `${value.valueName} Years`;
              }
            }
          } else {
            return ucWords(value.valueName);
          }
        };
        return (
          <Chip
            className={classes.chip}
            size='small'
            label={renderChipLabel()}
            key={index}
            onDelete={handleDeleteFilter(value.columnName, value.valueId, value.valueName)}
            deleteIcon={<CloseIcon />}
          />
        );
      });
    }
  };

  return (
    <Card>
      <PositionedPopper
        openPopper={openSkillsFilterPopper}
        setOpenPopper={setOpenSkillsFilterPopper}
        anchorEl={anchorElSkillsFilterPopper}
        placement={placementSkillsFilterPopper}
        containerWidth={250}
        fadeTransition={350}
        popperComponent='checkBoxMenus'
        options={[
          {
            key: 'skillFilter',
            label: 'Skill',
            checkBoxList: skills,
            isFilterWithSelect: true,
            query: skillQuery,
            setQuery: setSkillQuery
          }
        ]}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        setCurrentPage={setCurrentPage}
      />
      <PositionedPopper
        openPopper={openFlagFilterPopper}
        setOpenPopper={setOpenFlagFilterPopper}
        anchorEl={anchorElFlagFilterPopper}
        placement={placementFlagFilterPopper}
        containerWidth={180}
        fadeTransition={350}
        popperComponent='checkBoxMenus'
        options={[{ key: 'flagFilter', label: 'Flag', checkBoxList: flags }]}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        setCurrentPage={setCurrentPage}
      />
      <PositionedPopper
        openPopper={openEmploymentStatusFilterPopper}
        setOpenPopper={setOpenEmploymentStatusFilterPopper}
        anchorEl={anchorElEmploymentStatusFilterPopper}
        placement={placementEmploymentStatusFilterPopper}
        containerWidth={180}
        fadeTransition={350}
        popperComponent='checkBoxMenus'
        options={[{ key: 'employmentStatusFilter', label: 'Employment Status', checkBoxList: employmentsStatus }]}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        setCurrentPage={setCurrentPage}
      />
      <PositionedPopper
        openPopper={openNumberOfYearsFilterPopper}
        setOpenPopper={setOpenNumberOfYearsFilterPopper}
        anchorEl={anchorElNumberOfYearsFilterPopper}
        placement={placementNumberOfYearsFilterPopper}
        containerWidth={180}
        fadeTransition={350}
        popperComponent='checkBoxMenus'
        options={[{ key: 'numberOfYearsFilter', label: 'Number Of Years', checkBoxList: numberOfYears }]}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        setCurrentPage={setCurrentPage}
      />
      <PositionedPopper
        openPopper={openQualificationsFilterPopper}
        setOpenPopper={setOpenQualificationsFilterPopper}
        anchorEl={anchorElQualificationsFilterPopper}
        placement={placementQualificationsFilterPopper}
        containerWidth={350}
        fadeTransition={350}
        popperComponent='checkBoxMenus'
        options={[{ key: 'qualificationFilter', label: 'Qualification', checkBoxList: qualifications }]}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        setCurrentPage={setCurrentPage}
      />
      <CardContent className={classes.cardHeader}>{renderFilter()}</CardContent>
      <CardActions>
        <Button color='primary' onClick={event => handleSkillFilterClick(event)}>
          SKILLS <ArrowDropDownIcon />
        </Button>
        <Button color='primary' onClick={event => handleFlagFilterClick(event)}>
          RATING <ArrowDropDownIcon />
        </Button>
        <Button color='primary' onClick={event => handleEmploymentStatusFilterClick(event)}>
          EMPLOYMENT STATUS <ArrowDropDownIcon />
        </Button>
        <Button color='primary' onClick={event => handleNumberOfYearsFilterClick(event)}>
          EXPERIENCE <ArrowDropDownIcon />
        </Button>
        <Button color='primary' onClick={event => handleQualificationFilterClick(event)}>
          QUALIFICATION <ArrowDropDownIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

export default FilterSection;
