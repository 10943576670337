import React, { FC, Fragment, useState, useEffect } from 'react';

import axios from 'axios';
import { GET_DELETE_CANDIDATES } from 'constants/url';

import { StandardConfirmationDialog } from 'components/AppDialog';
import { Button, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import Content from './components/Content';
import DownloadIcon from '@material-ui/icons/GetApp';

interface Props {
  isLoadingData: boolean;
  candidates: CandidateModel[];
  headers: CsvHeaderModel[];
  setDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  exportToCsvButton: {
    textDecoration: 'none'
  }
}));

const ContentSection: FC<Props> = props => {
  const classes = useStyles();
  const {
    isLoadingData,
    candidates,
    headers,
    setDelete,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError
  } = props;

  const dummyCandidate: CandidateModel = {
    id: 0,
    positionApplied: '',
    expectedSalary: 0,
    experience: 0,
    name: '',
    contactNumber: '',
    emailAddress: '',
    dateOfBirth: new Date(),
    passportNumber: '',
    passportExpiryDate: new Date(),
    maritalStatus: '',
    height: 0,
    weight: 0,
    religion: '',
    languageSpoken: '',
    skills: '',
    homeAddress: '',
    resumeFile: '',
    photo1: '',
    photo2: '',
    photo3: '',
    photo4: '',
    tagUser: '',
    injuriesHealthIssue: false,
    nameOfSchool: '',
    highestQualification: '',
    placementTo: '',
    placementStatus: '',
    candidateNotes: '',
    createdAt: new Date(),
    updatedAt: new Date()
  };

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);

  const [message, setMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number>();

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isLoadingData) {
      timeout = setTimeout(() => {
        setShowSkeleton(true);
      }, 500);
    }

    setShowSkeleton(false);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoadingData]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const actionWrapper = async (action: () => Promise<void>) => {
    try {
      await action();
      handleCloseDialog();
      setDelete(true);
      handleSetMessageSuccess(`Successfully delete a candidate`);
      setSnackbarVarient('success');
      setOpenSnackbar(true);
    } catch (err) {
      handleCloseDialog();
      handleSetMessageError(`Failed to delete a candidate`);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
    }
  };

  const deleteContract = async (selectedId: number) => {
    await actionWrapper(async () => {
      await axios.delete(GET_DELETE_CANDIDATES(selectedId));
    });
  };

  const renderContent = () => {
    if (showSkeleton) {
      return [1, 2, 3, 4, 5, 6].map(index => {
        return (
          <Content
            candidate={dummyCandidate}
            index={index}
            key={index}
            isLoadingData={isLoadingData}
            setSelectedId={setSelectedId}
            setOpenDialog={setOpenDialog}
            setMessage={setMessage}
          />
        );
      });
    } else {
      if (candidates.length === 0) {
        return (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant='body1' color='textSecondary' align='left'>
                No candidate yet
              </Typography>
            </Paper>
          </Grid>
        );
      } else {
        return candidates.map((candidate, index) => {
          return (
            <Content
              candidate={candidate}
              index={index}
              key={index}
              isLoadingData={isLoadingData}
              setSelectedId={setSelectedId}
              setOpenDialog={setOpenDialog}
              setMessage={setMessage}
            />
          );
        });
      }
    }
  };

  const renderExportButton = () => {
    if (candidates.length === 0) {
      return (
        <Button variant='outlined' color='primary' disabled={true}>
          <DownloadIcon /> EXPORT .CSV
        </Button>
      );
    } else {
      return (
        <CSVLink data={candidates} headers={headers} separator={','} filename={'candidate list.csv'} className={classes.exportToCsvButton}>
          <Button variant='outlined' color='primary'>
            <DownloadIcon /> EXPORT .CSV
          </Button>
        </CSVLink>
      );
    }
  };

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} container direction='row' justify='flex-end' alignItems='center'>
          {renderExportButton()}
        </Grid>
        {renderContent()}
      </Grid>
      <StandardConfirmationDialog
        variant={'warning'}
        message={message}
        open={openDialog}
        handleClose={handleCloseDialog}
        onConfirm={event => selectedId && deleteContract(selectedId)}
      />
    </Fragment>
  );
};

export default ContentSection;
