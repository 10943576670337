import React, { FC, useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';

import { Button, Card, CardContent, CardActions, Divider, Grid, makeStyles, Theme, Typography } from '@material-ui/core';

import EditIcon from '@material-ui/icons/EditOutlined';
import Skeleton from 'react-loading-skeleton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ActionSnackbar from 'components/ActionSnackbar';
import EditDetailModal from './components/EditDetailModal';
import { GET_FAMILY_MEMBER_BY_CANDIDATE_ID_URL } from 'constants/url';

interface Props {
  candidates: CandidateModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: 20,
    marginRight: 40
  },
  CardContent: { padding: 0 },
  actionIcon: {
    fontSize: 20
  },
  gridOdd: {
    backgroundColor: '#F2F4F7',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  gridEven: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  contentLabel: {
    fontWeight: 'normal'
  },
  contentValue: {
    paddingLeft: theme.spacing(4)
  },
  familyMemberGrid: {
    paddingBottom: theme.spacing(2)
  }
}));

const CandidateDetail: FC<Props> = props => {
  const classes = useStyles();
  const { candidates } = props;

  const { id, homeAddress, nameOfSchool, highestQualification } = candidates!;

  const [familyMembers, setFamilyMembers] = useState<FamilyMemberModel[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [address, setAddress] = useState<string>(homeAddress);
  const [candidateNameOfSchool, setCandidateNameOfSchool] = useState<string>(nameOfSchool);
  const [candidateHighestQualification, setCandidateHighestQualification] = useState<string>(highestQualification);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [openEditDetailModal, setOpenEditDetailModal] = useState<boolean>(false);

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const loadData = async () => {
      try {
        setIsLoadingData(true);
        const { data } = await axios.get(`${GET_FAMILY_MEMBER_BY_CANDIDATE_ID_URL(id)}`, { cancelToken: cancelTokenSource.token });
        setFamilyMembers(data.familyMembers);
      } catch (err) {
        console.log(err);
      }
      setIsLoadingData(false);
    };

    loadData();
    return () => {
      cancelTokenSource.cancel();
    };
  }, [id]);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenEditDetail = () => {
    setOpenEditDetailModal(true);
  };
  const handleCancelEditDetail = () => {
    setOpenEditDetailModal(false);
  };

  return (
    <Grid item xs={4}>
      <Card className={classes.card}>
        <CardContent className={classes.CardContent}>
          <Grid container className={classes.gridEven}>
            <Grid item xs={3}>
              <Typography variant='h6' color='primary' className={classes.contentLabel}>
                Home Address
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              {isLoadingData ? <Skeleton width={40} /> : <Typography variant='h6'>{address === null ? '-' : address}</Typography>}
            </Grid>
          </Grid>
          <Grid container className={classes.gridOdd}>
            <Grid item xs={3}>
              {isLoadingData ? (
                <Skeleton width={40} />
              ) : (
                <Typography variant='h6' color='primary' className={classes.contentLabel}>
                  Family Members
                </Typography>
              )}
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              {isLoadingData ? (
                <Skeleton width={40} />
              ) : familyMembers.length > 0 ? (
                familyMembers.map((familyMember, index) => {
                  return (
                    <Grid className={classes.familyMemberGrid} key={index}>
                      <Typography variant='h6'>Name: {familyMember.name}</Typography>
                      <Typography variant='h6'>Relationship: {familyMember.relationship}</Typography>
                      <Typography variant='h6'>Age: {familyMember.age}</Typography>
                      <Typography variant='h6'>Occupation: {familyMember.occupation}</Typography>
                      <Typography variant='h6'>Contact No: {familyMember.contactNumber}</Typography>
                    </Grid>
                  );
                })
              ) : (
                '-'
              )}
            </Grid>
          </Grid>
          <Grid container className={classes.gridEven}>
            <Grid item xs={3}>
              {isLoadingData ? (
                <Skeleton width={40} />
              ) : (
                <Typography variant='h6' color='primary' className={classes.contentLabel}>
                  Qualifications Attained
                </Typography>
              )}
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
              {isLoadingData ? (
                <Skeleton width={40} />
              ) : (
                <Typography variant='h6'>
                  {candidateHighestQualification} ({candidateNameOfSchool})
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color='primary' onClick={handleOpenEditDetail}>
            <EditIcon className={classes.actionIcon} />
            Edit
          </Button>
        </CardActions>
      </Card>
      <ActionSnackbar
        variant={snackbarVarient}
        message={snackbarVarient === 'success' ? 'Update is successful' : 'Update failed'}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        Icon={snackbarVarient === 'success' ? CheckCircleIcon : ErrorIcon}
      />
      <EditDetailModal
        open={openEditDetailModal}
        candidateId={id}
        candidateAddress={address}
        setCandidateAddress={setAddress}
        candidateFamilyMembers={familyMembers}
        setCandidateFamilyMembers={setFamilyMembers}
        candidateNameOfSchool={nameOfSchool}
        setCandidateNameOfSchool={setCandidateNameOfSchool}
        candidateHighestQualification={highestQualification}
        setCandidateHighestQualification={setCandidateHighestQualification}
        handleCancel={handleCancelEditDetail}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
      />
    </Grid>
  );
};

export default CandidateDetail;
